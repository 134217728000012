<template>
    <div class="his-left-main-select-driver">
        <el-select
                class="his-left-select"
                :size="size"
                :no-data-text="$t('globalText.noData') + '.'"
                :no-match-text="$t('globalText.noData') + '.'"
                @change="handleCurrentVehicleChange"
                v-model="currentVehicle"
                filterable
                default-first-option
                :placeholder="$t('tripHistory.vehicleNameOrPlateNumber')">
            <el-option
                    v-for="(item, index) in currentVehicleList"
                    :key="index"
                    :label="item.label"
                    :value="item.label">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "VehicleSelector",
        props: {
            size: {type: String, default: 'medium'},
            vehicleList: {type: Array, required: true},
            defaultVehicle: {required: true}
        },
        beforeMount() {
            this.currentVehicleList = this.vehicleList;
            this.currentVehicle = this.defaultVehicle;
            if (this.currentVehicle) {
                this.currentVehicleList.forEach(({label, value}) => {
                    if (value === this.currentVehicle) this.currentVehicle = label;
                });
            }
        },
        data() {
            return {
                currentVehicle: '',
                currentVehicleList: [],
            }
        },
        watch: {
            vehicleList(list) {
                this.currentVehicleList = list;
            }
        },
        methods: {
            handleCurrentVehicleChange(selectedLabel) {
                let vehicleId = '';
                this.currentVehicleList.forEach(({label, value}) => {
                    if (label === selectedLabel) vehicleId = value;
                });
                this.$emit('currentVehicle', vehicleId);
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .his-left-main-select-driver
        padding: 10px 10px 10px
        background-color $color_gray_first

        > .his-left-select
            width 100%

        >>> .el-input__inner
            background-color $color_white !important

    .unknown-driver
        color $color_collision !important

        >>> .el-input__inner
            color $color_collision !important

</style>