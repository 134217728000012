<template>
    <div class="dashboard-com-date-selector-layout-header" v-if="this.$route.name === 'dashboard'">
        <com-date-selector
                :start-date="this.startDate"
                :end-date="this.endDate"
                :max-last-days="366"
                @date="handleDate"
        ></com-date-selector>
    </div>
</template>

<script>
    import ComDateSelector from './ComDateSelector';
    import bus from "../../utils/bus";

    export default {
        name: "ComHeaderDateSelector",
        components: {
            ComDateSelector
        },
        created() {
            let currentTime = new Date().getTime();
            this.startDate = currentTime - 7 * 24 * 3600 * 1000;
            this.endDate = currentTime;
        },
        data() {
            return {
                startDate: null,
                endDate: null
            }
        },
        methods: {
            handleDate(startDate, endDate) {
                let start = startDate.slice(0, 4) + '-' + startDate.slice(4, 6) + '-' + startDate.slice(6, 8);
                let end = endDate.slice(0, 4) + '-' + endDate.slice(4, 6) + '-' + endDate.slice(6, 8);
                bus.$emit('dashboardDateSelect', start, end);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .dashboard-com-date-selector-layout-header
        line-height 0
</style>
