<template>
    <div class="sidebar" :class="{'collapse-sidebar':collapse}">
        <div class="header-img">
            <img width="45px" :src="require('../../assets/image/' + getLogoHeader('header_logo.png'))" />
            <!-- <span class="via-mobile360-logo via-logo"></span> -->
            <span v-show="!collapse" class="company-title">{{getHeaderAppName('globalText.VIA_FLEET')}}</span>
        </div>
        <div class="sidebar-hr">
            <div></div>
        </div>
        <el-menu
                class="sidebar-el-menu"
                ref="sidebarMenu"
                :default-active="onRoute"
                :collapse="collapse"
                :collapse-transition="false"
                @select="show"
                unique-opened
                router
        >
            <template v-for="item in items">
                <template v-if="item.subs">
                    <el-submenu :index="item.index" :key="item.index" class="sidebar-el-submenu">
                        <template slot="title">
                            <span :class="item.icon" class="menu-icon"></span>
                            <span slot="title" class="sidebar-title">{{ $t(item.title) }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                    v-if="subItem.subs"
                                    :index="subItem.index"
                                    :key="subItem.index"
                            >
                                <template slot="title" class="subtitle">{{ $t(subItem.title) }}</template>
                                <el-menu-item
                                        v-for="threeItem in subItem.subs"
                                        :key="threeItem.index"
                                        :index="threeItem.index"
                                >
                                    <div class="menu-item-content">
                                        <div><i class="el-icon-caret-right"
                                                v-show="subItem.index == currentSelect"></i></div>
                                        {{ $t(threeItem.title) }}
                                    </div>

                                </el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                    v-else
                                    :index="subItem.index"
                                    :key="subItem.index">
                                <div class="menu-item-content">
                                    <div>
                                        <i class="el-icon-caret-right"
                                           v-show="subItem.index == currentSelect"></i>
                                    </div>
                                    {{ $t(subItem.title) }}
                                </div>


                            </el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <!--<img :src="item.icon_c" v-if="currentSelect === item.index">-->
                        <span :class="item.icon" class="menu-icon"></span>
                        <!--<img v-else :src="item.icon">-->
                        <span slot="title" class="sidebar-title">{{ $t(item.title) }}</span>
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
        <div v-if="!collapse" class="sidebar-time">
            <div class="st-box">
                <div class="st-top-border"/>
                <div class="st-time">{{$store.getters['timezone/time']}}</div>
                <div class="st-timezone"><span class="st-icon via-timezone"/>{{$t('login.timezone')}}: {{$store.getters['timezone/UTC']}}</div>
            </div>
        </div>
        <div v-if="!collapse" class="sidebar-footer">
            <div class="text">
                <p>{{getCopyright('login.copyright')}}</p>
                <p>{{$t('login.allRightsReserved')}}</p>
            </div>
        </div>
        <div v-else class="sidebar-footer">
            <img width="52px" :src="require('../../assets/image/' + getLogoLeftNavBottom('VIA_logo.png'))" />
            <!-- <span class="via-viaLogo footer-icon"></span> -->
        </div>
    </div>
</template>

<script>
    import {CustomerConfig} from '@/services/customer/CustomerConfig'
    import bus from '../../utils/bus';

    export default {
        data() {
            return {
                currentSelect: '',
                items: [
                    {
                        icon: 'via-live-tracking',
                        index: '/liveTracking',
                        title: 'liveTracking.liveTracking'
                    },
                    {
                        icon: 'via-dashboard',
                        index: '/dashboard',
                        title: 'dashboard.dashboard'
                    },
                    {
                        icon: "via-trip-history",
                        index: '/tripHistory',
                        title: 'tripHistory.tripHistory'
                    },

                    {
                        icon: 'via-management',
                        index: "/management",
                        title: 'userAccounts.management',
                        subs: [
                            {
                                icon: '',
                                index: '/management/vehicle',
                                title: 'userAccounts.vehicles',
                            },
                            {
                                icon: '',
                                index: '/management/driver',
                                title: 'userAccounts.drivers',
                            }
                        ]
                    },
                    // {
                    //     icon: 'via-management',
                    //     index: "/account",
                    //     title: 'Account',
                    //     subs: [
                    //         {
                    //             icon: '',
                    //             index: '/account/user',
                    //             title: 'User',
                    //         },
                    //         {
                    //             icon: '',
                    //             index: '/account/role',
                    //             title: 'Role',
                    //         }
                    //     ]
                    // },


                ],
            };
        },
        methods: {
            getLogoHeader (imageName) {
                return CustomerConfig.getHeaderLogo(imageName)
            },
            getLogoLeftNavBottom (imageName) {
                return CustomerConfig.getLeftNavButtomLogo(imageName)
            },
            getHeaderAppName (value) {
                const content = this.$t(value)
                return CustomerConfig.getHeaderAppName(content, this.$i18n.locale)
            },
            getCopyright (value) {
                const content = this.$t(value, {year: this.currentFullYear})
                return CustomerConfig.getLeftNavButtomCopyright(content, this.$i18n.locale)
            },
            show(index) {
                this.currentSelect = index;
                if (index == "/liveTracking" || index == "/dashboard" || index == '/tripHistory') {
                    this.$refs.sidebarMenu.close('/management');
                    this.$store.commit("clearDriverListCtxScrollTop");
                }
            },
            isRouterInSidebar(route) {
                for (var i = 0; i < this.items.length; i++) {
                    var item = this.items[i];
                    if (item.index && route == item.index)
                        return true;
                    else {
                        if (item.subs) {
                            for (var j = 0; j < item.subs.length; j++) {
                                if (item.subs[j].index && route == item.subs[j].index)
                                    return true;
                            }
                        }

                    }
                }
                return false;
            },

        },
        computed: {
            onRoute: function () {
                let currentRoute = this.$route;
                if (this.isRouterInSidebar(currentRoute.path)) {
                    return currentRoute.path;
                } else {
                    if (currentRoute.meta.sidebarIndex)
                        return currentRoute.meta.sidebarIndex;
                }
                return "/liveTracking";
            },
            collapse: function () {
                return this.$store.getters.getMenuCollapse;
            },
            currentYear() {
                return new Date().getFullYear();
            },
            currentFullYear() {
                return new Date().getFullYear();
            }
        },
        created() {
            this.currentSelect = this.onRoute;
        }
    };
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .sidebar {
        display: block
        position: absolute
        left: 0
        top: 0
        bottom: 0
        text-align: left
        width: 245px
        background: $color_gray_first
        color: $color_black_second

    }

    .company-title {
        overflow: hidden
        text-overflow: ellipsis
        white-space: nowrap
        color: $color_white
        font-size: 24px
    }

    .collapse-sidebar {
        width: 65px
    }

    .sidebar-title {
        margin-left: 12px
    }

    .sidebar-el-menu {
        background-color: $color_gray_first;
    }

    .header-img {
        padding-left: 8px
        height: 75px
        line-height: 75px
        width: 100%
        background-color: $color_blue_first
        display: flex
        align-items: center
    }

    .header-img > span {
        margin: 0 8px;
        vertical-align: middle;
    }

    .header-img .via-logo {
        font-size: 40px;
        color: white;
        vertical-align: middle
    }

    .header-img .via-image {
        height: 75px;
        width: 130px
    }

    .sidebar-hr {
        height: 10px;
        padding: 10px 15px;
    }

    .sidebar-hr > div {
        border-radius: 5px;
        background: $color_gray_third;
        height: 10px;
    }

    .sidebar-el-menu::-webkit-scrollbar {
        width: 0;
    }

    .sidebar-el-menu {
        font-size: 16px;
    }

    .sidebar-el-menu:not(.el-menu--collapse) {
        width: 245px;
    }

    .sidebar > ul {
        height: 100%;
    }

    .menu-item-content {
        padding-left 48px
        height 100%
        background-color $color_gray_second
    }

    .menu-item-content div:nth-child(1) {
        display inline-block
        width 24px
    }

    .menu-item-content i {
        margin-top: -5px;
        color: white;
    }

    .sidebar .disactive-icon {
        color: $color_gray_second;
    }

    .sidebar-footer {
        text-align: center;
        line-height: 14px;
        padding: 8px 0;
        background: $color_blue_first;
        position: absolute;
        bottom: 0;
        width: 100%;
        color: $color_white;

    }

    .sidebar-footer .text {
        font-size: 12px;
        text-align: center;
        display: inline-block
    }

    .footer-icon {
        font-size: 22px;
        text-align: center;
        vertical-align: middle;
    }

    .footer-icon:before {
        color #ffffff
    }

    >>> .sidebar-el-menu > .el-menu-item, >>> .el-submenu__title {
        padding-left: 36px !important;
    }

    .collapse-sidebar >>> .el-submenu__title {
        padding-left: 20px !important;
    }

    .sidebar-time
        background-color $color_gray_first
        width 100%
        cursor default
        position absolute
        bottom 44px
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        -khtml-user-select: none;
        user-select: none;

        .st-box
            padding 0 24px

        .st-top-border
            margin-bottom 15px
            height 6px
            border-radius 6px
            background-color $color_gray_third

        .st-time
            width calc(100% - 16px)
            margin 0 auto
            line-height 34px
            background-color $color_black_third
            border-radius 4px
            color #ffffff
            font-size 24px
            text-align center

        .st-timezone
            line-height 48px
            font-size 16px
            text-align center
            color $color_black_third

            .st-icon
                position relative
                top 1px
                padding-right 5px
</style>
<style lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .el-submenu .el-submenu__title, .el-submenu > .el-menu-item {
        font-size: 16px;
    }

    .sidebar-title {
        margin-left: 16px !important;
    }

    .el-menu-item:hover, .el-submenu__title:hover, .menu-item-content:hover {
        background-color: $color_gray_third
    }

    .el-menu-item .menu-icon, .el-submenu .menu-icon {
        font-size: 20px;
        width 20px;
        display inline-block
    }

    .el-submenu .el-submenu__icon-arrow {
        color: $color_black_first;
        font-weight: 700;
        font-size: 16px;
    }

    .el-submenu .el-menu-item {
        padding 0 !important
    }

    .el-menu--vertical .el-menu-item {
        padding 0 !important
        height 48px
    }

    .el-menu--vertical .menu-item-content {
        padding-left 40px !important
        background-color transparent
        height 50px
    }

    .el-submenu.is-active .el-submenu__icon-arrow {
        color: white;
    }

    .sidebar-el-menu > .el-menu-item, .sidebar-el-menu .el-submenu__title {
        color: $color_black_first !important;
        font-size: 16px;
    }

    .sidebar-el-menu > .el-menu-item .menu-icon {
        color: $color_black_first;
    }

    .sidebar-el-menu > .is-active, .sidebar-el-menu > .is-active .el-submenu__title {
        background-color: $color_blue_first !important;
        color: white !important;
    }

    .sidebar-el-menu > .is-active .menu-icon {
        color: white;
    }

    .sidebar-el-submenu .el-menu--inline {
        background-color: $color_gray_second !important;
    }

    .sidebar-el-submenu .el-menu--inline .el-menu-item {
        background-color: $color_gray_second !important;
    }

    .sidebar-el-submenu .el-menu .is-active .menu-item-content {
        color: white !important;
        background-color: $color_blue_second !important;
    }

    .el-menu--collapse > .el-menu-item span, .el-menu--collapse > .el-submenu > .el-submenu__title span {
        visibility: visible;
        display: inline;
    }

    .el-menu--collapse > .el-submenu > .el-submenu__title > .sidebar-title {
        visibility: hidden;
    }


</style>
