<template>
    <v-base-pop-dialog width="600px" @onClose="handleClose">
        <template slot="title">
            <span class="title-icon"></span>{{$t('myAccount.changePassword')}}
        </template>
        <template slot="content">
            <el-form :rules="rules"
                     :model="passwordInfo"
                     v-loading="loading"
                     element-loading-background="rgba(0, 0, 0, 0.2)"
                     ref="changePwdForm"
                     class="change-password-content"
                     label-width="220px"
                     size="medium"
                     label-position="right">
                <el-form-item :label="$t('myAccount.oldPassword')" prop="oldPassword">
                    <!--<el-input class="text-value-input" size="small" type="password"-->
                              <!--v-model="passwordInfo.oldPassword" auto-complete="new-password"-->
                              <!--@input.native="passwordInputNoSpace(passwordInfo.oldPassword)"></el-input>-->
                    <password-input v-model="passwordInfo.oldPassword"></password-input>
                </el-form-item>

                <el-form-item :label="$t('myAccount.newPassword')" prop="newPassword">
                    <!--<el-input class="text-value-input" size="small" type="password"-->
                              <!--v-model="passwordInfo.newPassword" auto-complete="new-password"></el-input>-->
                    <password-input v-model="passwordInfo.newPassword"></password-input>
                </el-form-item>
                <el-form-item :label="$t('myAccount.reEnterNewPassword')" prop="newPasswordAgain">
                    <!--<el-input class="text-value-input" size="small" type="password"-->
                              <!--v-model="passwordInfo.newPasswordAgain" auto-complete="new-password"></el-input>-->
                    <password-input v-model="passwordInfo.newPasswordAgain"></password-input>
                </el-form-item>
                <div class="account-button">
                    <el-button class="ok-button" size="small" @click="saveChange">{{$t('globalText.oK')}}</el-button>
                    <el-button class="cancel-button" size="small" @click="cancelChange">{{$t('globalText.cancel')}}
                    </el-button>
                </div>
            </el-form>
        </template>

    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../../components/common/BasePopDialog'
    import regExp from "@/assets/js/RegExp";
    import PasswordInput from "@/components/common/PasswordInput";

    export default {
        name: "Role",
        components: {
            PasswordInput,
            vBasePopDialog,
        },
        data() {
            let checkPassword = (rule, value, callback) => {
                if (value !== this.passwordInfo.newPassword) {
                    callback(new Error(this.$t('tips.resetPwd.theTwoNewPasswordsAreNotTheSame')));
                } else {
                    callback();
                }
            };
            let checkSamePassword = (rule, value, callback) => {
                if (value === this.passwordInfo.oldPassword) {
                    callback(new Error(this.$t('tips.resetPwd.theNewPasswordIsTheSameAsTheOldPassword'))); // 'The new password is the same as the old password.'
                } else {
                    callback();
                }
            };
            return {
                userInfo: null,
                passwordInfo: {
                    oldPassword: '',
                    newPassword: '',
                    newPasswordAgain: '',
                },
                loading:false,
                rules: {
                    oldPassword: [
                        {required: true, message: this.$t('tips.resetPwd.pleaseInputThePassword'), trigger: 'blur'},
                        {min: 6, max: 15, message: this.$t('globalText.mustContainBetween_X_To_Y_Characters',{min:6,max:15}), trigger: ['blur']},
                    ],
                    newPassword: [
                        {
                            validator: checkSamePassword, trigger: 'blur'
                        },
                        {
                            required: true, message: this.$t('tips.resetPwd.pleaseInputTheNewPassword'), trigger: 'blur'
                        },
                        {min: 6, max: 15, message: this.$t('globalText.mustContainBetween_X_To_Y_Characters',{min:6,max:15}), trigger: ['blur']},
                    ],
                    newPasswordAgain: [
                        {
                            validator: checkPassword, trigger: 'blur'
                        },
                        {
                            required: true, message: this.$t('tips.resetPwd.pleaseInputTheNewPasswordAgain'), trigger: 'blur'
                        },
                        {min: 6, max: 15, message: this.$t('globalText.mustContainBetween_X_To_Y_Characters',{min:6,max:15}), trigger: ['blur']},
                    ]

                }
            }

        },
        methods: {

            handleClose() {
                this.$emit('onClose');
                this.$parent.showMyAccount();
            },
            saveChange() {
                this.$refs['changePwdForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$serverApi.account.password(this.passwordInfo.oldPassword, this.passwordInfo.newPassword, () => {
                            this.loading = false;
                            this.$emit('onClose');
                            this.$serverApi.auth.logout();
                            this.$customAlert(this.$t('popUpMsg.resetPwd.yourPasswordHasBeenUpdatedPleaseLogin'), '');
                        }, (error) => {
                            this.loading = false;
                            if (error.code == 203)
                                this.$customAlert(this.$t('popUpMsg.account.wrongPassword'), '');
                            else
                                this.$customAlert(this.$t('popUpMsg.account.failedToChangePassword'), '');
                        })

                    }
                });

            },
            cancelChange() {
                this.$emit('onClose');
               // this.$parent.showMyAccount();
            }
        },
        created() {
            if (this.$store.getters.currentUserData)
                this.userInfo = this.$store.getters.currentUserData;
            else {
                this.$serverApi.user.getUserInfo();
            }
        }

    }
</script>

<style scoped>

    .change-password-content {
        padding: 30px 0;
        font-size: 14px;
        height: 312px;
    }

    .title-icon {
        font-size: 24px;
        margin-right: 10px
    }

    /*.text-value {*/
    /*max-width: 300px;*/
    /*}*/

    .text-value-input {
        max-width: 300px
    }

    >>> .el-form-item__content, >>> .el-form-item__label {
        font-size: 14px !important;
        line-height: 40px !important;
    }

    .account-button {
        text-align: center;
        margin-top: 50px;
    }

    .account-button .ok-button {
        margin-right: 20px
    }

</style>

<style>

</style>
