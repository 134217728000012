<template>
    <v-base-pop-dialog title="Alerts" @onClose="handleClose" width="1300px">
        <template slot="title">
            <span class="el-icon-message-solid am-car-crash"/> {{$t('alertList.alerts')}}
        </template>
        <template slot="content">
            <div class="am-list-box">
                <el-table
                        :default-sort="{prop: 'time', order: 'descending'}"
                        :data="alertsList"
                        :empty-text="tips"
                        @sort-change="handleSortChange"
                        height="320"
                        class="alert-msg-table"
                        style="width: 100%">
                    <el-table-column
                            header-align="left"
                            align="left"
                            width="150"
                            prop="vehicle"
                            sortable="custom"
                            :sort-orders="['ascending', 'descending']"
                            :label="$t('alertList.vehicle')">
                        <template slot-scope="scope">
                            <div class="am-vehicle-box">
                                <span class="am-vehicle-name" :title="scope.row.vehicle">
                                    <span v-if="scope.row.showVehicleNew" class="via-new am-icon-new"/>
                                    <span class="am-text-one-line" style="display: inline-block; max-width: 129px;">{{ scope.row.vehicle }}</span>
                                </span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="left"
                            align="left"
                            width="170"
                            prop="fleet"
                            sortable="custom"
                            :sort-orders="['ascending', 'descending']"
                            :label="$t('alertList.fleet')">
                        <template slot-scope="scope">
                            <div class="am-text-one-line">
                                <span :title="scope.row.fleet">{{ scope.row.fleet }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="left"
                            align="left"
                            width="162"
                            prop="event"
                            sortable="custom"
                            :sort-orders="['ascending', 'descending']"
                            :label="$t('alertList.type')">
                        <template slot-scope="scope">
                            <div class="am-text-one-line">
                                <span v-if="scope.row.event === 'Collision'" :title="$t('alertList.collisionAlert')"><i
                                        class="am-icon-event via-car-crash"/> {{$t('alertList.collisionAlert')}}</span>
                                <span v-if="scope.row.event === 'DriverAlert'" :title="$t('alertList.driverAlert')"><i
                                        class="am-icon-event via-Driver-alert"/> {{$t('alertList.driverAlert')}}</span>
                                <span v-if="scope.row.event === 'ParkingCollision'" :title="$t('alertList.parkingAlert')"><i
                                        class="am-icon-event via-Parking-alert"/> {{$t('alertList.parkingAlert')}}</span>
                                <span v-if="scope.row.event === 'Unpermitted'" :title="$t('alertList.unpermittedDriver')"><i
                                        class="am-icon-event via-Unpermitted"/> {{$t('alertList.unpermittedDriver')}}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="left"
                            align="left"
                            prop="location"
                            :label="$t('alertList.location')">
                        <template slot-scope="scope">
                            <div class="am-location-box am-word-show" @click="handleLocationSelect(scope.row)">
                                <!--span class="via-map-marker am-icon"/-->{{ scope.row.location}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="left"
                            align="left"
                            width="160"
                            prop="driver"
                            sortable="custom"
                            :sort-orders="['ascending', 'descending']"
                            :label="$t('alertList.driver')">
                        <template slot-scope="scope">
                            <div class="am-word-show am-text-one-line" style="width: 140px"
                                 :class="{'red-tips': scope.row.isUnPermittedDriver}"
                                 :title="scope.row.driver">
                                {{ scope.row.driver}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="left"
                            align="left"
                            width="220"
                            prop="time"
                            :sort-method="sortTime"
                            sortable
                            :sort-orders="['descending', 'ascending']"
                            :label="$t('alertList.time')">
                        <template slot-scope="scope">
                            <span>{{ $dateFormat.dateTime(scope.row.timestamp, mixin_dateTimeFormat, mixin_timezone) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="center"
                            align="center"
                            width="50">
                        <template slot="header">
                            <span class="via-image"/>
                        </template>
                        <template slot-scope="scope">
                            <div class="am-media-box">
                                <span class="via-new" v-if="scope.row.showImgNew"/>
                                <span v-if="scope.row.hasImage" class="el-icon-check am-icon-check"/>
                                <span v-else class="el-icon-close am-icon-close"/>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                            header-align="center"
                            align="center"
                            width="50">
                        <template slot="header">
                            <span class="via-live-view"/>
                        </template>
                        <template slot-scope="scope">
                            <div class="am-media-box" v-if="scope.row.event !== 'Unpermitted'">
                                <span class="via-new" v-if="scope.row.showVideoNew"/>
                                <span v-if="scope.row.hasVideo" class="el-icon-check am-icon-check"/>
                                <span v-else class="el-icon-close am-icon-close"/>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="am-tips-box">* {{$t('alertList.showMsgFor24hours')}}</div>
            </div>
        </template>
    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../common/BasePopDialog';
    import Constant from '../../components/route/Constant';
    import Sort from "../../assets/js/Sort";

    let {ALERT_VALUE} = Constant;

    export default {
        name: "AlarmMessage",
        components: {
            vBasePopDialog,
        },
        data() {
            return {
                alertsList: [],
                tips: this.$t('globalText.loading'),
                loadingStatus: this.$t('globalText.loading'),
                nodataStatus: this.$t('globalText.noData'),
                lastResource: null, // 记录collision图片和视频未上传的信息
                lastSortOrder: null,
                lastSortObj: null,
            }
        },
        computed: {
            collisionList() {
                let list = this.$store.state.websocket.collisions.list;
                if (list && list.forEach) return list;
                return [];
            },
            lastMaxCollisionTime() {
                return this.$store.getters['websocket/lastMaxCollisionTime'];
            }
        },
        watch: {
            collisionList() {
                this.collisionList.length === 0 ? this.tips = this.nodataStatus : this.tips = this.loadingStatus;
                let alertList = [], initAddressList = [];
                let unUploadImgResource = {}, unUploadVideoResource = {};
                this.collisionList.forEach((item, index) => {
                    initAddressList[index] = {latitude: item.Latitude, longitude: item.Longitude, address: ''};
                    let firstName = item.DriverFirstName || '',
                        lastName = item.DriverLastName || '';
                    let driverName = firstName + ' ' + lastName;
                    if ((firstName === '' && lastName === '') || firstName === 'Unknown') driverName = this.$t('globalText.unknown');
                    let showVehicleNew = this.lastMaxCollisionTime < item.Timestamp;
                    let hasImage,hasVideo;
                    //2021.2.1  && 改为 || ，可能存在一组摄像头未配置的情况
                    if (item.MainModel === "M810" || item.MainModel === "M820"){
                        //M810  M820
                        item.Event === 'Unpermitted'
                            //6.25添加。DriverPicturePath为M810、M820增加的字段,用于标识Device起来Unpermitted情况下的拍照。
                            ? hasImage = item.DriverPicturePath
                            : hasImage = item.ImageCamAPath || item.ImageCamBPath || item.ImageCamCPath;
                        hasVideo = item.VideoCamAPath || item.VideoCamBPath || item.VideoCamCPath;
                    }else {
                        //D700 D700S M800
                        item.Event === 'Unpermitted'
                            ? hasImage = item.ImageFPath
                            : hasImage = item.ImageFPath || item.ImageRPath;
                        hasVideo = item.VideoFPath || item.VideoRPath;
                    }

                    let resourceStatus = {showImgNew: false, showVideoNew: false};
                    alertList.push({
                        isUnPermittedDriver: !!item.UnpermittedDriver,
                        index: index,
                        data: item,
                        vehicle: item.VehicleId,
                        fleet: item.FleetName || this.$t('liveTracking.unassigned'),
                        latitude: item.Latitude,
                        longitude: item.Longitude,
                        event: item.Event,
                        location: '',
                        driver: driverName,
                        // time: this.$dateFormat.date(item.Timestamp, this.mixin_dateTimeFormat, this.mixin_timezone),
                        timestamp: item.Timestamp,
                        hasImage: hasImage,
                        hasVideo: hasVideo,
                        showVehicleNew: showVehicleNew,
                        showImgNew: resourceStatus.showImgNew,
                        showVideoNew: resourceStatus.showVideoNew
                    });
                    if (!hasImage) unUploadImgResource[item.EventId] = 1;
                    if (!hasVideo) unUploadVideoResource[item.EventId] = 1;
                });
                this.$Gps.getAllLocation(initAddressList).then(result => {
                    result.forEach((location, index) => alertList[index].location = location);
                    this.alertsList = alertList;
                }).catch(errorResult => {
                    errorResult.forEach((location, index) => alertList[index].location = location);
                    this.alertsList = alertList;
                });
            }
        },
        methods: {
            handleClose() {
                this.$emit("onClose");
                this.$store.commit('websocket/closeNewCollisionStatus');
            },
            getLastUnuploadResources() {
                if (this.lastResource) return this.lastResource;
                let resources = null;
                if (window.localStorage) {
                    let lastResources = localStorage.getItem('lastUnuploadResources');
                    if (lastResources) {
                        resources = JSON.parse(lastResources);
                        if (resources.image) resources.image = JSON.parse(resources.image);
                        if (resources.video) resources.video = JSON.parse(resources.video);
                    }
                }
                return resources;
            },
            updateLastUnuploadResources(imgRes, videoRes) {
                let resources = {image: JSON.stringify(imgRes), video: JSON.stringify(videoRes)};
                this.lastResource = JSON.stringify(resources);
                if (window.localStorage) {
                    try {
                        localStorage.setItem('lastUnuploadResources', JSON.stringify(resources));
                    } catch (e) {
                        console.log('failed to add unupload resources info to localStorage');
                    }
                }
                return resources;
            },
            getResourcesUploadStatus(lastUnuploadResources, showVehicleNew, eventId, hasImage, hasVideo) {
                let status = {
                    showImgNew: false,
                    showVideoNew: false
                };
                if (
                    (lastUnuploadResources && lastUnuploadResources.image && lastUnuploadResources.image[eventId] && hasImage) ||
                    (hasImage && showVehicleNew)
                ) status.showImgNew = true;
                if (
                    (lastUnuploadResources && lastUnuploadResources.video && lastUnuploadResources.video[eventId] && hasVideo) ||
                    (hasVideo && showVehicleNew)
                ) status.showVideoNew = true;
                return status;
            },
            formatTime(time) {
                if (time === 0 || isNaN(time)) return this.$t('globalText.na');
                let date = new Date(time * 1000);
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate(),
                    hour = date.getHours(),
                    minute = date.getMinutes(),
                    second = date.getSeconds();
                if (time === 0 || year === 1970) return this.$t('globalText.na');
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + day : '' + day;
                hour = hour < 10 ? '0' + hour : '' + hour;
                minute = minute < 10 ? '0' + minute : '' + minute;
                second = second < 10 ? '0' + second : '' + second;
                return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
            },
            formatTimeToDate(time) {
                if (time === 0 || isNaN(time)) return this.$t('globalText.na');
                let date = new Date(time * 1000);
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                if (time === 0 || year === 1970) return this.$t('globalText.na');
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + day : '' + day;
                return year + '-' + month + '-' + day;
            },
            getLocation(latitude, longitude) {
                if (latitude === 0 && longitude === 0) return this.$t('globalText.na');
                return 'GPS: ' + latitude + ', ' + longitude;
            },
            handleLocationSelect(collision) {
                let params = {
                    category: ALERT_VALUE,
                    categoryValue: collision.data.Event,
                    date: this.formatTimeToDate(collision.data.Timestamp),
                    id: collision.data.EventId,
                };
                this.$parent.isShowMessageDialog = false;
                this.$store.commit('websocket/setCurrentCollisionInfo', {collision, params});
                // this.$router.push({name: 'tripHistory', params: {globalCollisionInfo: collision}});
                this.$router.push({name: 'tripHistory', params: params});
            },
            sortTime(a, b) {
                if (a.timestamp > b.timestamp) return 1;
                if (a.timestamp < b.timestamp) return -1;
                return Sort.NameSort(a.event, b.event);
            },
            handleSortChange(data) {
                if (this.lastSortObj && this.lastSortObj.prop !== data.prop) {
                    this.lastSortObj.column.order = null;
                    this.lastSortObj.order = null;
                }
                const ASCE = 'ascending', DESC = 'descending';
                if (data.order === null) {
                    if (this.lastSortOrder === null)
                        this.lastSortOrder = ASCE;
                    else
                        this.lastSortOrder = this.lastSortOrder === 'ascending' ? DESC : ASCE;
                    data.column.order = this.lastSortOrder;
                } else {
                    this.lastSortOrder = data.column.order;
                }
                let tagNum = this.lastSortOrder === ASCE ? 1 : -1;
                this.alertsList.sort((A, B) => {
                    if (data.prop === 'time')
                        return tagNum * (A.timestamp - B.timestamp);
                    else if (data.prop === 'event') {
                        let diffNum = A.event.trim().localeCompare(B.event.trim());
                        if (diffNum) return tagNum * diffNum;
                        if (A.timestamp > B.timestamp) return -1;
                        if (A.timestamp < B.timestamp) return 1;
                        return 0;
                    }
                    else {
                        let diffNum = tagNum * Sort.NameSort(A[data.prop].trim(), B[data.prop].trim());
                        if (diffNum) return diffNum;
                        if (A.timestamp > B.timestamp) return -1;
                        if (A.timestamp < B.timestamp) return 1;
                        return 0;
                    }
                });
                this.lastSortObj = data;
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $second-blue = $color_bule_second

    .alert-msg-table .el-table__body
        width 100 !important


    >>> .alert-msg-table .ascending .sort-caret.ascending,
    >>> .alert-msg-table .descending .sort-caret.descending
        font-size 12px
        border-top-color rgba(0, 0, 0, 0)
        border-bottom-color rgba(0, 0, 0, 0)
        font-family: 'via' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

    >>> .alert-msg-table .descending .sort-caret.descending::before
        content "\e940"
        color #ffffff
        position relative
        top -11px
        left -5px

    >>> .alert-msg-table .ascending .sort-caret.ascending::before
        content "\e93f"
        color #ffffff
        position relative
        top 0
        left -5px

    >>> .alert-msg-table .ascending .sort-caret.ascending, >>> .el-table .ascending .sort-caret.descending,
    >>> .alert-msg-table .descending .sort-caret.ascending, >>> .el-table .descending .sort-caret.descending
        border-bottom-color rgba(0, 0, 0, 0)
        border-top-color rgba(0, 0, 0, 0)

    >>> .alert-msg-table .sort-caret.ascending
        border-bottom-color $color_white

    >>> .alert-msg-table .sort-caret.descending
        border-top-color $color_white

    >>> .alert-msg-table th
        padding 6px 0

    >>> .el-table--border th, >>> .el-table--border td
        border-right 0 !important

    >>> .alert-msg-table .el-checkbox__inner {
        border-color: $color_black_third;
    }

    >>> .alert-msg-table .el-table__body-wrapper:not(.el-table__row) {
        cursor default
    }

    >>> .alert-msg-table td {
        padding: 6px 0 !important;
    }

    >>> .alert-msg-table td, >>> .alert-msg-table th.is-leaf {
        border-bottom: 1px solid $color_gray_third;
    }

    >>> .alert-msg-table td:first-child {
        border-left: 1px solid $color_gray_third;
    }

    >>> .alert-msg-table td:last-child {
        border-right: 1px solid $color_gray_third;
    }

    >>> .alert-msg-table th {
        color: white;
        background-color: $color_blue_second;
        border-bottom: none !important;
    }

    >>> .alert-msg-table .el-table__body tr.el-table__row--striped td {
        background: $color_gray_first;
    }

    >>> .alert-msg-table tr:hover > td {
        background-color: $color_blue_hover !important;

    }

    >>> .alert-msg-table .el-table__empty-block {
        border: 1px solid $color_gray_third;
        border-top: none;
        box-sizing border-box !important;
    }


    >>> .el-table th > .cell
        min-height 23px

    >>> .el-table .cell
        overflow visible
        min-height_bak 30px

    .am-tips-box
        padding-top 15px
        font-weight 600

    .am-car-crash::before
        color #ffffff

    .icon-location {
        color: $color_blue_second;
        cursor: pointer;
    }

    .am-icon-event
        display inline-block
        width 16px

    .red-tips
        color red

    .am-list-box
        padding 24px 24px 10px

    .am-location-box
        cursor pointer

    .am-location-box:hover
        color $color_blue_second

    .am-word-show
        overflow hidden
        word-break keep-all
        word-wrap break-word

    .am-icon
        display inline-block
        width 22px

    .am-vehicle-box
        position relative

        .am-icon-new
            width 0
            background-color #ffffff
            display inline-block
            z-index 1
            position relative
            bottom 20px
            left -8px
            font-size 10px

        .am-vehicle-name
            z-index 2
            position relative
            top 3px
            display inline-block
            width 100%

    .am-media-box
        position relative

        .am-icon-check, .am-icon-close
            font-weight 800
            font-size 18px
            position relative

        .am-icon-close
            color #FF5454

        .am-icon-check
            color #45CF00

        .via-new
            font-size 10px
            position absolute
            left -18px
            top -4px

    .am-text-one-line
        overflow hidden
        white-space nowrap
        text-overflow ellipsis
</style>
