<template>
    <v-base-pop-dialog title="Partner Program" @onClose="handleClose" width="500">
        <template slot="content">
            <div style="padding: 30px">
                <div class="content1">
                    {{$t('settings.partner_program_content1')}}
                </div>

                <div class="content2">
                    {{$t('settings.partner_program_content2')}}
                </div>
                <div class="box_device_platform">
                    <div class="device_title">
                        <div><b>Models</b></div>
                        <div><b>Log Files</b></div>
                        <div><b>Event Video</b></div>
                    </div>
                    <div class="device_title" v-for="(item,index) in devicePlatform" :key="index">
                        <div>{{item}}</div>
                        <div>{{item.indexOf('D700') !== -1 ? '3MB/hr' : 'TBD'}}</div>
                        <div>{{item.indexOf('D700') !== -1 ? '3MB/event' : 'TBD'}}</div>
                    </div>
                </div>
                <div class="box_switch">
                    <div class="logfile-box">
                        <span class="logfile-box-span">{{$t('settings.log_files')}}</span>
                        <div style="display: inline-block;">
                            <el-switch
                                    v-model="logFiles"
                                    active-color="#3c4dd7"
                                    inactive-color="#808080">
                            </el-switch>
                        </div>
                    </div>
                    <div class="event-videos-box">
                        <span class="event-videos-box-span">{{$t('settings.event_videos')}}</span>
                        <div style="display: inline-block;">
                            <el-switch
                                    v-model="eventVideos"
                                    active-color="#3c4dd7"
                                    inactive-color="#808080">
                            </el-switch>
                        </div>
                    </div>
                </div>
                <div class="button-box">
                    <el-button :loading="isLoading" type="primary" size="small" class="ut-btn" @click="onClickSave">
                        {{$t('globalText.save')}}
                    </el-button>
                    <el-button :loading="isLoading" type="info" size="small" class="ut-btn" @click="onClickCancel">
                        {{$t('globalText.cancel')}}
                    </el-button>
                </div>
            </div>

        </template>
    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../common/BasePopDialog'
    import ServerAPI from "../../assets/js/ServerAPI";

    export default {
        name: "PartnerProgram",
        components: {vBasePopDialog},
        data() {
            return {
                logFiles: this.$store.getters['companyInfo'].PartnerProgram.LogFiles === 1,
                eventVideos: this.$store.getters['companyInfo'].PartnerProgram.EventVideos === 1,
                isLoading: false,
                devicePlatform:Object.keys(this.$store.getters['companyInfo'].DeviceSettings),
            }
        },
        methods: {
            handleClose() {
                this.$emit("onClose")
            },
            onClickSave() {
                this.isLoading = true;
                ServerAPI.company.updateCompany({
                    Id: this.$store.getters['companyInfo'].Id,
                    PartnerProgram: {
                        LogFiles: this.logFiles ? 1 : 0,
                        EventVideos: this.eventVideos ? 1 : 0
                    }
                }, () => {
                    this.isLoading = false;
                    this.$message({message: this.$t('globalText.savedSuccessfully'), type: 'success'});
                    this.$emit("onClose")
                }, () => {
                    this.isLoading = false;
                    this.$message({message: this.$t('globalText.failedToSave'), type: 'warning'});
                })
            },
            onClickCancel() {
                this.$emit("onClose")
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .content1 {
        line-height: 20px;
        word-wrap: break-word
        word-break: normal !important;
    }

    .content2 {
        margin-top 20px
        line-height: 20px;
        word-wrap: break-word
        word-break: normal !important;
    }

    .box_device_platform {
        margin-top 20px
        padding 0 15px
    }

    .device_title {
        display flex
        width 50%
        height 30px
        flex-direction row
    }

    .device_title >div {
        height 50px
        flex 1
    }

    .box_switch {
        margin-top 20px
        border: #E2E2E2 1px solid;
        border-radius: 10px;
        padding: 20px 15px
    }

    .ut-btn
        width 140px
        margin-left 15px
        margin-right 15px

    .logfile-box {
        line-height: 30px;
        margin-bottom: 20px
    }

    .logfile-box-span {
        display: inline-block;
        width: 150px
    }

    .event-videos-box {
        line-height: 30px
    }

    .event-videos-box-span {
        display: inline-block;
        width: 150px
    }

    .button-box {
        margin-top 20px
        text-align: center
    }
</style>
