<template>
    <div class="route-date-selector" @mousedown="handleSelectBoxMouseDown"
         @mouseleave="handleSelectBoxMouseLeave">
        <el-input size="mini" class="rds-date-input" prefix-icon="el-icon-date" v-model="inputDate" @change="handleInputChange"/>
        <div class="rds-calendar" v-show="showCalendar" style="z-index: 2001;">
            <div class="rds-tag"/>
            <el-calendar class="rds-calendar-box" v-model="currentDate" :first-day-of-week="7">
                <template slot="dateCell" slot-scope="{date, data}">
                    <div class="his-left-calendar-date-div"
                         :class="{'has-information': hasTrips(data.day), 'has-collision-circle': hasCollision(data.day), 'has-collision-dot-bak': hasCollision(data.day), 'calender-is-selected': data.isSelected }">
                        {{ data.day.split('-')[2] }}
                        <span class="his-left-calendar-red-tips"/>
                    </div>
                </template>
            </el-calendar>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DateSelector",
        props: {
            date: {
                type: Date,
            },
            dateTime: { // 变更当前日期传入这个值
                type: Number
            },
            collisions: { // 发生碰撞的日期
                type: Array,
                required: true
            },
            trips: { // 发生事件的日期
                type: Array,
                required: true
            }
        },
        beforeMount() {
            this.inputDate = this.formatDate(this.currentDate);
        },
        data() {
            return {
                currentDate: this.date || new Date(), // new Date()
                currentCollisions: this.collisions,
                currentTrips: this.trips,
                showCalendar: false,
                inputDate: '',
            }
        },
        computed: {},
        watch: {
            date(value) {
                this.currentDate = value;
            },
            currentDate(newDate, oldDate) {
                let isClickMonthBtn = newDate.getMonth() !== oldDate.getMonth() && newDate.getDate() === 1;
                if (isClickMonthBtn === false) this.showCalendar = false;
                this.inputDate = this.formatDate(newDate);
                this.$emit('currentDate', newDate);
            },
            collisions() {
                this.currentCollisions = this.collisions;
            },
            trips() {
                this.currentTrips = this.trips;
            },
            dateTime(value) {
                if (value <= 0 || !value) return;
                this.currentDate = new Date(value);
            },
        },
        methods: {
            handleSelectBoxMouseDown() {
                this.showCalendar = true;
            },
            handleSelectBoxMouseLeave() {
                this.showCalendar =false;
            },
            hasCollision(date) {
                return this.currentCollisions.find((item) => {
                    let itemArr = item.split('-');
                    let dateArr = date.split('-');
                    if (!itemArr[2]) return false;
                    return itemArr[0] === dateArr[0]
                        && parseInt(itemArr[1]) === parseInt(dateArr[1])
                        && parseInt(itemArr[2]) === parseInt(dateArr[2]);
                });
            },
            hasTrips(date) {
                return this.currentTrips.find((item) => {
                    let itemArr = item.split('-');
                    let dateArr = date.split('-');
                    if (!itemArr[2]) return false;
                    return itemArr[0] === dateArr[0]
                        && parseInt(itemArr[1]) === parseInt(dateArr[1])
                        && parseInt(itemArr[2]) === parseInt(dateArr[2]);
                });
            },
            handleInputChange(newDate) {
                if (this.isValid(newDate)) this.currentDate = new Date(newDate);
                this.inputDate = this.formatDate(this.currentDate);
            },
            formatDate(date) {
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                month = month > 9 ? month : '0' + month;
                day = day > 9 ? day : '0' + day;
                return `${year}-${month}-${day}`;
            },
            isValid(dateStr) {
                let pattern = /^\d{4}-\d{2}-\d{2}$/;
                if (pattern.test(dateStr) === false) return false;
                let dateArr = dateStr.split('-');
                let currYear = new Date().getFullYear();
                if (currYear < dateArr[0] ||  dateArr[1] > 12 ) return false;
                return new Date(dateArr[0], dateArr[1], 0).getDate() >= dateArr[2];
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .route-date-selector
        color $color_black_first
        position relative

        .rds-calendar
            line-height 14px
            font-size 14px
            border-bottom 0 solid $color_gray_third;
            position absolute
            z-index 2000
            width 280px
            top 52px

            .rds-tag
                position relative
                top 1px
                left 30px
                width: 0;
                height: 0;
                overflow: hidden;
                font-size: 0; /*是因为, 虽然宽高度为0, 但在IE6下会具有默认的 */
                line-height: 0; /* 字体大小和行高, 导致盒子呈现被撑开的长矩形 */
                border-width: 6px;
                border-style: solid dashed dashed dashed; /*IE6下, 设置余下三条边的border-style为dashed,即可达到透明的效果*/
                border-color: transparent transparent #ffffff transparent;

        .his-left-calendar-date-div
            width 27px
            height 20px
            border-radius 30px
            padding 6px 0 1px
            position relative
            top -8px
            left -4px

            .his-left-calendar-red-tips
                display none;
                position absolute
                right -1px
                top -1px
                height 10px
                width 10px
                border-radius 100%

        .calender-is-selected
            background-color $color_blue_second !important
            color $color_white !important
            border 1px solid $color_blue_second

        .has-collision-dot
            background-color $color_gray_third

            .his-left-calendar-red-tips
                background-color $color_collision
                display block !important

        .has-collision-circle
            background-color $color_gray_third
            border 1px solid $color_collision !important

        .has-information
            background-color $color_gray_third
            border_bak 1px solid $color_gray_third
            border 1px solid #bbbbbb

        >>> .el-calendar
            border 1px solid $color_gray_third
            padding-top 10px
            background-color $color_gray_first
            -moz-user-select: none; /*火狐*/
            -webkit-user-select: none; /*webkit浏览器*/
            -ms-user-select: none; /*IE10*/
            -khtml-user-select: none; /*早期浏览器*/
            user-select: none;

        >>> .el-calendar__header
            padding 0 0 0
            border 0
            height 16px

        >>> .el-calendar__body
            padding 0 0 5px

            th
                line-height 15px

        >>> .el-calendar-table .el-calendar-day
            height 30px
            text-align center

        >>> .el-button--mini, >>> .el-button--mini.is-round
            padding 7px 4px
            overflow hidden

        >>> .el-calendar__header
            position relative

        >>> .el-button-group > .el-button:nth-child(1), >>> .el-button-group > .el-button:nth-child(3)
            border 0
            width 40px
            padding-top 0
            padding-bottom 0
            color transparent
            background-color $color_gray_first

        >>> .el-button-group > .el-button:nth-child(1):before, >>> .el-button-group > .el-button:nth-child(3):before
            display: inline-block
            width 30px
            text-align center
            font-size 18px

        >>> .el-button-group > .el-button:nth-child(1)
            position absolute
            top 0
            left 0

        >>> .el-button-group > .el-button:nth-child(1):before
            content '<'
            color $color_black_first

        >>> .el-button-group > .el-button:nth-child(1):after
            content ' '
            color $color_black_first
            position absolute
            left 0
            z-index 1
            display inline-block
            width 5px
            height 20px
            background-color $color_gray_first

        >>> .el-button-group > .el-button:nth-child(2)
            width 0
            padding 0

        >>> .el-button-group > .el-button:nth-child(3)
            position absolute
            top 0
            right 0

        >>> .el-button-group > .el-button:nth-child(3):before
            content '>'
            color $color_black_first

        >>> .el-button-group > .el-button:nth-child(1):hover:before, >>> .el-button-group > .el-button:nth-child(3):hover:before
            color $color_blue_second

        >>> .el-calendar-table td.is-selected
            color $color_black_first

        >>> .el-calendar__title
            position absolute
            top 0
            width 100%
            text-align center
            color $color_black_first
            font-weight 800
            line-height 25px

        >>> .el-calendar-table
            padding 0 10px 0

        >>> .el-calendar-table td
            border 0
            border-radius 35px

        >>> .el-calendar-table > thead > th
            border-bottom 1px solid $color_gray_third
            text-align center
            padding-top 9px
            padding-bottom 9px

        >>> .el-calendar-table td.is-today
            color $color_blue_second
            font-weight 600

        >>> .el-calendar-table:not(.is-range) td.next
            color $color_black_third

        >>> .el-calendar-table:not(.is-range) td.prev
            color $color_black_third

        >>> .el-calendar-table:not(.is-range) td.prev .has-collision-circle,
        >>> .el-calendar-table:not(.is-range) td.next .has-collision-circle
            border-color $color_collision !important
            opacity 0.9
            background-color $color_gray_third

        >>> .el-calendar-table:not(.is-range) td.prev .has-information,
        >>> .el-calendar-table:not(.is-range) td.next .has-information
            background-color $color_gray_third
            opacity 0.9
</style>
