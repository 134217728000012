<template>
    <div class="header-selector" v-if="this.$route.name === 'tripHistory'">
        <div class="hs-type">
            <el-select v-model="category" size="mini" @change="handleCategoryChange">
                <el-option v-for="item in cateOptions"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
        </div>
        <div class="hs-search">
            <el-select style="width: 100%;" v-model="cateValue" size="mini"
                       v-show="isShowAlertSelector" @change="handleAlertChange">
                <el-option v-for="item in alertOption"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                </el-option>
            </el-select>
            <fleet-selector v-show="isShowFleetSelector"
                            size="mini"
                            :fleetList="fleet.list"
                            :default-fleet="fleet.current"
                            @currentFleet="handleFleetChange" />
            <driver-selector v-show="isShowDriverSelector" size="mini"
                             :drivers="driver.list"
                             :driver-id="driver.current"
                             :api-drivers="driver.apiList"
                             @currentDriver="handleDriverChange"/>
            <vehicle-selector v-show="isShowVehicleSelector" size="mini"
                              :vehicle-list="vehicle.list"
                              :default-vehicle="vehicle.current"
                              @currentVehicle="handleVehicleChange"/>
        </div>
        <div class="hs-date">
            <date-selector
                    style="width: 160px;"
                    v-if="showDateRange === false"
                    :date="startDate"
                    :collisions="daysList.collision"
                    :trips="daysList.trip"
                    @currentDate="handleDateChange"/>
            <date-range-selector
                    v-if="showDateRange === true"
                    :start-date="startDate"
                    :end-date="endDate"
                    :alert-days-list="daysList.collision"
                    :trip-days-list="daysList.trip"
                    @update-alert-trip-days="handleUpdateAlertAndTripDays"
                    @date-range-change="handleDateRangeChange"
            />
        </div>
    </div>
</template>

<script>
    import DateRangeSelector from "./DateRangeSelector";
    import DateSelector from "./DateSelector";
    import DriverSelector from "./DriverSelector";
    import VehicleSelector from "./VehicleSelector";
    import FleetSelector from "./FleetSelector";
    import bus from "../../utils/bus";
    import Sort from "../../assets/js/Sort";
    import Constant from './Constant';

    let {
        DRIVER_LABEL, VEHICLE_LABEL, ALERT_LABEL, FLEET_LABLE,
        DRIVER_VALUE, VEHICLE_VALUE, ALERT_VALUE, FLEET_VALUE,
        A_COLLISION_LABEL, A_PARKING_MODE_COLLISION_LABLE, A_DRIVER_ALERT_LABEL, A_UNPERMITTED_DRIVER_LABLE, A_ALL_LABEL,
        A_COLLISION_VALUE, A_PARKING_MODE_COLLISION_VALUE, A_DRIVER_ALERT_VALUE, A_UNPERMITTED_DRIVER_VALUE, A_ALL_VALUE,
        API_VEHICLE_DATA, API_DRIVER_DATA, API_FLEET_DATA,
        DATE_DAYS_LIST,
        CHANGE_ALL_DATA,
        CLEAR_ALL_HISTORY_DATA
    } = Constant;

    export default {
        name: "HeaderSelector",
        components: {
            DateRangeSelector,
            DateSelector,
            DriverSelector,
            VehicleSelector,
            FleetSelector
        },
        created() {
            bus.$on('trip-history-filter-condition', this.handleTripHistoryCondition); // 收到的数据格式为：{type: '', data: ''}
        },
        data() {
            return {
                showDateRange: true,
                category: ALERT_VALUE,
                cateValue: A_COLLISION_VALUE,
                startDate: new Date(),
                endDate: new Date(),
                calendar: {
                    begin: null,
                    end: null
                },
                alert: {
                    current: A_COLLISION_VALUE,
                },
                driver: {
                    list: [], // { value: item.Id, label: item.FirstName + ' ' + item.LastName }
                    apiList: [], // { Id: driver.Id + '', FirstName: driver.FirstName, LastName: driver.LastName }
                    current: "",
                },
                vehicle: {
                    list: [], // [{ value: item.Id, label: item.Id + ' - ' + item.Name}]
                    current: "",
                },
                fleet: {
                    list: [],
                    current: "",
                },
                daysList: {
                    map: new Map(),
                    collision: [], // ['2019-12-31', '2019-12-20'] 存在collision的日期
                    trip: [], // ['2019-12-31', '2019-12-20'] 存在trip的日期
                }
            }
        },
        computed: {
            cateOptions() {
                let driver = this.$t('tripHistory.searchType.driver'),
                    fleet = this.$t('tripHistory.searchType.fleet'),
                    vehicle = this.$t('tripHistory.searchType.vehicle'),
                    alert = this.$t('tripHistory.searchType.alert');
                return  [
                    {label: alert, value: ALERT_VALUE},
                    {label: driver, value: DRIVER_VALUE},
                    {label: fleet, value: FLEET_VALUE},
                    {label: vehicle, value: VEHICLE_VALUE},
                ];
            },
            alertOption() {
                let collisionAlert = this.$t('tripHistory.alertList.collisionAlert'),
                    driverAlert = this.$t('tripHistory.alertList.driverAlert'),
                    parkingAlert = this.$t('tripHistory.alertList.parkingAlert'),
                    unpermittedDriver = this.$t('tripHistory.alertList.unpermittedDriver'),
                    all = this.$t('tripHistory.alertList.all');
                return [
                    {label: collisionAlert, value: A_COLLISION_VALUE},
                    {label: driverAlert, value: A_DRIVER_ALERT_VALUE},
                    {label: parkingAlert, value: A_PARKING_MODE_COLLISION_VALUE},
                    {label: unpermittedDriver, value: A_UNPERMITTED_DRIVER_VALUE},
                    {label: all, value: A_ALL_VALUE},
                ];
            },
            isShowAlertSelector() {
                return this.category === ALERT_VALUE;
            },
            isShowDriverSelector() {
                return this.category === DRIVER_VALUE;
            },
            isShowVehicleSelector() {
                return this.category === VEHICLE_VALUE;
            },
            isShowFleetSelector() {
                return this.category === FLEET_VALUE;
            },
        },
        methods: {
            handleUpdateAlertAndTripDays(startDate, endDate) {
                if (startDate && endDate) {
                    this.daysList.collision = [];
                    this.daysList.trip = [];
                    if (!this.category || !this.cateValue || !this.startDate || !this.endDate) return;
                    this.calendar.begin = startDate;
                    this.calendar.end = endDate;
                    let reqCategory = this.category,
                        reqCateValue = this.cateValue,
                        reqStartDate = startDate,
                        reqEndDate = endDate;
                    this.getDaysListData(reqCategory, reqCateValue, reqStartDate, reqEndDate, true)
                        .then(({tripDaysList, alertDaysList}) => {
                            if (
                                reqCategory === this.category
                                && reqCateValue === this.cateValue
                                && reqStartDate === this.calendar.begin
                                && reqEndDate === this.calendar.end
                            ) {
                                this.daysList.collision = alertDaysList || [];
                                this.daysList.trip = tripDaysList || [];
                            }
                        }).catch(() => {
                            if (
                                reqCategory === this.category
                                && reqCateValue === this.cateValue
                                && reqStartDate === this.calendar.begin
                                && reqEndDate === this.calendar.end
                            ) {
                                this.daysList.collision = [];
                                this.daysList.trip = [];
                            }
                        });
                } else {
                    this.initDaysList();
                }
            },
            initDaysList() {
                this.daysList.collision = [];
                this.daysList.trip = [];
                if (!this.category || !this.cateValue || !this.startDate || !this.endDate) return;
                let reqCategory = this.category,
                    reqCateValue = this.cateValue,
                    reqStartDate = this.startDate,
                    reqEndDate = this.endDate;
                this.getDaysListData(reqCategory, reqCateValue, reqStartDate, reqEndDate).then(({tripDaysList, alertDaysList}) => {
                    if (
                        reqCategory === this.category
                        && reqCateValue === this.cateValue
                        && reqStartDate === this.startDate
                        && reqEndDate === this.endDate
                    ) {
                        this.daysList.collision = alertDaysList || [];
                        this.daysList.trip = tripDaysList || [];
                    }
                }).catch(() => {
                    if (
                        reqCategory === this.category
                        && reqCateValue === this.cateValue
                        && reqStartDate === this.startDate
                        && reqEndDate === this.endDate
                    ) {
                        this.daysList.collision = [];
                        this.daysList.trip = [];
                    }
                });
            },
            getDaysListData(category, categoryValue, startDate, endDate, isCanlendar = false) {
                let start = startDate, end = endDate;
                if (isCanlendar === false) {
                    let date = this.getStartAndEndDateForTrip(startDate, endDate);
                    start = date.start;
                    end = date.end;
                }
                let promise = (resolve, reject) => {
                    if (category === DRIVER_VALUE) {
                        this.getTripDriverDaysList(categoryValue, start, end)
                            .then(({Trip, Collision, ParkingCollision, DriverAlert, Unpermitted}) => {
                                let tripDaysList = [], alertDaysList = [];
                                tripDaysList = Trip;
                                Collision && Collision.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                DriverAlert && DriverAlert.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                Unpermitted && Unpermitted.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                let validAlertDaysList = [];
                                tripDaysList.forEach(day => {
                                    if (alertDaysList.findIndex(alertDay => alertDay === day) !== -1)
                                        validAlertDaysList.push(day);
                                });
                                alertDaysList = validAlertDaysList;
                                resolve({tripDaysList, alertDaysList});
                            })
                            .catch(() => {
                                reject({})
                            });
                    } else if (category === VEHICLE_VALUE) {
                        this.getTripVehicleDaysList(categoryValue, start, end)
                            .then(({Trip, Collision, ParkingCollision, DriverAlert, Unpermitted}) => {
                                let tripDaysList = [], alertDaysList = [];
                                tripDaysList = Trip;
                                Collision && Collision.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                DriverAlert && DriverAlert.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                Unpermitted && Unpermitted.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                let validAlertDaysList = [];
                                tripDaysList.forEach(day => {
                                    if (alertDaysList.findIndex(alertDay => alertDay === day) !== -1)
                                        validAlertDaysList.push(day);
                                });
                                alertDaysList = validAlertDaysList;
                                ParkingCollision && ParkingCollision.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                resolve({tripDaysList, alertDaysList});
                            })
                            .catch(() => {
                                reject({})
                            });
                    } else if (category === FLEET_VALUE) {
                        this.getTripFleetDaysList(categoryValue, start, end)
                            .then(({Trip, Collision, ParkingCollision, DriverAlert, Unpermitted}) => {
                                let tripDaysList = [], alertDaysList = [];
                                tripDaysList = Trip;
                                Collision && Collision.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                DriverAlert && DriverAlert.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                Unpermitted && Unpermitted.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                let validAlertDaysList = [];
                                tripDaysList.forEach(day => {
                                    if (alertDaysList.findIndex(alertDay => alertDay === day) !== -1)
                                        validAlertDaysList.push(day);
                                });
                                alertDaysList = validAlertDaysList;
                                ParkingCollision && ParkingCollision.forEach(date => {
                                    if (alertDaysList.findIndex(day => day === date) === -1) alertDaysList.push(date);
                                });
                                resolve({tripDaysList, alertDaysList});
                            })
                            .catch(() => {
                                reject({})
                            });
                    } else if (category === ALERT_VALUE) {
                        this.getTripEventDaysList(categoryValue, start, end).then(result => {
                            let tripDaysList = [], alertDaysList = [];
                            tripDaysList = result.Data;
                            alertDaysList = result.Data;
                            resolve({tripDaysList, alertDaysList});
                        }).catch(() => {
                            reject({});
                        })
                    } else {
                        reject({});
                    }
                };
                return new Promise(promise);
            },
            getTripDriverDaysList(driverId, start, end) {
                let key = `tripDriver-dId-${driverId}-${start}-${end}`;
                let promise = (resolve, reject) => {
                    if (this.daysList.map.has(key)) {
                        resolve(this.daysList.map.get(key));
                        return;
                    }
                    this.$serverApi.trip.driver({
                        type: 'Date',
                        driverId: driverId,
                        startDate: start,
                        endDate: end
                    }, (data) => {
                        this.daysList.map.set(key, data);
                        resolve(data);
                    }, () => {
                        reject({});
                    })
                };
                return new Promise(promise);
            },
            getTripFleetDaysList(fleetId, start, end) {
                let key = `tripVehicle-vId-${fleetId}-${start}-${end}`;
                let promise = (resolve, reject) => {
                    if (this.daysList.map.has(key)) {
                        resolve(this.daysList.map.get(key));
                        return;
                    }
                    this.$serverApi.trip.fleet({
                        type: 'Date',
                        fleetId: fleetId,
                        startDate: start,
                        endDate: end
                    }, (data) => {
                        this.daysList.map.set(key, data);
                        resolve(data);
                    }, () => {
                        reject({});
                    })
                };
                return new Promise(promise);
            },
            getTripVehicleDaysList(vehicleId, start, end) {
                let key = `tripVehicle-vId-${vehicleId}-${start}-${end}`;
                let promise = (resolve, reject) => {
                    if (this.daysList.map.has(key)) {
                        resolve(this.daysList.map.get(key));
                        return;
                    }
                    this.$serverApi.trip.vehicle({
                        type: 'Date',
                        vehicleId: vehicleId,
                        startDate: start,
                        endDate: end
                    }, (data) => {
                        this.daysList.map.set(key, data);
                        resolve(data);
                    }, () => {
                        reject({});
                    })
                };
                return new Promise(promise);
            },
            getTripEventDaysList(eventType, start, end) {
                let key = `tripEvent-${eventType}-${start}-${end}`;
                let promise = (resolve, reject) => {
                    if (this.daysList.map.has(key)) {
                        resolve(this.daysList.map.get(key));
                        return;
                    }
                    this.$serverApi.trip.event({
                        type: 'Date',
                        event: eventType,
                        startDate: start,
                        endDate: end
                    }, data => {
                        this.daysList.map.set(key, data);
                        resolve(data);
                    }, () => {
                        reject({});
                    });
                };
                return new Promise(promise);
            },
            handleTripHistoryCondition(action) {
                switch (action.type) {
                    case API_DRIVER_DATA:
                        this.updateDriverInfo(action.data);
                        break;
                    case API_VEHICLE_DATA:
                        this.updateVehicleInfo(action.data);
                        break;
                    case API_FLEET_DATA:
                        this.updateFleetInfo(action.data);
                        break;
                    case DATE_DAYS_LIST:
                        // 通过TripHistory的页面来发送日期列表控制
                        // this.updateDaysList(action.data);
                        break;
                    case CHANGE_ALL_DATA:
                        this.changeAllData(action.data);
                        break;
                    case CLEAR_ALL_HISTORY_DATA:
                        this.clearAllData();
                    default:
                        break;
                }
            },
            handleCategoryChange(cate) {
                this.cateValue = null;
                switch (cate) {
                    case ALERT_VALUE:
                        this.cateValue = this.alert.current;
                        break;
                    case VEHICLE_VALUE:
                        this.cateValue = this.vehicle.current;
                        break;
                    case DRIVER_VALUE:
                        this.cateValue = this.driver.current;
                        break;
                    case FLEET_VALUE:
                        this.cateValue = this.fleet.current;
                        break;
                }
                this.busEmitParams();
                this.initDaysList();
            },
            handleDriverChange(driverId) {
                this.cateValue = driverId;
                this.driver.current = driverId;
                this.busEmitParams();
                this.initDaysList();
            },
            handleFleetChange(fleetId) {
                this.cateValue = fleetId;
                this.fleet.current = fleetId;
                this.busEmitParams();
                this.initDaysList();
            },
            handleVehicleChange(vehicleId) {
                this.cateValue = vehicleId;
                this.vehicle.current = vehicleId;
                this.busEmitParams();
                this.initDaysList();
            },
            handleAlertChange(alertType) {
                this.cateValue = alertType;
                this.alert.current = alertType;
                this.busEmitParams();
                this.initDaysList();
            },
            handleDateRangeChange(dateRange) {
                if (dateRange === null) {
                    this.startDate = null;
                    this.endDate = null;
                } else {
                    this.startDate = dateRange.startDate;
                    this.endDate = dateRange.endDate;
                }
                this.busEmitParams();
                this.initDaysList();
            },
            handleDateChange(date) {
                if (date !== this.startDate) {
                    this.startDate = date;
                    this.endDate = date;
                    this.busEmitParams();
                }
                this.initDaysList();
            },
            busEmitParams() {
                bus.$emit('trip-history-filter-action', {
                    type: 'params',
                    data: {
                        category: this.category,
                        cateValue: this.cateValue,
                        startDate: this.formatDate(this.startDate),
                        endDate: this.formatDate(this.endDate)
                    }
                })
            },
            updateDriverInfo(apiDriverData) {
                let tmpDriverList = Object.assign([], apiDriverData);
                tmpDriverList.sort((driverA, driverB) => {
                    let nameA = (driverA.FirstName || '') + ' ' + (driverA.LastName || '');
                    let nameB = (driverB.FirstName || '') + ' ' + (driverB.LastName || '');
                    return Sort.NameSort(nameA, nameB);
                });
                let optionList = [], apiList = [];
                tmpDriverList.forEach(item => {
                    optionList.push({value: item.Id, label: item.FirstName + ' ' + item.LastName});
                    apiList.push({Id: item.Id + '', FirstName: item.FirstName, LastName: item.LastName});
                });
                this.driver.list = optionList;
                this.driver.apiList = apiList;
            },
            updateVehicleInfo(apiVehicleData) {
                let tmpVehicleList = Object.assign([], apiVehicleData);
                tmpVehicleList.sort((vehicleA, vehicleB) => {
                    let idA = vehicleA.Name + ' - ' + (vehicleA.id + '').trim();
                    let idB = vehicleB.Name + ' - ' + (vehicleB.id + '').trim();
                    return Sort.NameSort(idA, idB);
                });
                let list = [];
                tmpVehicleList.forEach((item) => {
                    list.push({
                        value: item.Id,
                        label: item.Name.trim() + ' - ' + (item.Id + '').trim()
                    });
                });
                this.vehicle.list = list;
            },
            updateFleetInfo(apiFleetData) {
                let tmpFleetList = Object.assign([], apiFleetData.Sub);
                tmpFleetList.sort((fleetA, fleetB) => {
                    return Sort.NameSort(fleetA.Name.trim(), fleetB.Name.trim());
                });
                let list = [];
                tmpFleetList.forEach(item => {
                    list.push({
                        value: item.Id,
                        label: item.Name.trim()
                    })
                });
                this.fleet.list = list;
            },
            updateDaysList(data) {
                let {collision, trip} = data;
                this.daysList.collision = collision ? collision : [];
                this.daysList.trip = trip ? trip : [];
            },
            changeAllData(data) {
                let {category, categoryValue, startDate, endDate} = data;
                if (category) this.category = category;
                if (categoryValue) this.cateValue = categoryValue;
                if (startDate && endDate) {
                    this.startDate = new Date(startDate);
                    this.endDate = new Date(endDate);
                } else {
                    this.startDate = null;
                    this.endDate = null;
                }
            },
            clearAllData() {
                this.category = ALERT_VALUE;
                this.cateValue = A_COLLISION_VALUE;
                this.startDate = new Date();
                this.endDate = new Date();
                this.alert.current = A_COLLISION_VALUE;
                this.vehicle.current = "";
                this.driver.current = "";
                this.fleet.current = "";
            },
            formatDate(date) {
                if (!date) return null;
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                month = month > 9 ? month : '0' + month;
                day = day > 9 ? day : '0' + day;
                return `${year}-${month}-${day}`;
            },
            getStartAndEndDateForTrip(startDate, endDate) {
                let datePre = this.getPreMonth(startDate);
                if (startDate.getFullYear() === endDate.getFullYear() && startDate.getMonth() === endDate.getMonth()) {
                    endDate = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 1);
                }
                let dateNext = this.getNextMonth(endDate);
                return {
                    start: datePre.last7Day,
                    end: dateNext.first14Day
                };
            },
            getNextMonth(date) {
                let dateObj = this.formatDateStrToObj(date);
                let year = parseInt(dateObj.year);
                let month = parseInt(dateObj.month);
                let day = parseInt(dateObj.day);
                if (month === 12) {
                    year = year + 1;
                    month = 1;
                } else {
                    month = month + 1;
                }
                let endDay = new Date(year, month, 0).getDate();
                let last7Day = endDay - 6;
                day = day > endDay ? endDay : day;
                year = '' + year;
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + month : '' + month;
                return {
                    year: year,
                    month: month,
                    endDay: endDay + '',
                    str: year + '-' + month + '-' + day,
                    start: year + '-' + month + '-01',
                    end: year + '-' + month + '-' + endDay,
                    first14Day: year + '-' + month + '-14',
                    last7Day: year + '-' + month + '-' + last7Day
                };
            },
            getPreMonth(date) {
                let dateObj = this.formatDateStrToObj(date);
                let year = parseInt(dateObj.year);
                let month = parseInt(dateObj.month);
                let day = parseInt(dateObj.day);
                if (month === 1) {
                    year = year - 1;
                    month = 12;
                } else {
                    month = month - 1;
                }
                let endDay = new Date(year, month, 0).getDate();
                let last7Day = endDay - 6;
                day = day > endDay ? endDay : day;
                year = '' + year;
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + month : '' + month;
                return {
                    year: year,
                    month: month,
                    endDay: endDay + '',
                    str: year + '-' + month + '-' + day,
                    start: year + '-' + month + '-01',
                    end: year + '-' + month + '-' + endDay,
                    first14Day: year + '-' + month + '-14',
                    last7Day: year + '-' + month + '-' + last7Day
                };
            },
            formatDateStrToObj(date) {
                let year = date.getFullYear();
                let month = date.getMonth() + 1;
                let day = date.getDate();
                let monthStr = month < 10 ? '0' + month : '' + month;
                let dayStr = day < 10 ? '0' + day : '' + day;
                return {
                    year: '' + year,
                    month: monthStr,
                    day: dayStr,
                    str: year + '-' + month + '-' + dayStr,
                    start: year + '-' + monthStr + '-01',
                    end: year + '-' + monthStr + '-' + new Date(year, month, 0).getDate()
                };
            },
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    @media only screen and (min-width: 1500px) {
        .hs-empty-space {
            width 30px !important
            height 50px !important
        }

        .hs-type {
            width 120px !important
            margin-left 30px
        }

        .hs-search {
            width 260px !important
            margin-left 5px !important
        }
        .hs-date {
            width 270px !important
            margin-left 25px !important
        }
        .hs-date >>> .el-range-editor.el-input__inner {
            padding 3px 10px !important
        }
        .hs-date >>> .el-date-editor .el-range__close-icon {
            width 25px !important
        }
    }

    .header-selector

        >>> .el-input
            height 50px

        .hs-type, .hs-search, .hs-date
            float left

            >>> .el-input--mini .el-input__inner
                font-size 14px

        .hs-type, .hs-search
            >>> .el-input__inner
                padding-left 20px

            >>> .el-input__suffix-inner
                position relative
                top -1px

        .hs-type
            width 100px

        .hs-search
            width 240px
            margin-left 5px

            >>> .his-left-main-select-driver
                padding 0;
                background-color transparent

        .hs-date
            width 225px
            margin-left 5px

            >>> .el-range-editor.el-input__inner
                padding 3px 3px

            >>> .el-date-editor .el-range__close-icon
                width 15px

            >>> .el-input--prefix .el-input__inner
                padding-left 37px !important

            >>> .rds-calendar-box
                border-radius 4px

            >>> .el-input__prefix
                color $color_black_second

        .hs-type, .hs-search
            >>> .el-icon-arrow-up::before
                content "\e78f" !important
                color $color_black_second
</style>