<template>
    <div class="wrapper">
        <v-sidebar></v-sidebar>
        <v-head @rtcShowState="changeRtcState"></v-head>
        <v-web-r-t-c v-show="isShowVoice" :showComponent="isShowVoice" :driverName="driverName"></v-web-r-t-c>
        <div class="content-box" :class="{'content-collapse':collapse, 'history-content-box': $route.name === 'tripHistory'}" ref="contentBox" id="contentBox">
            <div class="loading-style" v-if="showLoading">
                <div class="load-icon">
                    <img src="../assets/image/waiting.png" class="img-animate"/>
                </div>
            </div>
            <div class="main-content" :class="{'history-main-content': $route.name === 'tripHistory'}" id="mainContent">
                <transition name="move" mode="out-in">
                    <keep-alive>
                        <!-- 由于需要记录滚动条的位置，只能单独使用router-view，不要和其他组件共用。 -->
                        <router-view name="tripHistory" />
                    </keep-alive>
                </transition>

                <transition name="move" mode="out-in">
                    <keep-alive>
                        <router-view name="fleetList" />
                    </keep-alive>
                </transition>


                <transition name="move" mode="out-in">
                    <router-view></router-view>
                </transition>

            </div>
        </div>
        <global-upgrade-firmware v-if="$store.getters['firmware/showTips']"/>
    </div>
</template>
<script>
    import vHead from '../components/layout/Header.vue';
    import vSidebar from '../components/layout/Sidebar.vue';
    import vWebRTC from '../components/header/WebRTCConnected'
    import store from "../store";
    import GlobalUpgradeFirmware from "../components/settings/GlobalUpgradeFirmware";
    import {cancelCurrentAxios} from "../assets/js/AxiosConfig";


    export default {
        data() {
            return {
                isShowVoice: false,
                driverName: null,
                views:[],
            };
        },
        components: {
            vHead,
            vSidebar,
            vWebRTC,
            GlobalUpgradeFirmware
        },
        watch: {
            '$route'() {
                this.$refs.contentBox.scrollTop = 0;
                cancelCurrentAxios();
            },
        },
        beforeMount() {
            this.$store.commit('timezone/startIntervalTimer');
        },
        created() {
            if (!this.$store.getters.touchSessionTimer) {
                let sessionTouchTimer = setInterval(() => {
                    this.$serverApi.auth.touch();
                }, 20 * 60 * 1000);
                this.$store.commit("touchSessionTimer", sessionTouchTimer);
            }
            if (!store.getters.companyInfo) {
                this.$store.dispatch('updateCompanyInfo');
            }
            this.$store.dispatch('websocket/createSocket');
            if (this.$store.state.driverData.driverList) {
                this.$store.dispatch('getDriverList');
            }
            if (this.$store.state.vehicleData.getVehicleList) {
                this.$store.dispatch('getVehicleList');
            }
        },
        computed: {
            collapse: function () {
                return this.$store.getters.getMenuCollapse;
            },
            showLoading:function () {
                return this.$store.getters.getAxiosLoadingState;
            }
        },
        methods: {
            changeRtcState(params) {
                console.log(`changeRtcState state = ${params}`);
                this.isShowVoice = params[0];
                this.driverName = params[1].toUpperCase() === 'Unknown'.toUpperCase() ? this.$t('globalText.unknown') : params[1];
            },
            getScrollTop() {
                return this.$refs.contentBox.scrollTop;
            },
            scrollTo(scrollTop) {
                this.$refs.contentBox.scrollTop = scrollTop;

            }
        }
    };
</script>

<style lang="stylus" scoped>
    .history-content-box
        bottom -10px
        padding-bottom 0

    .history-main-content
        padding-bottom 0

    .loading-style
        height: 100%
        position: fixed
        top: 75px
        width: 100%;
        z-index 101
        .load-icon
            position fixed
            width 200px
            height 200px
            top calc(50% + 28px - 100px)
            left calc(50% + 33px - 100px)
            border-radius 10px
            -webkit-user-select none;
            -moz-user-select none;
            -ms-user-select none;
            user-select none;

    .img-animate
        margin-left 55px
        margin-top 55px
        animation rotate 1s linear infinite

    @keyframes rotate
        0%{
            transform rotateZ(0deg)
        }
        100%{
            transform rotateZ(360deg)
        }
</style>
