<template>
    <div class="dashboard-com-date-selector">
        <div class="com-date-selector-left">
            <el-radio-group size="mini" v-model="dateTag">
                <el-radio-button :label="7">{{$t('dashboard.days7')}}</el-radio-button>
                <el-radio-button :label="30">{{$t('dashboard.days30')}}</el-radio-button>
                <el-radio-button :label="365">{{$t('dashboard.month')}}</el-radio-button>
            </el-radio-group>
        </div>
        <div class="com-date-selector-right" id="com-date-selector-right"
             :class="{ 'com-date-selector-active-date' : dateTag === -1 }">
            <el-date-picker
                    :class="{ 'com-date-selector-self-date-close' : showSelfDateClose }"
                    size="mini"
                    v-model="currentSelectedDateTime"
                    type="daterange"
                    :picker-options="pickerOptions"
                    @change="handleDateChange"
                    :format="dateFormat"
                    value-format="timestamp"
                    range-separator="to"
                    start-placeholder="Start date"
                    end-placeholder="End date">
            </el-date-picker>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ComDateSelector",
        props: {
            startDate: {
                type: Number,
                default: null
            },
            endDate: {
                type: Number,
                default: null
            },
            maxLastDays: {
                type: Number,
                default: 366
            }
        },
        beforeMount() {
            if (this.startDate === null || this.endDate === null) {
                let time = new Date().getTime();
                this.currentSelectedDateTime[0] = time - 7 * 24 * 3600 * 1000;
                this.currentSelectedDateTime[1] = time;
            }
        },
        data() {
            return {
                currentSelectedDateTime: [this.startDate, this.endDate],
                pickerOptions: {
                    disabledDate: (time) => {
                        let maxDay = this.maxLastDays;
                        if (this.currentSelectedDateTime !== null) {
                            let rangeD = this.dayRange ? this.dayRange : maxDay;
                            let minD = this.currentSelectedDateTime - 3600 * 1000 * 24 * rangeD;
                            let maxD = this.currentSelectedDateTime + 3600 * 1000 * 24 * rangeD;
                            return time.getTime() < minD || time.getTime() > maxD || time.getTime() > Date.now() || time.getTime() < (Date.now() - 3600 * 1000 * 24 * maxDay)
                        } else {
                            return time.getTime() < (Date.now() - 3600 * 1000 * 24 * maxDay) || time.getTime() > Date.now()
                        }
                    },
                },
                leftValue: -5
            }
        },
        computed: {
            dateFormat() {
                let format = '';
                switch (this.mixin_dateFormat) {
                    case 'YYYY-MM-DD':
                        format = 'yyyy-MM-dd';
                        break;
                    case 'MM-DD-YYYY':
                        format = 'MM-dd-yyyy';
                        break;
                    case 'DD-MM-YYYY':
                        format = 'dd-MM-yyyy';
                        break;
                    default:
                        format = 'yyyy-MM-dd';
                        break;
                }
                return format;
            },
            showSelfDateClose() {
                return this.dateTag !== -1;
            },
            dateTag: {
                get() {
                    if (
                        typeof this.currentSelectedDateTime !== 'object'
                        || this.currentSelectedDateTime === null
                        || typeof this.currentSelectedDateTime[0] === 'undefined'
                        || typeof this.currentSelectedDateTime[1] === 'undefined'
                    ) {
                        return -1;
                    }
                    let today = new Date(), endDate = new Date(this.currentSelectedDateTime[1]);
                    if (
                        today.getFullYear() !== endDate.getFullYear()
                        || today.getMonth() !== endDate.getMonth()
                        || today.getDate() !== endDate.getDate()
                    ) {
                        return -1;
                    }
                    let diff = this.currentSelectedDateTime[1] - this.currentSelectedDateTime[0];
                    let modeDate = diff % (24 * 3600 * 1000);
                    if (modeDate !== 0) {
                        return -1;
                    }
                    let days = diff / (24 * 3600 * 1000);
                    let tag = -1;
                    switch (days) {
                        case 7:
                            tag = 7;
                            break;
                        case 30:
                            tag = 30;
                            break;
                        case 365:
                            tag = 365;
                            break;
                    }
                    return tag;
                },
                set(value) {
                    let time = new Date().getTime();
                    this.currentSelectedDateTime = [];
                    this.currentSelectedDateTime[1] = time;
                    this.currentSelectedDateTime[0] = time - value * 24 * 3600 * 1000;
                    this.emitDate();
                }
            }
        },
        watch: {
            '$store.getters.screen'() {
                let dom = document.getElementById('com-date-selector-right');
                if (!dom || !dom.getElementsByClassName('el-input__icon')) return;
                if (this.detectZoom() <= 67) {
                    dom.getElementsByClassName('el-input__icon')[0].style['margin-left'] = '-7px'
                } else {
                    dom.getElementsByClassName('el-input__icon')[0].style['margin-left'] = '-5px'
                }
            }
        },
        methods: {
            emitDate() {
                if (this.currentSelectedDateTime === null) {
                    return;
                }

                let time = this.currentSelectedDateTime[1] - this.currentSelectedDateTime[0];
                let hours = time / 1000 / 3600;
                if (hours / 24 > this.maxLastDays) {
                    return;
                }

                let startDate = this.jsTimestampToString(this.currentSelectedDateTime[0]);
                let endDate = this.jsTimestampToString(this.currentSelectedDateTime[1]);
                this.$emit('date', startDate, endDate);
            },
            jsTimestampToString(timestamp) {
                let date = new Date(timestamp);
                let Y = date.getFullYear();
                let M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1);
                let D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
                let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
                let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();

                return '' + Y + M + D + h + m + s;
            },
            handleDateChange() {
                if (this.currentSelectedDateTime === null) {
                    return;
                }
                this.emitDate();
            },
            detectZoom() {
                var ratio = 0,
                    screen = window.screen,
                    ua = navigator.userAgent.toLowerCase();

                if (window.devicePixelRatio !== undefined) {
                    ratio = window.devicePixelRatio;
                } else if (~ua.indexOf('msie')) {
                    if (screen.deviceXDPI && screen.logicalXDPI) {
                        ratio = screen.deviceXDPI / screen.logicalXDPI;
                    }
                } else if (window.outerWidth !== undefined && window.innerWidth !== undefined) {
                    ratio = window.outerWidth / window.innerWidth;
                }

                if (ratio) {
                    ratio = Math.round(ratio * 100);
                }
                return ratio;
            }
        },
        mounted() {
            if (this.detectZoom() <= 67) {
                document.getElementById('com-date-selector-right').getElementsByClassName('el-input__icon')[0].style['margin-left'] = '-7px'
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .dashboard-com-date-selector
        display flex
        justify-content center
        align-items center


        .com-date-selector-right
            width 280px
            display flex
            justify-content_bak center
            align-items_bak center


        .com-date-selector-left, .com-date-selector-right
            float left

        .com-date-selector-left
            margin-right 15px

        .com-date-selector-right .com-date-selector-self-date-close
            width 26px
            cursor pointer
            overflow hidden

        .com-date-selector-active-date
            >>> .el-icon-date:before
                color $color_blue_second
                font-weight 800

        >>> .el-input__icon:hover
            color $color_blue_second

    >>> .el-range-input
        margin-left 6px;

    >>> .el-date-editor--daterange.el-input__inner
        width 323px
</style>
<style lang="stylus">
    .el-date-range-picker .popper__arrow
        left 7px !important
</style>
