<template>
    <div class="his-left-main-select-driver">
        <el-select
                class="his-left-select"
                @change="handleCurrentDriverChange"
                @blur="handleBlur"
                @focus="handleFocus"
                :size="size"
                v-model="currentDriver"
                filterable
                default-first-option
                @visible-change="handleVisibleChange"
                :filter-method="this.filterQueryWords"
                :placeholder="$t('tripHistory.driverName')"
                :class="{'unknown-driver': currentDriver == '0'}"
        >
            <slot name="empty">
                <div class="hlmsd-no-data" v-show="driverList.length === 0 && !showUnknown">
                    {{$t('globalText.noData')}}.
                </div>
            </slot>
            <el-option
                    v-for="(item, index) in driverList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
            </el-option>
            <el-option v-show="showUnknown" value="0" :label="$t('globalText.unknown')" :key="driverList.length">
                <span class="unknown-driver">{{$t('globalText.unknown')}}</span>
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "DriverSelector",
        props: {
            size: {type: String, default: 'medium'},
            drivers: {type: Array, required: true},
            apiDrivers: {type: Array, required: true},
            driverId: {required: true}
        },
        beforeMount() {
            this.driverList = this.drivers;
            this.currentDriver = this.driverId;
            this.lastDriverId = this.driverId;
        },
        data() {
            return {
                lastDriverId: "",
                currentDriver: "",
                driverList: [],
                showUnknown: true
            }
        },
        computed: {
            driverIdMap() {
                let map = new Map();
                this.apiDrivers.forEach(item => {
                    map.set(item.Id, item.FirstName + ' ' + item.LastName);
                });
                // map.set('0',"Unknown");
                map.set('0', this.$t('globalText.unknown'));
                return map;
            },
            driverNameList() {
                let names = [];
                this.apiDrivers.forEach(item => {
                    names.push(item.FirstName + ' ' + item.LastName);
                });
                return names;
            }
        },
        watch: {
            drivers(value) {
                this.driverList = value;
            },
            driverId(id) {
                this.currentDriver = id;
                this.lastDriverId = id;
            }
        },
        methods: {
            filterQueryWords(value) {
                if (value === '') {
                    if (this.driverNameList.findIndex(item => item === this.currentDriver) !== -1) {
                        value = this.currentDriver;
                    } else {
                        if (this.driverIdMap.has(this.currentDriver)) {
                            value = this.driverIdMap.get(this.currentDriver);
                        } else {
                            this.currentDriver = '';
                        }
                    }
                }
                if (value.trim().length === 0) {
                    this.driverList = this.drivers;
                    this.showUnknown = true;
                    return value;
                }
                let nameArr = value.trim().split(/\s+/);
                let firstName = nameArr[0] ? nameArr[0] : null;
                let lastName = nameArr[1] ? nameArr[1] : null;
                if (firstName === null && lastName === null) {
                    this.driverList = this.drivers;
                    this.showUnknown = true;
                    return value;
                }
                this.currentDriver = value;
                let tmpData = this.apiDrivers.filter((item) => {
                    if (item.FirstName.toLowerCase().indexOf(firstName.toLowerCase()) === -1) return false;
                    return !(lastName !== null && item.LastName.toLowerCase().indexOf(lastName.toLowerCase()) === -1);
                });
                this.showUnknown = this.$t('globalText.unknown').indexOf(firstName.toLowerCase()) !== -1;
                this.driverList = [];
                tmpData.forEach((item, index) => {
                    this.driverList[index] = {
                        value: item.Id,
                        label: item.FirstName + ' ' + item.LastName
                    };
                });
                return value;
            },
            handleVisibleChange(value) {
                if (!value) {
                    this.driverList = [];
                    this.apiDrivers.forEach((item, index) => {
                        this.driverList[index] = {
                            value: item.Id,
                            label: item.FirstName + ' ' + item.LastName
                        };
                    });
                    if (!this.driverIdMap.has(this.currentDriver))
                        this.currentDriver = this.driverIdMap.get(this.lastDriverId);
                }
            },
            handleCurrentDriverChange(value) {
                this.showUnknown = true;
                this.lastDriverId = value;
                this.$emit('currentDriver', value);
            },
            handleBlur(event) {
            },
            handleFocus(event) {
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .his-left-main-select-driver
        padding: 10px 10px 10px
        background-color $color_gray_first

        > .his-left-select
            width 100%

        >>> .el-input__inner
            background-color $color_white !important

    .unknown-driver
        color $color_collision !important

        >>> .el-input__inner
            color $color_collision !important

    .hlmsd-no-data
        text-align center
        color #999
</style>
