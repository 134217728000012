import { render, staticRenderFns } from "./AlertRtcMessage.vue?vue&type=template&id=68ea4967&scoped=true&"
import script from "./AlertRtcMessage.vue?vue&type=script&lang=js&"
export * from "./AlertRtcMessage.vue?vue&type=script&lang=js&"
import style0 from "./AlertRtcMessage.vue?vue&type=style&index=0&id=68ea4967&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68ea4967",
  null
  
)

export default component.exports