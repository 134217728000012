<template>
    <div class="top-fleet-selector">
        <el-select
                class="tfs-selector"
                :no-data-text="$t('globalText.noData') + '.'"
                :no-match-text="$t('globalText.noData') + '.'"
                @change="handleChange"
                :size="size"
                v-model="currentFleet"
                filterable
                default-first-option
                :placeholder="$t('tripHistory.fleetName')"
        >
            <el-option
                    v-for="(item, index) in currentFleetList"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
            </el-option>
        </el-select>
    </div>
</template>

<script>
    export default {
        name: "FleetSelector",
        props: {
            size: {type: String, default: 'medium'},
            fleetList: {type: Array, required: true},
            defaultFleet: {required: true}
        },
        beforeMount() {
            this.currentFleetList = this.fleetList;
            this.currentFleet = this.defaultFleet;
        },
        data() {
            return {
                currentFleet: '',
                lastFleet: '',
                currentFleetList: [],
            }
        },
        watch: {
            fleetList(list) {
                this.currentFleetList = list;
            }
        },
        methods: {
            handleChange(value) {
                this.$emit('currentFleet', value);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .top-fleet-selector
        .tfs-selector
            width 100%
</style>