<template>
    <div class="">
        <base-pop-dialog width="680px" @onClose="handleClose" v-if="!showConfirmBox">
            <template v-slot:title>{{$t('settings.upgradeFirmware')}}</template>
            <template v-slot:content v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <div class="global-upgrade-firmware">
                    <div class="guf-title">{{$t('settings.newFirmWareAvailableFor', {name: deviceName})}}</div>
                    <div class="guf-tips">{{$t('settings.firmwareVersion')}}: {{version}}</div>
                    <div class="guf-release-notes"><pre>{{$t('settings.releaseNotes')}}:<br/>{{releaseNotes}}</pre></div>
                    <div class="guf-btns-box">
                        <el-button class="guf-btn" type="primary" size="small" @click="showConfirmBox = true">
                            {{$t('settings.upgradeNow')}}
                        </el-button>
                        <el-button class="guf-btn" type="info" size="small" @click="handleClose">
                            {{$t('globalText.cancel')}}
                        </el-button>
                    </div>
                </div>
            </template>
        </base-pop-dialog>
        <base-pop-dialog width="560px" @onClose="handleConfirmClose" v-if="showConfirmBox">
            <template v-slot:title>{{$t('settings.upgradeFirmware')}}</template>
            <template v-slot:content v-loading="loading" element-loading-background="rgba(0, 0, 0, 0.2)">
                <div class="global-upgrade-firmware-confirm">
                    <div class="gufc-tips">{{$t('settings.pressOKToUpgradeYourMobile360D700Devices', {device:
                        deviceName})}}
                    </div>
                    <div class="gufc-version">{{$t('settings.firmwareVersion')}}: {{version}}</div>
                    <div class="guf-btns-box">
                        <el-button class="guf-btn" type="primary" size="small" @click="handleUpgradeFirmware">
                            {{$t('globalText.oK')}}
                        </el-button>
                        <el-button class="guf-btn" type="info" size="small" @click="handleConfirmClose">
                            {{$t('globalText.cancel')}}
                        </el-button>
                    </div>
                </div>
            </template>
        </base-pop-dialog>
    </div>
</template>

<script>
    import BasePopDialog from "../common/BasePopDialog";

    export default {
        name: "GlobalUpgradeFirmware",
        components: {
            BasePopDialog
        },
        data() {
            return {
                showConfirmBox: false,
            };
        },
        computed: {
            version() {
                return this.$store.getters['firmware/currentUpgradeFirmware'].version;
            },
            deviceName() {
                return 'Mobile360 ' + this.mainModel;
            },
            releaseNotes() {
                return this.$store.getters['firmware/currentUpgradeFirmware'].releaseNote;
            },
            mainModel() {
                return this.$store.getters['firmware/currentUpgradeFirmware'].mainModel;
            },
            id() {
                return this.$store.getters['firmware/currentUpgradeFirmware'].id;
            }
        },
        methods: {
            handleUpgradeFirmware() {
                this.upgradeFirmware(this.mainModel, this.version).then(data => {
                    this.$message({message: this.$t('globalText.savedSuccessfully'), type: 'success'});
                }).catch(error => {
                    if (error.code !== 6001)
                        this.$message({message: this.$t('globalText.failedToSave'), type: 'warning'});
                });
                this.closeWindow();
            },
            handleClose() {
                this.closeWindow();
            },
            handleConfirmClose() {
                this.showConfirmBox = false;
                this.closeWindow();
            },
            closeWindow() {
                this.readNotification(this.id).then(data => {
                }).catch(error => {
                });
                this.$store.dispatch('firmware/closeUpgradeFirmwareWindow');
            },
            readNotification(id) {
                let promise = (resolve, reject) => {
                    this.$serverApi.notification.read({id: id}, data => {
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                };
                return new Promise(promise);
            },
            upgradeFirmware(mainModel, version) {
                let promise = (resolve, reject) => {
                    this.$serverApi.company.updateFirmware({
                        mainModel: mainModel,
                        version: version
                    }, data => {
                        resolve(data);
                    }, error => {
                        reject(error);
                    });
                };
                return new Promise(promise);
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    $border-color = $color_gray_third

    .global-upgrade-firmware
        padding 25px 40px

        .guf-title
            font-size 16px
            padding-bottom 20px
            color $color_blue_second

        .guf-tips
            padding-bottom 15px

        .guf-release-notes
            border 1px solid $border-color
            padding 15px
            overflow-y auto
            margin-bottom 18px
            min-height 250px
            max-height 300px
            line-height 20px

        .guf-btns-box
            text-align center

            .guf-btn
                min-width 140px
                margin-left 15px
                margin-right 15px

    .global-upgrade-firmware-confirm
        text-align center
        padding 40px 20px

        .gufc-tips
            padding-bottom 15px

        .gufc-version
            padding-bottom 45px

        .guf-btns-box
            text-align center

            .guf-btn
                min-width 140px
                margin-left 15px
                margin-right 15px
</style>
