<!--！！！该页面废弃使用，原始可由Header组件中跳转过来。字段修改和多语言已不支持该页面，重新启用该页面需要修正接口字段和多语言校准-->
<template>
    <v-base-pop-dialog width="600px" @onClose="handleClose" id="setting-dialog">
        <template slot="title">
            <span class="via-settings setting-title-icon"></span>{{$t('settings.settings')}}
        </template>
        <div slot="content"
             v-loading="loading"
             element-loading-background="rgba(0, 0, 0, 0.2)"
             class="setting-content">
            <el-form :model="settingInfo" :rules="rules" label-width="230px" size="medium"
                     label-position="right" style="margin-bottom: 50px">
                <el-form-item label="Units" prop="Units">
                    <el-select class="text-value" size="small" type="password" v-model="settingInfo.unitSetting">
                        <el-option value="Metric">Metric</el-option>
                        <el-option value="Imperial">Imperial</el-option>
                        <el-option value="US Customary">US Customary</el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('settings.loginScanTime')" prop="scanTTL">
                    <el-input-number class="text-value" v-model="settingInfo.scanTTL" controls-position="right"
                                     size="small" :min="5" :max="300" @input.native="checkNumber">
                    </el-input-number>
                    <span class="text-unit">Secs</span>
                </el-form-item>
                <el-form-item :label="$t('settings.loginPictureDelay')" prop="takePictureTime">
                    <el-input-number class="text-value" v-model="settingInfo.takePictureTime" controls-position="right"
                                     size="small" :min="10" :max="300" @input.native="checkNumber">
                    </el-input-number>
                    <span class="text-unit">Secs</span>
                </el-form-item>
                <el-form-item :label="$t('settings.alertRecordingDuration')" prop="collisionRecordTime">
                    <el-input-number class="text-value" v-model="settingInfo.collisionRecordTime"
                                     controls-position="right"
                                     size="small" :step="10" :min="10" :max="30" @input.native="checkNumber">
                    </el-input-number>
                    <span class="text-unit">Secs</span>
                </el-form-item>
                <el-form-item :label="$t('settings.alertSensitivity')" prop="">
                    <el-slider class="text-value"
                               v-model="settingInfo.sensitivity"
                               :min="5"
                               :max="20"
                               :step="1"
                               :format-tooltip="formatTooltip"
                               :marks="sensitivityMarks"
                               :show-tooltip="false">
                    </el-slider>
                </el-form-item>
                <el-form-item label="Audio Recording" prop="">
                    <el-switch v-model="settingInfo.audioRecord"
                               class="text-value">
                    </el-switch>
                </el-form-item>
                <el-form-item :label="$t('settings.driverRegistrationMethod')" prop="">
                    <el-select class="text-value" size="small" type="password"
                               v-model="settingInfo.driverRegistrationMethod">
                        <el-option :value="0" label="QR Code"></el-option>
                        <el-option :value="1" label="QR Code + Face ID"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('settings.alertVolume')">
                    <el-slider class="text-value"
                               v-model="settingInfo.alertVolume"
                               :min="1"
                               :max="4"
                               :step="1"
                               :marks="alertVolumeMarks"
                               :show-tooltip="false">
                    </el-slider>
                </el-form-item>
                <el-form-item :label="$t('settings.way2CallVolume')">
                    <el-slider class="text-value"
                               v-model="settingInfo.twoWayCallVolume"
                               :min="1"
                               :max="4"
                               :step="1"
                               :marks="alertVolumeMarks"
                               :show-tooltip="false">
                    </el-slider>
                </el-form-item>
                <el-divider content-position="left">{{$t('settings.ADASAudioAlerts')}}</el-divider>
                <el-form-item :label="$t('settings.LDW')">
                    <el-switch v-model="settingInfo.LDW" class="text-value">
                    </el-switch>
                </el-form-item>
                <el-form-item :label="$t('settings.FCW')">
                    <el-switch v-model="settingInfo.FCW" class="text-value">
                    </el-switch>
                </el-form-item>
                <el-divider content-position="left">DMS Audio Alerts</el-divider>
                <el-form-item :label="$t('settings.driverFatigue')" class="setting-inline-form">
                    <el-switch style="padding-left: 36px" v-model="settingInfo.driverFatigue"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('settings.smoking')" label-width="160px" class="setting-inline-form">
                    <el-switch style="padding-left: 20px" v-model="settingInfo.smoking"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('settings.distractedDriving')" class="setting-inline-form">
                    <el-switch style="padding-left: 36px" v-model="settingInfo.distractedDriving"></el-switch>
                </el-form-item>
                <el-form-item :label="$t('settings.phoneUsage')" label-width="160px" class="setting-inline-form">
                    <el-switch style="padding-left: 20px" v-model="settingInfo.phoneUsage"></el-switch>
                </el-form-item>
            </el-form>
            <div class="account-button">
                <el-button class="ok-button" size="small" type="primary" @click="saveSetting">{{$t('globalText.oK')}}</el-button>
                <el-button class="cancel-button" size="small" @click="cancelSetting">{{$t('globalText.cancel')}}</el-button>
            </div>
        </div>

    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../../components/common/BasePopDialog'
    export default {
        name: "Setting",
        components: {
            vBasePopDialog
        },
        data() {
            return {
                loading: true,
                companyInfo: null,
                settingInfo: {
                    unitSetting: '',
                    sensitivity: 0,
                    scanTTL: 0,
                    takePictureTime: 0,
                    audioRecord: 0,
                    collisionRecordTime: 10,
                    driverRegistrationMethod: 0,
                    alertVolume: 2,
                    twoWayCallVolume: 2,
                    LDW: 1,
                    FCW: 1,
                    driverFatigue: 1,
                    smoking: 1,
                    distractedDriving: 1,
                    phoneUsage: 1

                },
                sensitivityMarks: {
                    5: this.$t('settings.low'),
                    13: this.$t('settings.medium'),
                    20: this.$t('settings.high'),
                },
                alertVolumeMarks: {
                    1: this.$t('settings.min'), //25%
                    2: '',
                    3: '',
                    4: this.$t('settings.max')//100%
                },
                rules: {

                    scanTTL: [
                        // {min:5,max: 300, message: 'The number should be between 5 to 300', trigger: 'blur'},
                        //  {type: 'number', message: 'Number only', trigger: 'blur'},
                    ],
                    takePictureTime: [
                        // {min:5,max: 300, message: 'The number should be between 5 to 300', trigger: 'blur'}
                    ]
                },
                loadingInstance: null
            }
        },
        computed: {
            CompanyInfo: function () {
                return this.$store.state.companyInfo;
            }
        },
        watch: {
            CompanyInfo(val) {
                if (val)
                    this.setData(val);
            }
        },
        methods: {
            formatTooltip(val) {
                return 25 - val;
            },
            checkNumber(e) {
                e.target.value = e.target.value.replace(/[^\d.]/g, '');
                if (e.target.value.trim() == "" ) {
                    e.target.value = e.target.min;
                }
            },
            handleClose() {
                this.$emit('onClose');
            },
            saveSetting() {
                var editInfo = {
                    Id: this.CompanyInfo.Id,
                    Unit: this.settingInfo.unitSetting,
                    Sensitivity: (25 - this.settingInfo.sensitivity),
                    ScanTTL: this.settingInfo.scanTTL,
                    TakePictureTime: this.settingInfo.takePictureTime,
                    MuteRecord: this.settingInfo.audioRecord ? 0 : 1,
                    CollisionRecordTime: this.settingInfo.collisionRecordTime,
                    DRIVER_FACIAL_RECOGNITION: this.settingInfo.driverRegistrationMethod,
                    AlertVolume: this.settingInfo.alertVolume,
                    CallVolume: this.settingInfo.twoWayCallVolume,
                    LDW_AUDIO_ALERT: this.settingInfo.LDW ? 1 : 0,
                    FCW_AUDIO_ALERT: this.settingInfo.FCW ? 1 : 0,
                    DMS_FATIGUE_AUDIO_ALERT: this.settingInfo.driverFatigue ? 1 : 0,
                    DMS_SMOKING_DRIVER_AUDIO_ALERT: this.settingInfo.smoking ? 1 : 0,
                    DMS_DISTRACTED_DRIVER_AUDIO_ALERT: this.settingInfo.distractedDriving ? 1 : 0,
                    DMS_TALKING_ON_PHONE_AUDIO_ALERT: this.settingInfo.phoneUsage ? 1 : 0
                };
                this.loading = true;
                this.$serverApi.company.updateCompany(editInfo, (data) => {
                    this.$message(
                        {
                            message: this.$t('globalText.savedSuccessfully'),
                            type: 'success'
                        }
                    );
                    this.$store.commit("setCompanyInfo", data);
                    this.loading = false;
                    this.$emit('onClose');
                })


            },
            cancelSetting() {
                this.$emit('onClose');
            },
            setData(info) {
                this.settingInfo.unitSetting = info.Unit;
                this.settingInfo.sensitivity = 25 - info.Sensitivity;
                this.settingInfo.scanTTL = info.ScanTTL;
                this.settingInfo.takePictureTime = info.TakePictureTime;
                this.settingInfo.audioRecord = info.MuteRecord == 0 ? true : false;  //0 代表AudioRecord打开
                this.settingInfo.collisionRecordTime = info.CollisionRecordTime;
                if (info.DRIVER_FACIAL_RECOGNITION != undefined)
                    this.settingInfo.driverRegistrationMethod = info.DRIVER_FACIAL_RECOGNITION;
                if (info.LDW_AUDIO_ALERT != undefined)
                    this.settingInfo.LDW = info.LDW_AUDIO_ALERT == 1 ? true : false;
                if (info.FCW_AUDIO_ALERT != undefined)
                    this.settingInfo.FCW = info.FCW_AUDIO_ALERT == 1 ? true : false;
                if (info.DMS_FATIGUE_AUDIO_ALERT != undefined)
                    this.settingInfo.driverFatigue = info.DMS_FATIGUE_AUDIO_ALERT == 1 ? true : false;
                if (info.DMS_TALKING_ON_PHONE_AUDIO_ALERT != undefined)
                    this.settingInfo.phoneUsage = info.DMS_TALKING_ON_PHONE_AUDIO_ALERT == 1 ? true : false;
                if (info.DMS_DISTRACTED_DRIVER_AUDIO_ALERT != undefined)
                    this.settingInfo.distractedDriving = info.DMS_DISTRACTED_DRIVER_AUDIO_ALERT == 1 ? true : false;
                if (info.DMS_SMOKING_DRIVER_AUDIO_ALERT != undefined)
                    this.settingInfo.smoking = info.DMS_SMOKING_DRIVER_AUDIO_ALERT == 1 ? true : false;
                if (info.AlertVolume != undefined)
                    this.settingInfo.alertVolume = info.AlertVolume;
                if (info.CallVolume != undefined)
                    this.settingInfo.twoWayCallVolume = info.CallVolume;

                console.log(JSON.stringify(this.settingInfo));
            }
        },
        created() {
            this.$serverApi.company.getCompany((data) => {
                this.$store.commit("setCompanyInfo", data);
                this.loading = false;
            })
        }
    }
</script>

<style scoped lang="stylus">
    @import "../../assets/stylus/color.styl";
    .setting-content {
        padding: 12px 0 0 0;
    }

    .setting-title-icon {
        font-size: 24px;
        margin-right: 10px;
        vertical-align: middle;
    }

    .setting-content .text-value {
        padding-left: 36px;
        width: 200px;
    }

    .setting-content .text-unit {
        padding-left: 10px;
    }

    .account-button {
        position sticky ;
        margin 0 auto;
        text-align: center;
        bottom:0px
        padding 15px 0px 15px 0px;
        height: 30px;
        width calc(100% - 25px);
        background-color white;
        z-index:1003
    }

    .account-button  .cancel-button {
        margin-left: 30px;
    }

    >>> .el-form-item {
        padding: 8px 10px;
        margin-bottom: 0px;
    }

    >>> .el-divider--horizontal {
        width: auto;
        margin: 25px 60px 20px 60px;
    }

    >>> .el-divider__text.is-left {
        left: 40px;
    }

    .setting-inline-form {
        width: 45%;
        display: inline-block
    }

    >>> .el-switch__core {
        background: $color_black_third
    }

</style>

<style lang="stylus">
    @import "../../assets/stylus/color.styl"
    .setting-content .el-slider__stop {
        top: -5px;
        height: 6px;
        width: 2px;
        border-radius: 5px;
        background-color: $color_gray_third;
    }

    .setting-content .el-slider__marks-text {
        font-size: 12px;
        margin-top 6px;
    }

    .setting-content .el-slider__marks-text:last-child {
        width: 40px;
        left: 98% !important
    }

    .setting-content .el-slider__marks-text:nth-child(1) {
        left: 5% !important
    }

    .setting-content .el-slider__button {
        width: 15px;
        height: 15px;
        border: 2px solid $color_blue_second;
        background-color: $color_blue_second;
    }

    .setting-content .el-slider__button:hover {
        transform: scale(1.1);
    }

</style>
