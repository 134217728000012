<template>
    <v-base-pop-dialog title="Alerts" @onClose="handleClose" width="1300px">
        <template slot="title">
            <span class="via-call" style="font-size: 16px"/> {{$t('way2Call.way2Call')}}
        </template>
        <template slot="content">
            <div style="margin-left: 24px;margin-top: 10px">
                <div style="cursor: pointer;display:table-cell;text-align:center;width: 120px;" @click="onClickTab">
                    <div :class="{'span-class':isShowNotification}" style="display:inline-block">
                        {{$t('way2Call.notifications')}}
                    </div>
                    <div v-if="isShowNotification" style="height: 4px;background-color: #6995D6"></div>
                </div>

                <div style="display:table-cell;text-align:center;cursor: pointer;width: 120px;" @click="onClickTab">
                    <div :class="{'span-class':!isShowNotification}" style="display:inline-block">
                        {{$t('way2Call.missedCalls')}}
                    </div>
                    <div v-if="!isShowNotification" style="height: 4px;background-color: #6995D6"></div>
                </div>

            </div>
            <div class="am-list-box">


                <el-table
                        v-if="isShowNotification"
                        :data="alertsList"
                        :empty-text="tips"
                        border
                        height="300"
                        class="outer-table"
                        ref="outerTable">


                    <!--Vehicle-->
                    <el-table-column
                            :resizable="colResize"
                            width="150"
                            prop="vehicle"
                            :label="$t('way2Call.vehicle')">
                        <template slot-scope="scope">
                            <span :class="[scope.row.Status === 'online' ? 'vehicle-hover' : '']"
                                  @click="onClickVehicle(scope.row)">{{ scope.row.VehicleId }}</span>
                        </template>
                    </el-table-column>

                    <!--Fleet-->
                    <el-table-column
                            :resizable="colResize"
                            width="160"
                            prop="fleet"
                            :label="$t('way2Call.fleet')">
                        <template slot-scope="scope">
                            <div class="am-text-one-line">
                                <span :title="scope.row.fleet">{{ scope.row.FleetName }}</span>
                            </div>
                        </template>
                    </el-table-column>


                    <!--Driver-->
                    <el-table-column
                            :resizable="colResize"
                            width="140"
                            prop="driver"
                            :label="$t('way2Call.driver')">
                        <template slot-scope="scope">
                            <span :class="{'vehicle-hover':driverName(scope.row) !== 'Unknown'}"
                                  @click="onClickDriver(scope.row)">
                                <strong>{{driverName(scope.row)}}</strong>
                            </span>
                        </template>
                    </el-table-column>

                    <!--Time-->
                    <el-table-column
                            :resizable="colResize"
                            width="188"
                            prop="time"
                            :label="$t('way2Call.time')">
                        <template slot-scope="scope">
                            <span>{{ $dateFormat.dateTime(scope.row.time, mixin_dateTimeFormat, mixin_timezone) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column type="expand">
                        <template slot-scope="scope">
                            <div>
                                <el-table
                                        :class="[isSafari ? 'son-table-safari':'son-table']"
                                        :data="childRtcList"
                                        :empty-text="sonTips"
                                        :show-header="showTableHeader"
                                        border>
                                    <!--Driver-->
                                    <el-table-column
                                            width="140"
                                            prop="driver"
                                            :label="$t('way2Call.driver')">
                                        <template slot-scope="scope">
                                            <span :class="{'vehicle-hover':driverName(scope.row) !== 'Unknown'}"
                                                  @click="onClickDriver(scope.row)">
                                                <strong>{{driverName(scope.row)}}</strong>
                                            </span>
                                        </template>
                                    </el-table-column>

                                    <!--Time-->
                                    <el-table-column
                                            width="236"
                                            prop="time"
                                            :label="$t('way2Call.time')">
                                        <template slot-scope="scope">
                                            <span>{{ $dateFormat.dateTime(scope.row.time, mixin_dateTimeFormat, mixin_timezone) }}</span>
                                        </template>
                                    </el-table-column>

                                    <!--Location-->
                                    <el-table-column
                                            prop="location"
                                            :label="$t('way2Call.location')">
                                        <template slot-scope="scope">
                                            <div class="am-word-show">
                                                {{ scope.row.location}}
                                            </div>

                                        </template>
                                    </el-table-column>


                                    <!--Log-->
                                    <el-table-column
                                            width="70"
                                            prop="log"
                                            :label="$t('way2Call.log')">
                                        <template slot-scope="scope">
                                            <div class="am-media-box">
                                                <span> </span>
                                            </div>
                                        </template>
                                    </el-table-column>


                                    <!--Log Event-->
                                    <el-table-column
                                            width="125"
                                            prop="event"
                                            :label="$t('way2Call.logEvent')">
                                        <template slot-scope="scope">
                                            <span v-if="scope.row.LogEvent == '0'">{{$t('way2Call.callRequest')}}</span>
                                            <span v-if="scope.row.LogEvent == '1'">{{$t('way2Call.newTrip')}}</span>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>

                        </template>
                    </el-table-column>

                    <!--Location-->
                    <el-table-column
                            :resizable="colResize"
                            prop="location"
                            :label="$t('way2Call.location')">
                        <template slot-scope="scope">
                            <div class="am-word-show">
                                {{ scope.row.location}}
                            </div>
                        </template>
                    </el-table-column>


                    <!--Log-->
                    <el-table-column
                            :resizable="colResize"
                            width="70"
                            prop="log"
                            :label="$t('way2Call.log')">
                        <template slot-scope="scope">
                            <div @click="handleRowClick(scope.row)"
                                 :class="[(scope.row.Logs.length > 0) ? 'pointClass': '']">
                                <span>{{scope.row.Logs.length + 1}}</span>
                                <span v-if="scope.row.Logs.length > 0" class="arrow-class"
                                      :class="[scope.row.arrowStatus? 'via-chevron-up':'via-chevron-down']"></span>
                            </div>
                        </template>
                    </el-table-column>


                    <!--Log Event-->
                    <el-table-column
                            :resizable="colResize"
                            width="125"
                            prop="Status"
                            :label="$t('way2Call.logEvent')">
                        <template slot-scope="scope">
                            <div>
                                <span v-if="scope.row.LogEvent == '0'">{{$t('way2Call.callRequest')}}</span>
                                <span v-else>{{$t('way2Call.newTrip')}}</span>
                            </div>
                        </template>
                    </el-table-column>


                    <!--Status-->
                    <el-table-column
                            :resizable="colResize"
                            width="80"
                            prop="status"
                            :label="$t('way2Call.status')">
                        <template slot-scope="scope" style="cursor: pointer">
                            <div>
                                <!--<span v-if="scope.row.status" class="el-icon-check am-icon-check"/>-->
                                <div style="display: inline-block;cursor: pointer">
                                    <span v-if="scope.row.Status == 'online'" class="via-microphone"
                                          style="margin-left: 16px;color:#00cc1b;font-size: 20px;margin-top: 2px"
                                          @click="onClickPhone(scope.row)"></span>
                                </div>

                                <span v-if="scope.row.Status == 'offroad'" class="via-off-road"
                                      style="margin-left: 12px;font-size: 18px"></span>
                                <span v-if="scope.row.ago > 600" class="via-wireless"
                                      style="margin-left: 13px;font-size: 19px"></span>
                                <div :class="[hangupClickStatus?'status-active':'alerthangup-disable']">
                                    <span v-if="scope.row.Status == 'hangup'" class="via-hangup"
                                          @click="onClickPhone(scope.row)"
                                          style="margin-left: 10px;color:#FF0000;font-size: 27px"></span>
                                </div>

                            </div>

                        </template>
                    </el-table-column>
                </el-table>
                <RtcMissCalled
                        v-else
                        :tableData="alertsHistoryList"
                        :alertHistoryTips="alertHistoryTips"
                        @onClickDriver="onClickDriver">
                </RtcMissCalled>
            </div>
            <div style="margin-bottom: 10px;margin-left: 24px;font-size: 13px"><b>*
                {{isShowNotification ? $t('way2Call.showMsgFor1hour') : $t('way2Call.showMsgFor24hours')}}</b></div>
        </template>
    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../common/BasePopDialog'
    import RtcMissCalled from './RtcMissCalled'

    export default {
        name: "AlertRtcMessage",
        components: {
            vBasePopDialog,
            RtcMissCalled
        },
        data() {
            return {
                rowExpansion: false,
                rowHistoryExpansion: false,
                clickedItem: null,
                showTableHeader: false,
                isShowNotification: true,
                childRtcList: [],
                alertsList: [],
                alertsHistoryList: [],
                currentRtcDeviceId: null,
                tips: this.$t('globalText.loading'),
                alertHistoryTips: this.$t('globalText.loading'),
                sonTips: this.$t('globalText.loading'),
                hasReCalledTask: false,
                timer: null,
                colResize: false
            }
        },
        computed: {
            hangupClickStatus() {
                //挂断按钮是否可用
                return this.$store.getters['websocket/getRequestCallInfo'].hangUpIconStatus;
            },
            isClearHangUpStatus() {
                return this.$store.getters['websocket/getRtcTaskInfo'].type;
            },
            changeAlertStatusToHangUp() {
                return this.$store.getters['websocket/getIsRunningRtcState'];
            },
            driverName() {
                return function (data) {
                    let name = data.DriverFirstName ? (data.DriverFirstName + ' ' + data.DriverLastName) : data.DriverName;
                    return name.trim();
                };
            },
            isSafari() {
                return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
            }
        },
        watch: {
            isClearHangUpStatus() {
                console.log(`状态恢复，this.isClearHangUpStatus = ${!this.isClearHangUpStatus}`);
                if (!this.isClearHangUpStatus) {
                    this.clearConnectedRtcRequest();
                }
            },
            changeAlertStatusToHangUp() {
                if (this.changeAlertStatusToHangUp && this.clickedItem) {
                    this.clickedItem.Status = 'hangup';
                }
            }
        },
        mounted() {
            let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
            this.currentRtcDeviceId = currentRtcInfo.rtcConnectedDeviceId;
            this.getRtcList();
        },

        beforeDestroy() {
            //清除定时器
            if (this.timer) {
                clearInterval(this.timer)
                this.timer = null;
            }
        },

        methods: {
            clearConnectedRtcRequest() {
                this.alertsList.forEach(item => {
                    if (item.Status === 'hangup') {
                        console.log(`图标置为可拨打状态`);
                        //删除已回拨通话记录
                        //this.alertsList.splice(index, 1);
                        //状态重置回去，决定是否删除记录操作服务器进行
                        item.Status = 'online';
                    }

                })
            },
            getRtcList() {

                //console.log(`获取通话数据`);
                //0:Notification, 1:Missed calls
                let alertListData = new Promise((resolve) => {
                    this.$serverApi.webRtcApi.getRtcCalledList(
                        {
                            Type: 0
                        }, (data) => {
                            if (data) {
                                resolve(data);
                            }
                        }, (errorData) => {
                            console.log("获取数据出错！error = " + errorData);
                            //reject(errorData);
                        }
                    )
                });

                let alertHistoryData = new Promise((resolve) => {
                    this.$serverApi.webRtcApi.getRtcCalledList(
                        {
                            Type: 1
                        }, (data) => {
                            if (data) {
                                resolve(data);
                            }
                        }, (errorData) => {
                            console.log("获取历史数据出错！error = " + errorData);
                            //reject(errorData);
                        }
                    )
                });
                Promise.all([alertListData, alertHistoryData]).then(data => {
                    //console.log(`返回的数据：${JSON.stringify(data)}`);
                    console.log(`data length = ${data.length}`);
                    if (data.length === 2) {
                        this.handleData(data[0], 0);
                        this.handleData(data[1], 1);
                    }
                })

            },
            handleData(dataArr, flag) {
                if (dataArr && dataArr.length === 0 && flag === 0) {
                    this.tips = this.$t('globalText.noData');
                }
                if (dataArr && dataArr.length === 0 && flag === 1) {
                    this.alertHistoryTips = this.$t('globalText.noData');
                }
                let initAddressList = [];
                dataArr.forEach((item, index) => {
                    //格式化时间
                    item['time'] = item.Timestamp;
                    //增加箭头控制字段
                    item['arrowStatus'] = false;
                    if (!item.DriverFirstName || !item.DriverLastName) {
                        item.DriverName = this.$t('globalText.unknown');
                    }
                    initAddressList[index] = {latitude: item.Latitude, longitude: item.Longitude, address: ''};
                    if (flag === 0 && this.currentRtcDeviceId) {
                        //当前若有从该组件发起的通话正在进行，则显示挂断图标
                        if (item.DeviceId == this.currentRtcDeviceId) {
                            item.Status = 'hangup';
                        }
                    }
                    if (item.Logs.length > 0) {
                        let initChildrenAddressList = [];
                        item.Logs.forEach((child, index) => {
                            //格式化时间
                            child['time'] = child.Timestamp;
                            if (!child.DriverFirstName || !child.DriverLastName) {
                                child.DriverName = this.$t('globalText.unknown');
                            }
                            initChildrenAddressList[index] = {
                                latitude: child.Latitude,
                                longitude: child.Longitude,
                                address: ''
                            };
                        });
                        this.$Gps.getAllLocation(initChildrenAddressList).then(result => {
                            result.forEach((location, index) => item.Logs[index].location = location);
                        }).catch(errorResult => {
                            errorResult.forEach((location, index) => item.Logs[index].location = location);
                        });
                    }
                });
                if (initAddressList.length > 0) {
                    this.$Gps.getAllLocation(initAddressList).then(result => {
                        result.forEach((location, index) => {
                            dataArr[index].location = location
                        });
                        if (flag === 0) {
                            this.alertsList = dataArr;

                            if (this.alertsList.length === 0) {
                                if (this.timer) {
                                    clearInterval(this.timer);
                                    this.timer = null;
                                }
                                return
                            }
                            if (this.timer) return;
                            this.timer = setInterval(() => {
                                this.checkVehicleStatus();
                            }, 5 * 1000);

                        } else {
                            this.alertsHistoryList = dataArr;
                        }
                    }).catch(errorResult => {
                        errorResult.forEach((location, index) => dataArr[index].location = location);
                        if (flag === 0) {
                            this.alertsList = dataArr;
                        } else {
                            this.alertsHistoryList = dataArr;
                        }
                    });
                }
            },
            checkVehicleStatus() {
                if (this.isShowNotification) {
                    this.$serverApi.webRtcApi.getRtcCalledList({Type: 0},
                        data => {
                            if (data) {
                                let tempArr = [];
                                data.forEach(item => {
                                    tempArr.push({VehicleId: item.VehicleId, Status: item.Status})
                                });
                                if (this.isShowNotification) {
                                    this.alertsList.forEach(alertItem => {
                                        for (let i = 0; i < tempArr.length; i++) {
                                            if (tempArr[i].VehicleId === alertItem.VehicleId && alertItem.Status !== 'hangup') {
                                                alertItem.Status = tempArr[i].Status;
                                                break;
                                            }
                                        }
                                    })
                                }
                            }
                        }, (errorData) => {
                            console.log("error = " + errorData);
                        }
                    )
                }
            },
            formatTime(time) {
                if (time === 0 || isNaN(time)) return this.$t('globalText.na');
                let date = new Date(time * 1000);
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate(),
                    hour = date.getHours(),
                    minute = date.getMinutes(),
                    second = date.getSeconds();
                if (time === 0 || year === 1970) return this.$t('globalText.na');
                month = month < 10 ? '0' + month : '' + month;
                day = day < 10 ? '0' + day : '' + day;
                hour = hour < 10 ? '0' + hour : '' + hour;
                minute = minute < 10 ? '0' + minute : '' + minute;
                second = second < 10 ? '0' + second : '' + second;
                return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
            },
            async onClickPhone(data) {
                if (!this.hangupClickStatus) {
                    //sdk正在初始化，禁止操作
                    return
                }
                //localStorage中储存rtc信息，供上传音频接口使用。不能使用state中的信息，因为state中的信息在通话结束时会被清除掉
                let storageInfo = {
                    DeviceId: data.DeviceId,
                    TripId: data.TripId,
                    FleetId: data.FleetId,
                    PlateNumber: data.PlateNumber
                };
                localStorage.removeItem("rtcInfo");
                localStorage.setItem("rtcInfo", JSON.stringify(storageInfo));

                this.hasReCalledTask = false;
                this.clickedItem = data;
                let name = data.DriverFirstName ? (data.DriverFirstName + " " + data.DriverLastName) : 'UnKnown';
                if (data.Status === 'hangup') {

                    //通知Realtime组件结束推流
                    //bus.$emit("startWebRtcFromAlert", data.DeviceId, name,data.VehicleId,data.Timestamp);
                    this.$store.commit('websocket/setRtcTask', null);
                } else {
                    let isRunningRtc = this.$store.getters['websocket/getIsRunningRtcState'];
                    if (isRunningRtc) {
                        //当前有正在进行的RTC通话
                        //console.log(`data.DriverFirstName = ${data.DriverFirstName}`);
                        let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                        this.$customConfirm(
                            this.$t('popUpMsg.rtcPop.terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall',
                                {currentDriverName: currentRtcInfo.rtcConnectedDriverName, newDriverName: name}),
                            {
                                dangerouslyUseHTMLString: true
                            }
                        ).then(() => {
                            this.$store.commit('websocket/setRtcTask', {
                                type: null,
                                deviceId: null,
                                driverName: null,
                                vehicleId: null,
                                currentTime: new Date().getTime(),
                                otherRtcTask: {
                                    type: '重拨任务',
                                    deviceId: data.DeviceId,
                                    driverName: this.getRecentlyDriverName(data, name),
                                    vehicleId: data.VehicleId,
                                    timestamp: data.Timestamp
                                }
                            });
                            //data.Status = 'hangup';
                            this.hasReCalledTask = true;

                        }).catch(() => {
                            console.log('取消');
                        });
                    } else {
                        this.notifyRtcState(data, name)
                    }
                }
            },
            notifyRtcState(data, name) {
                this.$store.commit('websocket/setRtcTask', {
                    type: 'AlertRtcMessage',
                    deviceId: data.DeviceId,
                    driverName: this.getRecentlyDriverName(data, name),
                    vehicleId: data.VehicleId,
                    currentTime: new Date().getTime()
                })
            },
            getRecentlyDriverName(data, name) {
                //使用该条记录下最新的driver通信记录作为通话name
                let driverName = "";
                let timeStamp = 0;
                if (data.Logs && data.Logs.length > 0) {
                    data.Logs.forEach(item => {
                        if (item.Timestamp > timeStamp) {
                            timeStamp = item.Timestamp;
                            driverName = item.DriverFirstName ? (item.DriverFirstName + " " + item.DriverLastName) : 'UnKnown';
                        }
                    })
                } else {
                    driverName = name;
                }
                return driverName;
            },
            handleClose() {
                this.$emit("onClose")
            },
            onClickTab() {
                this.isShowNotification = !this.isShowNotification;
                this.resetArrowState();
            },
            handleRowClick(row) {
                if (row.Logs.length === 0) return;
                let $table = this.$refs.outerTable;
                //let tempArr = this.isShowNotification ? this.alertsList : this.alertsHistoryList;

                if (this.isShowNotification ? this.alertsList : this.alertsHistoryList) {
                    (this.isShowNotification ? this.alertsList : this.alertsHistoryList).map((item) => {
                        if (item !== row) {
                            item.arrowStatus = false;
                            $table.toggleRowExpansion(item, false)
                        }
                    })
                }
                this.childRtcList = row.Logs;
                $table.toggleRowExpansion(row, !row.arrowStatus);
                row.arrowStatus = !row.arrowStatus;
            },
            onClickVehicle(data) {
                if (this.isShowNotification) {
                    if (data.Status !== 'online') {
                        /*this.$customAlert(`${data.VehicleId} is off-road.`).then(() => {
                        });*/
                        return;
                    }
                    this.$emit("onClose");
                    this.$router.push({name: "realtime"}).catch();
                    this.$store.commit("setCurrentOnRoadTreeNodeId", data.VehicleId);

                }
            },
            onClickDriver(data) {
                console.log(`点击的Driver信息：`, data);
                let driverName = this.driverName(data);
                if (driverName !== 'Unknown') {
                    this.$emit("onClose");
                    this.$router.push({name: 'viewDriver', query: {id: data.DriverId}});
                }
            },
            resetArrowState() {
                if (this.isShowNotification) {
                    this.alertsHistoryList.forEach(item => {
                        item.arrowStatus = false;
                    })
                } else {
                    this.alertsList.forEach(item => {
                        item.arrowStatus = false;
                    })
                }
            },
            onClose() {
                this.$emit("onClose");
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .outer-table
        width 100%

    .outer-table >>> th
        padding 6px 0
        color $color_gray_first

    .outer-table >>> .el-table__expand-icon {
        height: 0 !important;
    }

    .outer-table >>> th
        background-color $color_blue_second
        border-right none

    .outer-table >>> td {
        padding: 6px 0 !important
        border-top 1px solid $color_gray_first
    }

    .outer-table >>> td:nth-child(3), .outer-table >>> td:nth-child(4),
    .outer-table >>> td:nth-child(6), .outer-table >>> td:nth-child(7)
        border-right 0

    .outer-table >>> .el-table__expand-column
        border-right none

    .pointClass
        cursor pointer

    .son-table, .son-table-safari
        margin-top -1px

    .son-table >>> td, .son-table-safari >>> td {
        border-top none
        border-right none
    }

    .outer-table >>> .el-table__expanded-cell {
        padding 0 0 20px 0 !important
        border none
    }

    .outer-table >>> .outer-table th {
        color: white;
        background-color: $color_blue_second;
        border-bottom: none !important;
    }

    .am-list-box
        padding 10px 24px 24px 24px
        min-width 1250px
        height 300px
        box-sizing content-box

    .span-class
        color $color_blue_second

    .arrow-class
        float right
        margin-top 5px
        font-size 16px

    .son-table
        margin-left: 309px;
        width auto
        margin-right 80px

    .son-table-safari
        margin-left: 313px;
        width auto
        margin-right 80px

    .status-active
        display: inline-block
        cursor pointer

    .alerthangup-disable
        display: inline-block
        cursor not-allowed

    .vehicle-hover
        cursor pointer

    .vehicle-hover:hover
        color $color_blue_second

    .am-word-show
        overflow hidden
        word-break keep-all
        word-wrap break-word
</style>
