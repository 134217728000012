<template>
    <div>
        <el-table
                :data="tableData"
                :empty-text="alertHistoryTips"
                border
                height="300"
                class="outer-table"
                ref="outerTable">
            <el-table-column
                    :resizable="colResize"
                    width="150"
                    prop="vehicle"
                    :label="$t('way2Call.vehicle')">
                <template slot-scope="scope">
                    <span>{{ scope.row.VehicleId }}</span>
                </template>
            </el-table-column>

            <el-table-column
                    :resizable="colResize"
                    width="160"
                    prop="fleet"
                    :label="$t('way2Call.fleet')">
                <template slot-scope="scope">
                    <div class="am-text-one-line">
                        <span :title="scope.row.fleet">{{ scope.row.FleetName }}</span>
                    </div>
                </template>
            </el-table-column>


            <el-table-column
                    :resizable="colResize"
                    width="140"
                    prop="driver"
                    :label="$t('way2Call.driver')">
                <template slot-scope="scope">
                    <span :class="{'vehicle-hover':driverName(scope.row) !== 'Unknown'}" @click="onClickDriver(scope.row)">
                        <strong>{{driverName(scope.row)}}</strong>
                    </span>
                </template>
            </el-table-column>

            <el-table-column
                    :resizable="colResize"
                    width="188"
                    prop="time"
                    :label="$t('way2Call.time')">
                <template slot-scope="scope">
                    <span>{{ $dateFormat.dateTime(scope.row.time, mixin_dateTimeFormat, mixin_timezone) }}</span>
                </template>
            </el-table-column>
            <el-table-column type="expand" >
                <template slot-scope="scope">
                    <div>
                        <el-table
                                :class="[isSafari ? 'son-table-history-safari' : 'son-table-history']"
                                :data="childRtcList"
                                :empty-text="sonTips"
                                :show-header="showTableHeader"
                                border>
                            <el-table-column
                                    width="140"
                                    prop="driver"
                                    :label="$t('way2Call.driver')">
                                <template slot-scope="scope">
                                    <span :class="{'vehicle-hover':driverName(scope.row) !== 'Unknown'}" @click="onClickDriver(scope.row)">
                                        <strong>{{driverName(scope.row)}}</strong>
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                    width="236"
                                    prop="time"
                                    :label="$t('way2Call.time')">
                                <template slot-scope="scope">
                                    <span>{{ $dateFormat.dateTime(scope.row.time, mixin_dateTimeFormat, mixin_timezone) }}</span>
                                </template>
                            </el-table-column>

                            <el-table-column
                                    prop="location"
                                    :label="$t('way2Call.location')">
                                <template slot-scope="scope">
                                    <div class="am-word-show">
                                        {{ scope.row.location}}
                                    </div>

                                </template>
                            </el-table-column>


                            <el-table-column
                                    width="70"
                                    prop="log"
                                    :label="$t('way2Call.log')">
                                <template slot-scope="scope">
                                    <div class="am-media-box">
                                        <span> </span>
                                    </div>
                                </template>
                            </el-table-column>


                            <el-table-column
                                    width="125"
                                    prop="event"
                                    :label="$t('way2Call.logEvent')">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.LogEvent == '0'">{{$t('way2Call.callRequest')}}</span>
                                    <span v-if="scope.row.LogEvent == '1'">{{$t('way2Call.newTrip')}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                </template>
            </el-table-column>

            <el-table-column
                    :resizable="colResize"
                    prop="location"
                    :label="$t('way2Call.location')">
                <template slot-scope="scope">
                    <div class="am-word-show">
                        {{ scope.row.location}}
                    </div>
                </template>
            </el-table-column>


            <el-table-column
                    :resizable="colResize"
                    width="70"
                    prop="log"
                    :label="$t('way2Call.log')">
                <template slot-scope="scope">
                    <div @click="handleRowClick(scope.row)"
                         :class="[(scope.row.Logs.length > 0) ? 'pointClass': '']">
                        <span>{{scope.row.Logs.length + 1}}</span>
                        <span v-if="scope.row.Logs.length > 0" class="arrow-class"
                              :class="[scope.row.arrowStatus? 'via-chevron-up':'via-chevron-down']"></span>
                    </div>
                </template>
            </el-table-column>


            <el-table-column
                    :resizable="colResize"
                    width="125"
                    prop="Status"
                    :label="$t('way2Call.logEvent')">
                <template slot-scope="scope">
                    <div>
                        <span v-if="scope.row.LogEvent == '0'">{{$t('way2Call.callRequest')}}</span>
                        <span v-else>{{$t('way2Call.newTrip')}}</span>
                    </div>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
    export default {
        name: "RtcMissCalled",
        props:{
            tableData:{
                type: Array,
                required: true
            },
            alertHistoryTips:{
                type: String
            },

        },
        data(){
            return {
                childRtcList:null,
                sonTips: this.$t('globalText.loading'),
                showTableHeader: false,
                colResize:false
            }
        },
        computed:{
            driverName(){
                return function (data) {
                    let name = data.DriverFirstName ? (data.DriverFirstName + ' ' + data.DriverLastName) : data.DriverName;
                    return name.trim();
                };
            },
            isSafari(){
                return /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);
            }
        },
        methods:{
            onClickDriver(data){
                this.$emit("onClickDriver", data);
            },
            handleRowClick(row) {
                if (row.Logs.length === 0) return;
                let $table = this.$refs.outerTable;
                if (this.tableData) {
                    this.tableData.map((item) => {
                        if (item !== row) {
                            item.arrowStatus = false;
                            $table.toggleRowExpansion(item, false)
                        }
                    })
                }
                this.childRtcList = row.Logs;
                $table.toggleRowExpansion(row, !row.arrowStatus);
                row.arrowStatus = !row.arrowStatus;
            },
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .outer-table
        width 100%

    .outer-table >>> th
        padding 6px 0
        color $color_gray_first

    .outer-table >>> .el-table__expand-icon {
        height: 0 !important;
    }

    .outer-table >>> th
        background-color $color_blue_second
        border-right none

    .outer-table >>> td {
        padding: 6px 0 !important
        border-top 1px solid $color_gray_first
    }

    .outer-table >>> td:nth-child(3),.outer-table >>> td:nth-child(4),.outer-table >>> td:nth-child(6)
        border-right 0

    .outer-table >>> .el-table__expand-column
        border-right none

    .pointClass
        cursor pointer

    .son-table-safari
        margin-top -1px

    .son-table-safari >>> td {
        border-top none
        border-right none
    }

    .son-table-history >>> td,.son-table-history-safari >>> td {
        border-top none
        border-right none
    }

    .outer-table >>> .el-table__expanded-cell {
        padding 0 0 20px 0 !important
        border none
    }

    .outer-table >>> .outer-table th {
        color: white;
        background-color: $color_blue_second;
        border-bottom: none !important;
    }

    .span-class
        color $color_blue_second

    .arrow-class
        float right
        margin-top 5px
        font-size 16px

    .son-table-history
        margin-left: 309px;
        margin-top -1px
        width auto

    .son-table-history-safari
        margin-left: 309px;
        margin-top -1px
        width auto

    .status-active
        display: inline-block
        cursor pointer

    .alerthangup-disable
        display: inline-block
        cursor not-allowed

    .vehicle-hover
        cursor pointer

    .vehicle-hover:hover
        color $color_blue_second

    .am-word-show
        overflow hidden
        word-break keep-all
        word-wrap break-word
</style>
