<template>
    <div class="video-control" ref="rtcBox"  id="rtcBoxId" v-drag="{that:this}">
        <div style="display: inline-block;width: 35px;height: 45px; cursor: move">
            <img :src="startUrl" class="animalImg"/>
        </div>
        <div style="display: inline-block;cursor: move" class="name-space">
            <span class="viewerName" @mouseenter="overShow" @mouseleave="hideShow">
                <strong>{{isRtcConnected?(driverName.length > 14 ? driverName.slice(0,11)+"..." : driverName):connectTip}}</strong>
            </span>
        </div>
        <div v-show="isShowDiv" id="showDiv" style="position: absolute;background: white;border: #8c939d solid 1px;border-radius: 5px;padding: 10px"></div>
        <div style="display: inline-block;width: 50px;height: 45px" >
            <div class="hungup">
                <img src="../../assets/image/hangup.svg" alt="" style="margin-left: 1.5px;margin-top: 1.5px"
                     :class="[enableHungupIcon ? 'clicked-enable' : 'clicked-disable']" @click="clickHangUp">
            </div>
        </div>

        <div id="master" style="display: none">
            <!--<div class="video-container">
                <video ref="localStream" style="width: 100%" autoplay playsinline muted src="" disablePictureInPicture controlslist="nodownload"/>
            </div>-->
            <div>
                <video src="" ref="remoteStream"  autoplay playsinline></video>
            </div>
        </div>


    </div>
</template>

<script>
    import voice_0 from '../../assets/image/voice_1.svg';
    import voice_1 from '../../assets/image/voice_2.svg';
    import voice_2 from '../../assets/image/voice_3.svg';

    export default {
        name: "WebRTCConnected",
        props: {
            showComponent: {
                type: Boolean,
                default: false
            },
            driverName:{
                type: String,
                default: ''
            }
        },
        data() {
            return {
                startUrl: voice_0,
                count: 1,
                timer: null,
                connectTip: this.$t('liveTracking.connecting'),
                isShowDiv:false
            }
        },
        watch: {
            showComponent(value) {
                if (value) {
                    this.doImageAnimation();
                } else {
                    if (this.timer != null) {
                        console.log("清除定时器");
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }
            },
            '$route'() {
                let elBox = this.$refs.rtcBox;
                elBox.style.right = 400 + 'px';
                elBox.style.top = 15 + 'px';
            },
            rtcRemoteStream() {
                if (this.rtcRemoteStream) {
                    this.$refs['remoteStream'].srcObject = this.rtcRemoteStream;
                }
            },
        },
        computed:{
            enableHungupIcon(){
                let hangUpIconStatus = this.$store.getters['websocket/getRequestCallInfo'].hangUpIconStatus;
                console.log(`rtc弹窗组件中挂断可用状态${hangUpIconStatus}`);
                return hangUpIconStatus;
            },
            isRtcConnected(){
                return this.$store.getters['websocket/getRequestCallInfo'].deviceIsEnterChannel;
            },
            rtcRemoteStream() {
                return this.$store.getters['websocket/getRequestCallInfo'].rtcRemoteStream;
            }
        },
        methods: {

            overShow(){
                if (!this.driverName){
                    return
                }
                let showDiv = document.getElementById('showDiv');
                showDiv.style.left = event.clientX;
                showDiv.style.top = event.clientY;
                showDiv.innerHTML = this.driverName ? this.driverName : this.$t('globalText.unknown');
                this.isShowDiv=true;
            },
            hideShow(){
                this.isShowDiv=false;
            },
            clickHangUp() {
                if (this.enableHungupIcon) {
                    console.log(`挂断rtc通话`);
                    this.$store.commit('websocket/setRtcTask', null);
                }

            },
            doImageAnimation() {
                this.timer = setInterval(() => {
                    let temp = this.count % 3;
                    if (temp === 0) {
                        this.startUrl = voice_0;
                        this.connectTip = this.$t('liveTracking.connecting') + '.'
                    } else if (temp === 1) {
                        this.startUrl = voice_1;
                        this.connectTip = this.$t('liveTracking.connecting') + '..'
                    } else {
                        this.startUrl = voice_2;
                        this.connectTip = this.$t('liveTracking.connecting') + '...'
                    }
                    this.count++;
                }, 250);
            }
        },
        directives:{
            drag: function (el, binding) {
                el.onmousedown = (e) => {
                    let elBox = binding.value.that.$refs.rtcBox;
                    let oriX = e.pageX;
                    let oriY = e.pageY;
                    let bodyWidth = document.documentElement.clientWidth;
                    let bodyHeight = document.documentElement.clientHeight;
                    let selfWidth = el.clientWidth;
                    let selfHeight = el.clientHeight;
                    let curX = elBox.style.right.replace('px', '');
                    let curY = elBox.style.top.replace('px', '');
                    curX = curX === '' ? 400 : parseInt(curX);
                    curY = curY === '' ? 15 : parseInt(curY);
                    document.onmousemove = function (e) {
                        let xOffset = oriX - e.pageX;
                        let yOffset = e.pageY - oriY;
                        let newX = curX + xOffset;
                        let newY = curY + yOffset;
                        newX = newX < 0 ? 0 : newX;
                        newY = newY < 0 ? 0 : newY;
                        newX = newX < (bodyWidth - selfWidth) ? newX : (bodyWidth - selfWidth);
                        newY = newY < (bodyHeight - selfHeight) ? newY : (bodyHeight - selfHeight);
                        elBox.style.right = newX + 'px';
                        elBox.style.top = newY + 'px';
                    };
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null;
                    }
                }
            }
        },
        destroyed() {
            if (this.timer !== null) {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    }
</script>

<style scoped>
    .animalImg {
        height: 22px;
        width: 19px;
        position: absolute;
        top: 12px;
        left: 15px
    }

    .viewerName {
        font-size: 16px;
        color: #FFFFFF;
        line-height: 45px;
        position: absolute;
        left: 53px
    }

    .hungup {
        width: 28px;
        height: 28px;
        border-radius: 14px;
        background-color: #FFFFFF;
        float: right;
        margin-top: 8px;
        margin-right: 10px
    }
    .hungup:hover{
        background-color: #6995d6;
    }

    .name-space{
        height: 45px;
        width: calc(217px - 85px);
    }

    .clicked-enable{
        cursor: pointer;
    }

    .clicked-disable{
        cursor: not-allowed !important;
    }

</style>
