<template>
    <v-base-pop-dialog width="600px" @onClose="handleClose">
        <template slot="title">
            <span v-show="!isInEdit" class="via-user account-title-icon"></span>
            <span v-if="!isInEdit">{{$t('myAccount.myAccount')}}</span>
            <span v-else>{{$t('myAccount.editAccount')}}</span>
            <!--<div class="edit-button-area" v-if="!isInEdit"><span class="via-edit" @click="editAccount()"></span></div>-->
        </template>
        <div slot="content" class="account-content"
             v-loading="loading"
             element-loading-background="rgba(0, 0, 0, 0.2)">
            <div class="operator-line" v-if="!isInEdit">
                <div>{{$t('myAccount.userID')}}: {{newUserInfo.LoginId !=undefined ? newUserInfo.LoginId : $t('globalText.na')}}
                </div>
                <div class="change-button-area" @click="changePassword()">{{$t('myAccount.changePassword')}}</div>
                <div class="edit-account" @click="editAccount()">{{$t('myAccount.editAccount')}}</div>
            </div>
            <div v-if="!isInEdit">
                <div class="text-line">
                    <span class="text-line-prop">{{$t('myAccount.company')}}</span>
                    <span class="text-line-value">{{newUserInfo.Company !=undefined ?newUserInfo.Company : $t('globalText.na')}}</span>
                </div>
                <div class="text-line">
                    <span class="text-line-prop">{{$t('myAccount.name')}}</span>
                    <span class="text-line-value">{{newUserInfo.Name !=undefined ? newUserInfo.Name : $t('globalText.na')}}</span>
                </div>
                <div class="text-line">
                    <span class="text-line-prop">{{$t('myAccount.email')}}</span>
                    <span class="text-line-value">{{newUserInfo.Email !=undefined ? newUserInfo.Email : $t('globalText.na')}}</span>
                </div>
                <div class="text-line">
                    <span class="text-line-prop">{{$t('myAccount.contactNumber')}}</span>
                    <span class="text-line-value">{{newUserInfo.ContactNumber !=undefined && newUserInfo.ContactNumber
                    != 'n/a' ? newUserInfo.ContactNumber : $t('globalText.na')}}
                    </span>
                </div>
                <div class="text-line" style="height: 100px">
                    <span class="text-line-prop">{{$t('myAccount.address')}}</span>
                    <span class="text-line-value">{{newUserInfo.Address !=undefined ?newUserInfo.Address : $t('globalText.na')}}
                    </span>
                </div>
            </div>
            <el-form v-else :model="newUserInfo"
                     ref="accountForm"
                     :rules="isInEdit ? rules:null"
                     label-width="185px"
                     size="medium"
                     label-position="right">

                <el-form-item :label="$t('myAccount.company')">
                    <el-input class="text-value text-value-input" v-model="newUserInfo.Company" size="small"
                              disabled></el-input>
                </el-form-item>

                <el-form-item :label="$t('myAccount.name')">
                    <el-input class="text-value text-value-input" v-model="newUserInfo.Name" size="small"
                              disabled></el-input>
                </el-form-item>


                <!--<el-form-item :label="$t('myAccount.email')" v-if="!isInEdit" >-->
                <!---->
                <!--&lt;!&ndash;<el-input v-else class="text-value text-value-input" v-model="newUserInfo.Email"&ndash;&gt;-->
                <!--&lt;!&ndash;size="small"></el-input>&ndash;&gt;-->
                <!--</el-form-item>-->
                <el-form-item :label="$t('myAccount.contactNumber')" prop="ContactNumber">
                    <el-input class="text-value text-value-input"
                              v-model="newUserInfo.ContactNumber"

                              size="small"></el-input>
                </el-form-item>

                <el-form-item :label="$t('myAccount.address')" prop="Address">
                    <el-input
                            class="text-value text-value-input"
                            v-model="newUserInfo.Address"
                            type="textarea"
                            resize="none"
                            @keydown.enter.native="textareaKeydown"
                            :maxlength="100"
                            show-word-limit
                            :rows="5"></el-input>
                </el-form-item>

                <div class="account-button" v-if="isInEdit">
                    <el-button class="ok-button" size="small" @click="saveEdit"> {{$t('globalText.oK')}}</el-button>
                    <el-button class="cancel-button" size="small" @click="cancelEdit"> {{$t('globalText.cancel')}}
                    </el-button>
                </div>
            </el-form>


        </div>

    </v-base-pop-dialog>
</template>

<script>
    import vBasePopDialog from '../../components/common/BasePopDialog'
    import vUpload from "../../components/common/Upload"
    import regExp from "../../assets/js/RegExp"

    export default {
        name: "Account",
        components: {
            vBasePopDialog,
            vUpload
        },
        watch: {
            companyInfo(val) {
                if (val) {
                    this.oldAddress = val.Address;

                }
            }
        },
        computed: {
            companyInfo: function () {
                return this.$store.getters.companyInfo;
            },


        },
        methods: {

            textareaKeydown() {
                let e = window.event || arguments[0];
                // console.log(e,e.keyCode)
                if (e.key == 'Enter' || e.code == 'Enter' || e.keyCode == 13) {
                    e.returnValue = false;
                    return false;
                }
            },
            handleClose() {
                if (this.isInEdit) {
                    this.cancelEdit();
                }
                else {
                    this.$emit('onClose');
                }


            },
            editAccount() {
                this.isInEdit = true;
            },
            setUserInfo() {
                this.newUserInfo = {
                    Name: this.userInfo.Name,
                    ContactNumber: this.userInfo.Phone,
                    LoginId: this.userInfo.LoginId,
                    Email: this.userInfo.Email,
                    Company: this.companyInfo.Name,
                    Address: this.companyInfo.Address,
                };
            },
            saveEdit() {
                this.$refs['accountForm'].validate((valid) => {
                    if (valid) {
                        var editInfo = {
                            Id: this.userInfo.Id,
                            Name: this.newUserInfo.Name,
                            Phone: this.newUserInfo.ContactNumber ? this.newUserInfo.ContactNumber : null
                        }
                        this.loading = true;
                        this.$serverApi.user.editUser(this.userInfo.Id, editInfo, (data) => {
                            this.$store.commit("setCurrentUser", data);
                            if (this.oldAddress != this.newUserInfo.Address) {
                                this.companyInfo.Address = this.newUserInfo.Address;
                                this.$serverApi.company.updateCompany(this.companyInfo, (data) => {
                                    this.$store.commit("setCompanyInfo", data);
                                    this.isInEdit = false;
                                    this.loading = false;
                                    this.$message(
                                        {
                                            message: this.$t('globalText.savedSuccessfully'),
                                            type: 'success'
                                        }
                                    );
                                });
                            } else {
                                this.isInEdit = false;
                                this.loading = false;
                                this.$message(
                                    {
                                        message: this.$t('globalText.savedSuccessfully'),
                                        type: 'success'
                                    }
                                );
                            }

                        })
                    }
                })

            },
            cancelEdit() {
                this.$refs['accountForm'].clearValidate();
                this.setUserInfo();
                this.isInEdit = false;
            },
            // getImagePath(path) {
            //     if (path)
            //         return this.$baseURL + path + "?" + new Date().getTime();
            //     return null
            // },
            // selectPhoto(imgFile) {
            //     this.newUserInfo.PicBase64 = imgFile;
            // },
            changePassword() {
                this.$parent.showChangePWD();
            }
        },
        data() {
            return {
                userInfo: null,
                newUserInfo: {
                    Name: "",
                    ContactNumber: '',
                    LoginId: '',
                    Email: "",
                    Company: '',
                    Address: ''
                },
                oldAddress: "",
                isInEdit: false,
                rules: {
                    ContactNumber: [
                        {required: true, message: this.$t('tips.account.pleaseInputAContactNumber'), trigger: 'blur'},
                        {
                            min: 6,
                            max: 32,
                            message: this.$t('globalText.mustContainBetween_X_To_Y_Characters', {min: 6, max: 32}),
                            trigger: ['change', 'blur']
                        },
                        {validator: regExp.checkPhoneNumber, trigger: ['change', 'blur']}
                    ],
                    Address: [
                        {required: true, message: this.$t('tips.account.pleaseInputAddress'), trigger: 'blur'},
                    ]

                },
                loading: true
            }
        },
        created() {
            if (!this.$store.getters.currentUserData)
                this.$serverApi.user.getUserInfo((info) => {
                    this.userInfo = info;
                    this.loading = false;
                    this.setUserInfo();
                });
            else {
                this.userInfo = this.$store.getters.currentUserData;
                this.loading = false;
                this.setUserInfo();
            }


        }

    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .account-content {
        font-size 14px !important;
        box-sizing: border-box;
        height 375px

    }

    .account-title-icon {
        font-size: 24px;
        margin-right: 10px;
        vertical-align: middle;
    }

    .edit-button-area {
        position: absolute;
        top: 15px;
        right: 80px;
        padding: 0;
        background: 0 0;
        border: none;
        outline: 0;
        cursor: pointer;
        font-size: 20px;
    }

    .edit-button-area:hover {
        color: $color_gray_third
    }

    .text-line{
        padding: 0 30px;
        height 40px;
        line-height 40px

    }
    .text-line-prop {
        width 150px
        text-align right
        display inline-block
    }
        .text-line-value{
            display inline-block
            padding-left 30px
            width 300px
            vertical-align top
        }

    .text-value {
        padding-left: 30px;
        word-wrap: break-word;
        word-break break-word
    }

    .text-value-input {
        max-width 300px
    }

    >>> .el-form-item {
        margin: 0;
        padding 8px 30px
    }

    >>> .el-form-item__content, >>> .el-form-item__label {
        line-height 24px !important;
        font-size 14px !important
    }

    .account-button {
        text-align: center;
        margin: 20px;
    }

    .account-button .ok-button {
        margin-right 20px
    }

    >>> .el-textarea__inner {
        font-size 12px
    }

    >>> .el-form-item__error {
        left: 30px;
        padding-top 2px
    }

    >>> .el-textarea__inner {
        padding 5px;
        font-size 13px;
        font-family: 'Avenir', Helvetica, Arial, sans-serif, 'Microsoft YaHei';
    }

    .operator-line
        padding 20px 20px 30px 50px
        div
            display inline-block
        .edit-account, .change-button-area
            width 150px
            float right
            text-align right
            color $color_blue_second
            cursor pointer
        .edit-account:hover, .change-button-area:hover
            text-underline $color_blue_second
            text-decoration: underline
</style>
