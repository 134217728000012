import AWS from 'aws-sdk';
import * as KVSWebRTC from 'amazon-kinesis-video-streams-webrtc'
import store from '../store'
import VUE from 'vue'
import Recorder from 'recorder-core/recorder.mp3.min'
import serverApi from "../assets/js/ServerAPI"
import VueI18n from 'vue-i18n'

VUE.use(VueI18n);

const i18nAxios = new VueI18n({
    locale: localStorage.getItem('language') || "en-US",
    // 修改messages需要同步修改Main.js对应的messages.
    messages: {
        'en-US': require("../assets/lang/en.js"),
        'zh-CN': require("../assets/lang/zh-CN.js"),
        'zh-TW': require("../assets/lang/zh-TW.js")
    }
});

const master = {
    signalingClient: null,
    peerConnectionByClientId: {},
    dataChannelByClientId: {},
    localStream: null,
    remoteStream: null,
    peerConnectionStatsInterval: null,
    isStarted: false,
    channelName: null,
    rtcArn: null,
    iceStateCheckTimer: null,
    checkIceStatePeerSecondTimer: null
};
let options = {
    region: null,
    accessKeyId: null,
    secretAccessKey: null,
    sessionToken: null,
    endpoint: null,
};
//录音相关
const recorder = {
    local: null,
    remote: null,
    mix: null,
    clearRecordTimeOut: null
};
let audioRecordData = [];
let isRecording = null;
let SendInterval = 1000;//发送间隔
let callID = null;


function onStatsReport(report) {
    console.log("onStatsReport:report->" + JSON.stringify(report));
}

function getEndpointList(getSignalingChannelEndpointResponse) {
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
        endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
        return endpoints;
    }, {});
    console.log('[MASTER] Endpoints: ', endpointsByProtocol);
    return endpointsByProtocol;
}

async function startMaster(channelName, callId, awsRTCConfig, isRecord, localCallback, remoteCallback, errorCallback) {
    initAudioRecordData(callId);
    callID = callId;
    options = awsRTCConfig;
    isRecording = isRecord;
    clearRecorder();
    //初始化
    initRecorder();

    if (!channelName) {
        channelName = "843Vh6aUzXebWVapgrRmH4KrCMg5CFrLjTthApZFDsntFf49pYeERWQPBNWAGUEH";
    }
    master.channelName = channelName;
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo(options);
    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
            ChannelName: channelName,
        })
        .promise();

    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log('[MASTER] Channel ARN: ', channelARN);
    master.rtcArn = channelARN;
    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
        .getSignalingChannelEndpoint({
            ChannelARN: channelARN,
            SingleMasterChannelEndpointConfiguration: {
                Protocols: ['WSS', 'HTTPS'],
                Role: KVSWebRTC.Role.MASTER,
            },
        })
        .promise();
    const endpointsByProtocol = getEndpointList(getSignalingChannelEndpointResponse);


    // Create Signaling Client
    master.signalingClient = new KVSWebRTC.SignalingClient({
        channelARN,
        channelEndpoint: endpointsByProtocol.WSS,
        role: KVSWebRTC.Role.MASTER,
        region: options.region, // AWSConfig.region,
        credentials: {
            accessKeyId: options.accessKeyId, // AWSConfig.accessKeyId,
            secretAccessKey: options.secretAccessKey, // AWSConfig.secretAccessKey,
            sessionToken: options.sessionToken // AWSConfig.sessionToken,
        },
    });

    // Get ICE server configuration
    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
        region: options.region, // AWSConfig.region,
        accessKeyId: options.accessKeyId, // AWSConfig.accessKeyId,
        secretAccessKey: options.secretAccessKey, // AWSConfig.secretAccessKey,
        sessionToken: options.sessionToken, // AWSConfig.sessionToken,
        endpoint: endpointsByProtocol.HTTPS,
    });
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
        .getIceServerConfig({
            ChannelARN: channelARN,
        })
        .promise();

    const iceServers = [];

    iceServers.push({urls: `stun:stun.kinesisvideo.${options.region}.amazonaws.com:443`});// AWSConfig.region,

    getIceServerConfigResponse.IceServerList.forEach(iceServer =>
        iceServers.push({
            urls: iceServer.Uris,
            username: iceServer.Username,
            credential: iceServer.Password,
        }),
    );
    console.log('[MASTER] ICE servers: ', iceServers);


    const configuration = {
        iceServers,
        iceTransportPolicy: 'all',
    };
    // const resolution = { width: { ideal: 1280 }, height: { ideal: 720 } } ;
    const constraints = {
        video: false,
        audio: true,
    };

    master.signalingClient.on('open', async () => {
        console.log('[MASTER] Connected to signaling service,time:' + new Date().toString());
        //通知WebRTCConnected组件和ComInformation组件挂断按钮恢复可用状态
        //bus.$emit('resetHungupState');
        store.commit('websocket/setHangUpStatus', true);

        // Get a stream from the webcam and display it in the local view
        try {
            master.localStream = await navigator.mediaDevices.getUserMedia(constraints);
            master.isStarted = true;
            if (localCallback) {
                if (!master.localStream) {
                    console.log(`master.localStream = null`);
                }
                localCallback(master.localStream);
                if (master.remoteStream && isRecording) {
                    decodeStream();
                }
            }
        } catch (e) {
            console.error('[MASTER] Could not find webcam');
            master.isStarted = false;
            if (errorCallback) {
                errorCallback({code: 0, errorMsg: 'Could not find webcam'});
            }
            //deleteChannel();
        }
    });


    master.signalingClient.on('sdpOffer', async (offer, remoteClientId) => {
        console.log('[MASTER] Received SDP offer from client: ' + remoteClientId /*+ ',offer = ' + JSON.stringify(offer)*/);

        // Create a new peer connection using the offer from the given client
        const peerConnection = new RTCPeerConnection(configuration);
        master.peerConnectionByClientId[remoteClientId] = peerConnection;


        // Poll for connection stats
        if (!master.peerConnectionStatsInterval) {
            master.peerConnectionStatsInterval = setInterval(() => peerConnection.getStats().then(onStatsReport), 1000);
        }

        // Send any ICE candidates to the other peer
        peerConnection.addEventListener('icecandidate', ({candidate}) => {
            if (candidate) {
                console.log('[MASTER] Generated ICE candidate for client: ' + remoteClientId);

                // When trickle ICE is enabled, send the ICE candidates as they are generated.
                console.log('[MASTER] Sending ICE candidate to client: ' + remoteClientId);
                master.signalingClient.sendIceCandidate(candidate, remoteClientId);
            } else {
                console.log('[MASTER] All ICE candidates have been generated for client: ' + remoteClientId);
            }
        });

        // As remote tracks are received, add them to the remote view
        peerConnection.addEventListener('track', event => {
            console.log('[MASTER] Received remote track from client: ' + remoteClientId);
            if (remoteCallback) {
                master.remoteStream = event.streams[0];
                remoteCallback(master.remoteStream);

                //远程音频就绪
                if (master.localStream && isRecording) {
                    decodeStream();
                }

                let iceState = null;
                if (master.checkIceStatePeerSecondTimer) {
                    iceState = null;
                    clearInterval(master.checkIceStatePeerSecondTimer);
                    master.checkIceStatePeerSecondTimer = null
                }

                //每隔1秒获取一次ice状态。因为兼容性问题无法使用peerConnection.onconnectionstatechange回调ice状态变化
                master.checkIceStatePeerSecondTimer = setInterval(() => {
                    iceState = peerConnection.iceConnectionState;
                    //console.log(`ice state changed！peerConnection.iceConnectionState = ` + iceState);
                    if (iceState === 'disconnected') {
                        //发现Device处于Disconnected状态，延时15s以后再次检查
                        if (master.iceStateCheckTimer) {
                            return;
                        }
                        master.iceStateCheckTimer = setTimeout(() => {
                            master.iceStateCheckTimer = null;
                            if (iceState === 'disconnected' || iceState === 'failed') {
                                //确定Device断开连接，master开始释放相关资源
                                //调用stopMaster方法结束通话
                                let currentRtcInfo = store.getters['websocket/getRequestCallInfo'];
                                let driverName = currentRtcInfo.rtcConnectedDriverName ?
                                    currentRtcInfo.rtcConnectedDriverName : i18nAxios.tc('globalText.unknown');
                                VUE.prototype.$customAlert(i18nAxios.tc('popUpMsg.rtcPop.connectionTo_DriverName_IsLost', {driverName: driverName}),
                                    {
                                        dangerouslyUseHTMLString: true
                                    }
                                );
                                stopMaster();
                                if (errorCallback) {
                                    errorCallback({code: 1, errorMsg: '', channelName: master.channelName})
                                }
                            }
                        }, 15 * 1000);
                    } else if (iceState === 'connected') {
                        //Device连接成功，第一连接成功需要告知服务器
                        if (!store.getters['websocket/getIsDeviceEnterChannel']) {
                            console.log(`Device connect succeed！current time:${new Date().toString()}`);
                            //告知服务器连接成功
                            store.commit('websocket/setDeviceEnterChannelStatus', true);
                        }
                    }
                }, 1000);


            }
        });

        master.localStream.getTracks().forEach(track => {
            if (!master.localStream) {
                console.log(`master.localStream = null`);
            }
            peerConnection.addTrack(track, master.localStream)
        });
        await peerConnection.setRemoteDescription(offer);

        // Create an SDP answer to send back to the client
        console.log('[MASTER] Creating SDP answer for client: ' + remoteClientId);
        await peerConnection.setLocalDescription(
            await peerConnection.createAnswer({
                offerToReceiveAudio: true,
                offerToReceiveVideo: true,
            }),
        );

        // When trickle ICE is enabled, send the answer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
        console.log('[MASTER] Sending SDP answer to client: ' + remoteClientId);
        master.signalingClient.sendSdpAnswer(peerConnection.localDescription, remoteClientId);
        console.log('[MASTER] Generating ICE candidates for client: ' + remoteClientId);
    });

    master.signalingClient.on('iceCandidate', async (candidate, remoteClientId) => {
        console.log('[MASTER] Received ICE candidate from client: ' + remoteClientId);

        // Add the ICE candidate received from the client to the peer connection
        const peerConnection = master.peerConnectionByClientId[remoteClientId];
        peerConnection.addIceCandidate(candidate);
    });

    master.signalingClient.on('close', (e) => {
        console.log('[MASTER] Disconnected from signaling channel，e = ' + e);
    });

    master.signalingClient.on('error', () => {
        master.isStarted = false;
        if (errorCallback) {
            errorCallback({code: 2, errorMsg: '[MASTER] Signaling client error!', channelName: master.channelName})
        }
        //deleteChannel()
        console.error('[MASTER] Signaling client error');
    });

    console.log('[MASTER] Starting master connection');
    master.signalingClient.open();
}

function initAudioRecordData(callId) {
    audioRecordData[callId] = {
        callId: callId,
        rtcInfo: JSON.parse(localStorage.getItem("rtcInfo")),//当前通话对象的相关信息
        step: 0,//将上传接口返回的index赋值给step
        audioBuffer: [],//混音回调数据包放在audioBuffer中，构成一个二维数组
        //当第一个数据包进入audioBuffer时启动循环，每一秒调用一次该函数
        uploadFunction: function () {
            if (this.isUploading) return;//正在上传时不进行循环检查
            if (this.isClose) {
                if (this.step === this.audioBuffer.length - 1) {
                    this.isUploading = true;
                    //已经上传到最后一组数据
                    let uploadRecordCallback = (data => {
                        if (data.Result) {
                            clearInterval(this.intervalTimer);
                            this.intervalTimer = null;
                            delete audioRecordData[this.callId];
                        } else if (data.code) {
                            this.step = data.message.DataSeq;
                        }
                        this.isUploading = false;
                    });
                    uploadRecord({
                        rtcInfo: this.rtcInfo,
                        callId: this.callId,
                        index: this.step
                    }, combinedRecordData(this.audioBuffer[this.step]), uploadRecordCallback);
                } else {
                    //按照step对应的位置上传数据
                    this.isUploading = true;
                    let uploadRecordCallback = (data => {
                        if (data.Result) {
                            this.step = data.DataSeq + 1;
                        } else if (data.code) {
                            this.step = data.message.DataSeq;
                        }
                        this.isUploading = false;
                    });
                    uploadRecord({
                        rtcInfo: this.rtcInfo,
                        callId: this.callId,
                        index: this.step
                    }, combinedRecordData(this.audioBuffer[this.step]), uploadRecordCallback);
                }
            } else {
                if (this.isUploading) return;
                if (this.audioBuffer[this.step]) {
                    if (this.audioBuffer[this.step].length === 10) {
                        //已经积累了10秒音频文件，开始上传
                        this.isUploading = true;
                        let uploadRecordCallback = (data => {
                            if (data.Result) {
                                this.step = data.DataSeq + 1;
                            } else if (data.code) {
                                this.step = data.message.DataSeq;
                            }
                            this.isUploading = false;
                        });
                        uploadRecord({
                            rtcInfo: this.rtcInfo,
                            callId: this.callId,
                            index: this.step
                        }, combinedRecordData(this.audioBuffer[this.step]), uploadRecordCallback);
                    }
                }
            }
        },
        isUploading: false,
        intervalTimer: null,
        isClose: false,//当用户挂断通话时，将值置为true
    }
}

function combinedRecordData(subData) {
    if (!(subData instanceof Array) || subData.length === 0) return "";
    return subData.reduce((x, y) => {
        return x + y
    });
}

async function createSignalingChannel(params, channelName, callBack) {
    // Create KVS client
    //const kinesisVideoClient = new AWS.KinesisVideo(options);
    const kinesisVideoClient = new AWS.KinesisVideo(params);
    await kinesisVideoClient
        .createSignalingChannel({
            ChannelName: channelName,
        })
        .promise();

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
        .describeSignalingChannel({
            ChannelName: channelName,
        })
        .promise();
    const ChannelName = describeSignalingChannelResponse.ChannelInfo.ChannelName;
    console.log('[CREATE_SIGNALING_CHANNEL] Channel Name: ', ChannelName);
    if (callBack) {
        console.log(`回调通知channel创建完成`);
        callBack(ChannelName)
    }
}

function stopMaster(callback) {
    if (master.checkIceStatePeerSecondTimer) {
        clearInterval(master.checkIceStatePeerSecondTimer);
        master.checkIceStatePeerSecondTimer = null;
    }
    if (master.iceStateCheckTimer) {
        clearTimeout(master.iceStateCheckTimer);
        master.iceStateCheckTimer = null;
    }
    console.log('[MASTER] Stopping master connection');
    master.isStarted = false;
    if (master.signalingClient) {
        master.signalingClient.close();
        master.signalingClient = null;
    }
    //通知WebRTCConnected组件和ComInformation组件挂断按钮恢复可用状态
    //bus.$emit('resetHungupState');
    store.commit('websocket/setHangUpStatus', true);

    Object.keys(master.peerConnectionByClientId).forEach(clientId => {
        master.peerConnectionByClientId[clientId].close();
    });
    master.peerConnectionByClientId = [];

    console.error(audioRecordData[callID]);
    streamMix(true); //发送最后一帧数据
    clearRecorder();
    if (master.localStream) {
        master.localStream.getTracks().forEach(track => track.stop());
        master.localStream = null;
    }
    if (master.remoteStream) {
        master.remoteStream.getTracks().forEach(track => track.stop());
        master.remoteStream = null;
    }

    if (master.peerConnectionStatsInterval) {
        clearInterval(master.peerConnectionStatsInterval);
        master.peerConnectionStatsInterval = null;
    }

    if (master.dataChannelByClientId) {
        master.dataChannelByClientId = {};
    }
    if (callback) {
        callback({code: 1, errorMsg: '', channelName: master.channelName})
    }
    deleteChannel()
}

//删除通话临时通道
function deleteChannel() {
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo(options);
    kinesisVideoClient.deleteSignalingChannel({ChannelARN: master.rtcArn}, (err, data) => {
        if (err) {
            console.log(`delete channel failed,err = ${err}`)
        } else {
            console.log(`delete channel!data = ${JSON.stringify(data)}`);

        }
    })
}

function getCurrentMasterState() {
    return master.isStarted;
}


function initRecorder() {
    console.log(`初始化mix对象`);
    recorder.mix = Recorder({
        type: "mp3",
        sampleRate: 32000,
        bitRate: 320,
        takeoffEncodeChunk: function (bytes) {
            if (recorder.mix && recorder.mix.onMixMp3Encode) {
                recorder.mix.onMixMp3Encode(bytes);
            }
        }
    });
}

function clearRecorder() {
    if (recorder.mix) {
        recorder.mix.stop();
        recorder.mix = null;
    }
    if (recorder.local) {
        streamStop(recorder.local);
        recorder.local = null;
    }
    if (recorder.remote) {
        streamStop(recorder.remote)
        recorder.remote = null;
    }
}


function streamStart(scope, stream, onProcess) {
    scope.stream = stream;
    Recorder.Support();
    var ctx = Recorder.Ctx;
    var media = stream._m = ctx.createMediaStreamSource(stream);
    var process = stream._p = (ctx.createScriptProcessor || ctx.createJavaScriptNode).call(ctx, 4096, 1, 1);//单声道

    media.connect(process);
    process.connect(ctx.destination);

    process.onaudioprocess = function (e) {
        var o = e.inputBuffer.getChannelData(0);//块是共享的，必须复制出来
        var size = o.length;

        var pcm = new Int16Array(size);
        for (var j = 0; j < size; j++) {//floatTo16BitPCM
            var s = Math.max(-1, Math.min(1, o[j]));
            s = s < 0 ? s * 0x8000 : s * 0x7FFF;
            pcm[j] = s;
        }
        onProcess(pcm, ctx.sampleRate);
    }
}

function streamStop(scope) {
    var stream = scope.stream;
    if (stream._m) {
        stream._m.disconnect();
        stream._p.disconnect();
        stream._p.onaudioprocess = stream._p = stream._m = null;
    }
}

function streamMix(isStop) {
    let Test = true;

    var rec = recorder.mix;
    if (!rec) {
        return;
    }

    var sampleRate, localBuffers = null, localSize = 0, remoteBuffers = null, remoteSize = 0;
    if (recorder.local) {
        localBuffers = recorder.local.buffers;
        localSize = recorder.local.size;
        sampleRate = recorder.local.sampleRate;
    }
    if (recorder.remote) {
        remoteBuffers = recorder.remote.buffers;
        remoteSize = recorder.remote.size;
        sampleRate = recorder.remote.sampleRate;
    }


    //取最小的buffers来混合，长的buffers剩余部分留到下一轮
    var mixPcmLen = localSize && remoteSize ? Math.min(localSize, remoteSize) : (localSize || remoteSize);

    //控制缓冲达到指定间隔才进行传输
    if (!isStop && mixPcmLen / sampleRate * 1000 < SendInterval) {
        return;
    }

    //测试，将本地、远程数据保存一份 下载
    if (Test) {
        var testLocalBuffers = recorder.mix.testLocalBuffers || [];
        var testRemoteBuffers = recorder.mix.testRemoteBuffers || [];
        recorder.mix.testLocalBuffers = testLocalBuffers;
        recorder.mix.testRemoteBuffers = testRemoteBuffers;

        if (localBuffers) {
            for (var i = 0; i < localBuffers.length; i++) {
                testLocalBuffers.push(localBuffers[i]);
            }
        }
        if (remoteBuffers) {
            for (var j = 0; j < remoteBuffers.length; j++) {
                testRemoteBuffers.push(remoteBuffers[j]);
            }
        }
    }

    var mixPcmBuffers = recorder.mix.mixPcmBuffers || [];//缓冲混合结果，到一定长度时发送
    recorder.mix.mixPcmBuffers = mixPcmBuffers;
    var mixPcmSize = recorder.mix.mixPcmSize || 0;

    //混合已有pcm
    var mixPcm = new Int16Array(mixPcmLen);
    if (!mixPcm.length) {
        return;
    }
    if (localBuffers) {
        recorder.local.size = Mix(mixPcm, localBuffers);
    }
    if (remoteBuffers) {
        recorder.remote.size = Mix(mixPcm, remoteBuffers);
    }
    if (Test) {//撤回未混合的PCM
        if (localBuffers && localBuffers.length) {
            testLocalBuffers.length -= localBuffers.length;
        }
        if (remoteBuffers && remoteBuffers.length) {
            testRemoteBuffers.length -= remoteBuffers.length;
        }
    }

    mixPcmSize += mixPcm.length;
    recorder.mix.mixPcmSize = mixPcmSize;
    mixPcmBuffers.push(mixPcm);

    //测试，将所有pcm存起来，结束时生成一个完整mp3
    var testMp3Bytes = 0;
    if (Test) {
        testMp3Bytes = recorder.mix.testMp3Bytes || [];
        recorder.mix.testMp3Bytes = testMp3Bytes;

        var testPcmBuffers = recorder.mix.testPcmBuffers || [];
        recorder.mix.testPcmBuffers = testPcmBuffers;
        testPcmBuffers.push(mixPcm);

        if (isStop) {
            var chunk2 = Recorder.SampleData(testPcmBuffers, sampleRate, 16000);
            new Recorder({type: "mp3", sampleRate: 16000, bitRate: 16})
                .mock(chunk2.data, 16000)
                .stop(function (blob, duration) {
                    console.log("生成测试MP3", blob, duration);
                    console.log(URL.createObjectURL(blob));


                    /*//以下为测试数据
                    var size=0;
                    for(var i=0;i<testMp3Bytes.length;i++){
                        size+=testMp3Bytes[i].length;
                    }
                    var bytes=new Uint8Array(size);
                    for(var j=0,pos=0;j<testMp3Bytes.length;j++){
                        bytes.set(testMp3Bytes[j], pos);
                        pos+=testMp3Bytes[j].length;
                    }
                    blob=new Blob([bytes.buffer],{type:"audio/mp3"});
                    console.log("实际发送bytes合并mp3", blob);


                    chunk2=Recorder.SampleData(rec.testLocalBuffers, sampleRate, 16000);
                    blob=new Blob([chunk2.data.buffer],{type:"audio/pcm"});
                    console.log("本地录音PCM", blob, chunk2.data.length/16000*1000);
                    console.log(URL.createObjectURL(blob));

                    chunk2=Recorder.SampleData(rec.testRemoteBuffers, sampleRate, 16000);
                    blob=new Blob([chunk2.data.buffer],{type:"audio/pcm"});
                    console.log("远程录音PCM", blob, chunk2.data.length/16000*1000);
                    console.log(URL.createObjectURL(blob));*/
                }, function (msg) {
                    console.error("生成测试MP3出错:" + msg);
                });
        }
    }

    //清空缓冲，为下一轮做准备
    recorder.mix.mixPcmBuffers = [];
    recorder.mix.mixPcmSize = 0;

    //转码(采用mp3专用方式，避免产生停顿),然后发送
    var distSampleRate = rec.set.sampleRate;
    rec.onMixMp3Encode = function (bytes) {
        if (testMp3Bytes) {
            testMp3Bytes.push(bytes);
        }
        var count = (rec.count || 0) + 1;
        rec.count = count;

        let blob = new Blob([bytes.buffer], {type: "audio/mp3"});
        let reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => {
            //console.log(`编码之后内容${reader.result}`);
            /*if (recordContent.length === 0) {
                uploadRecordState[0] = 0;
                uploadRecordState[1] = 0;
            }*/

            //recordContent.push(reader.result.split(',')[1]);
            let audioRecordByCallId = audioRecordData[callID];
            let audioBuff = audioRecordByCallId.audioBuffer;
            let base64AudioContent = reader.result.split(',')[1];

            if (audioBuff.length === 0) {
                audioBuff.push([base64AudioContent]);
            } else {
                if (audioBuff[audioBuff.length - 1].length === 10) {
                    audioBuff.push([base64AudioContent]);
                } else {
                    audioBuff[audioBuff.length - 1].push(base64AudioContent);
                }
            }
            if (audioBuff.length === 1 && audioBuff[0].length === 1) {
                //第一段音频进入buffer，开启循环
                audioRecordByCallId.intervalTimer = setInterval(() => {
                    audioRecordByCallId.uploadFunction()
                }, 1000);
            }
        }
    };
    if (!rec.set.disableEnvInFix) {
        rec.set.disableEnvInFix = true;
        rec.envStart({
            envName: "", canProcess: true
        }, distSampleRate);
    }
    var chunk = Recorder.SampleData(mixPcmBuffers, sampleRate, distSampleRate);
    rec.envIn(chunk.data, 0);
    if (isStop) {
        rec.stop();
    }
}

var Mix = function (pcm, buffers) {
    var idx = 0, pos = 0;
    for (var j = 0; j < pcm.length; j++) {
        var cur = buffers[idx];
        if (cur && pos < cur.length) {
            var data_mix, data1 = pcm[j] || 0, data2 = cur[pos] || 0;

            if (data1 < 0 && data2 < 0) {
                data_mix = data1 + data2 - (data1 * data2 / -0x7FFF);
            } else {
                data_mix = data1 + data2 - (data1 * data2 / 0x7FFF);
            }

            pcm[j] = data_mix;

            pos++;
            if (pos >= cur.length) {
                idx++;
                pos = 0;
            }
        }
    }

    if (pos === 0) idx--;

    //返回剩余未混合的pcm数量
    if (idx >= buffers.length) {
        buffers.length = 0;
        return 0;
    }

    buffers.splice(0, idx + 1);
    var size = 0;
    for (var i = 0; i < buffers.length; i++) {
        size += buffers[i].length;
    }
    return size;
};

//保证localStream和remoteStream都有值的时候进行语音解码
function decodeStream() {
    if (!recorder.local) {
        console.log(`----------------decode localStream------------------`);
        recorder.local = {size: 0, sampleRate: 0, buffers: []};
        streamStart(recorder.local, master.localStream, function (pcm, sampleRate) {
            if (recorder.local) {
                recorder.local.buffers.push(pcm);
                recorder.local.size += pcm.length;
                recorder.local.sampleRate = sampleRate;
                streamMix();
            }
        })
    }
    if (!recorder.remote) {
        console.log(`----------------decode remoteStream------------------`);
        recorder.remote = {size: 0, sampleRate: 0, buffers: []};
        streamStart(recorder.remote, master.remoteStream, function (pcm, sampleRate) {
            if (recorder.remote) {
                recorder.remote.buffers.push(pcm);
                recorder.remote.size += pcm.length;
                recorder.remote.sampleRate = sampleRate;
                streamMix();
            }
        });
    }
}

function uploadRecord(param, audioData, callback) {
    let {rtcInfo, callId, index} = param;
    let params = {
        CallId: callId,
        DeviceId: rtcInfo.DeviceId,
        TripId: rtcInfo.TripId,
        FleetId: rtcInfo.FleetId,
        PlateNumber: rtcInfo.PlateNumber,
        DataSeq: index,
        Data: audioData
    };
    serverApi.webRtcApi.uploadRecordToServer(
        params, data => {
            if (callback) callback(data);
        }, errorData => {
            if (callback) callback(errorData);
        })
}
function setRtcIsCloseByCallId(callId) {
    if (audioRecordData) {
        if (audioRecordData[callId]) {
            audioRecordData[callId].isClose = true;
            if (audioRecordData[callId].audioBuffer.length === 0) {
                //移除
                delete audioRecordData[callId];
            }
        }
    }
}

export {
    startMaster,
    stopMaster,
    getCurrentMasterState,
    createSignalingChannel,
    setRtcIsCloseByCallId
}
