<template>
    <div class="drs-main">
        <el-date-picker
                class="drs-date-picker"
                v-model="dateRange"
                :editable="false"
                :picker-options="pickerOptions"
                :default-time="defaultTime"
                size="mini"
                type="daterange"
                :format="dateFormat"
                @change="handleDateRangeChange"
                range-separator="to"
                start-placeholder="Start Date"
                end-placeholder="End Date">
        </el-date-picker>
    </div>
</template>

<script>
    export default {
        name: "DateRangeSelector",
        props: {
            startDate: {type: [Date, null]},
            endDate: {type: [Date, null]},
            alertDaysList: {
                type: Array,
                default: () => {
                    return [];
                }
            },
            tripDaysList: {
                type: Array,
                default: () => {
                    return [];
                }
            }
        },
        beforeMount() {
            if (this.startDate && this.endDate)
                this.dateRange = [this.startDate, this.endDate];
        },
        data() {
            return {
                dateRange: null,
                defaultTime: ['00:00:00', '23:59:59'],
                pickDate: {
                    date: null,
                    minDate: null, // 时间戳
                    maxDate: null, // 时间戳
                },
                curPickDateMap: new Map([['num', 0], ['third', null], ['begin', null], ['end', null]]), // 用于记录当前显示日历的起始与结束日期
                pickerOptions: {
                    cellClassName: (date) => {
                        let cls = [];
                        let dateStr = this.formatDateToStr(date);
                        if (this.dateRange) {
                            let startDateStr = this.formatDateToStr(this.dateRange[0]),
                                endDateStr = this.formatDateToStr(this.dateRange[1]);
                            if (startDateStr === dateStr || endDateStr === dateStr)
                                cls.push('drs-selected-day')
                        }
                        if (this.tripDaysList.findIndex(day => {
                            return day === dateStr
                        }) !== -1) cls.push('drs-trip-day');
                        if (this.alertDaysList.findIndex(day => {
                            return day === dateStr
                        }) !== -1) cls.push('drs-alert-day');
                        return cls.join(' ');
                    },
                    disabledDate: (date) => {
                        let newNum = this.curPickDateMap.get('num') + 1;
                        this.curPickDateMap.set('num', newNum);
                        if (newNum === 1)
                            this.curPickDateMap.set('begin', this.formatDateToStr(date));
                        if (newNum === 3)
                            this.curPickDateMap.set('third', this.formatDateToStr(date));
                        if (newNum === 84) {
                            let lastEndDate = this.curPickDateMap.get('end'),
                                newEndDate = this.formatDateToStr(date),
                                newBeginDate = this.curPickDateMap.get('begin');
                            if (
                                newEndDate
                                && newBeginDate
                                && new Date(newBeginDate).getTime() > new Date(newEndDate).getTime()
                            ) {
                                this.curPickDateMap.set('num', 82);
                                this.curPickDateMap.set('end', null);
                                this.curPickDateMap.set('begin', this.curPickDateMap.get('third'))
                            } else {
                                if (lastEndDate !== newEndDate) {
                                    this.curPickDateMap.set('end', newEndDate);
                                    this.$emit('update-alert-trip-days', newBeginDate, newEndDate);
                                }
                                this.curPickDateMap.set('num', 0);
                            }
                        }
                        let today_num = this.formatDateToNum(new Date()),
                            date_num = this.formatDateToNum(date);
                        if (date_num > today_num) return true;
                        if (this.pickDate.date) {
                            return date.getTime() < this.pickDate.minDate || date.getTime() > this.pickDate.maxDate;
                        }
                    },
                    onPick: ({maxDate, minDate}) => {
                        let maxRangeDays = 62;
                        if (maxDate === null && minDate) {
                            let day_begin = new Date(minDate.getFullYear(), minDate.getMonth(), minDate.getDate()).getTime(),
                                day_end = day_begin + 24 * 3600 * 1000 - 1;
                            this.pickDate.date = minDate;
                            this.pickDate.minDate = day_begin - (maxRangeDays - 1) * 24 * 3600 * 1000;
                            this.pickDate.maxDate = day_end + (maxRangeDays - 1) * 24 * 3600 * 1000;
                        } else {
                            this.pickDate.date = null;
                            this.pickDate.minDate = null;
                            this.pickDate.maxDate = null;
                        }
                    }
                },
            }
        },
        computed: {
            dateFormat() {
                let format = '';
                switch (this.mixin_dateFormat) {
                    case 'YYYY-MM-DD':
                        format = 'yyyy-MM-dd';
                        break;
                    case 'MM-DD-YYYY':
                        format = 'MM-dd-yyyy';
                        break;
                    case 'DD-MM-YYYY':
                        format = 'dd-MM-yyyy';
                        break;
                    default:
                        format = 'yyyy-MM-dd';
                        break;
                }
                return format;
            }
        },
        watch: {
            startDate() {
                this.updateDateRange();
            },
            endDate() {
                this.updateDateRange();
            }
        },
        methods: {
            handleDateRangeChange(dateRange) {
                if (dateRange === null) {
                    this.$emit('date-range-change', null);
                    return
                }
                // 验证规则
                // let maxDays = 62,
                //     maxTimestamp = maxDays * 24 * 3600 * 1000,
                //     now = new Date(),
                //     todayLastTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59),
                //     todayFistTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
                // if (dateRange[1].getHours() === 0 && (dateRange[1].getTime() - dateRange[0].getTime() > (maxTimestamp - 24 * 3600 * 1000))) {
                //     if (dateRange[0].getTime() > todayFistTime.getTime())
                //         dateRange = [todayFistTime, todayLastTime];
                //     if (dateRange[1].getTime() > todayLastTime.getTime())
                //         dateRange = [dateRange[0], todayLastTime];
                //     let lastDate = new Date(dateRange[1].getFullYear(), dateRange[1].getMonth(), dateRange[1].getDate());
                //     if (lastDate.getTime() - dateRange[0].getTime() > (maxTimestamp - 2 * 24 * 3600 * 1000)) {
                //         dateRange = [new Date(lastDate.getTime() - maxTimestamp + 24 *3600 * 1000), dateRange[1]];
                //     }
                //     this.dateRange = dateRange;
                // }
                let [startDate, endDate] = dateRange;
                this.$emit('date-range-change', {startDate, endDate});
            },
            formatDateToStr(date) {
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                month = month > 9 ? month : '0' + month;
                day = day > 9 ? day : '0' + day;
                return `${year}-${month}-${day}`;
            },
            formatDateToNum(date) {
                let year = date.getFullYear(),
                    month = date.getMonth() + 1,
                    day = date.getDate();
                month = month > 9 ? month : '0' + month;
                day = day > 9 ? day : '0' + day;
                let date_str = `${year}${month}${day}`;
                return parseInt(date_str);
            },
            updateDateRange() {
                if (this.startDate && this.endDate) {
                    this.dateRange = this.startDate.getTime() > this.endDate.getTime()
                        ? [this.endDate, this.startDate] : [this.startDate, this.endDate];
                } else
                    this.dateRange = null;
            }
        }
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    >>> .el-date-editor .el-range__icon
        color $color_black_second !important

    .drs-main
        width 100%

        .drs-date-picker
            width 100%

        >>> .el-date-editor input
            cursor default !important

        >>> .el-range-editor--mini .el-range-input,
        >>> .el-range-editor--mini .el-range-separator
            font-size 14px
</style>
<style lang="stylus">
    @import "~@/assets/stylus/color.styl"
    .el-date-table .drs-alert-day span,
    .el-date-table .drs-trip-day span
        border-radius 50%
        border 1px solid rgba(0, 0, 0, 0)

    .el-date-table .drs-alert-day span
        border-color red !important

    .el-date-table .drs-trip-day span
        background-color $color_gray_third
        border-color $color_gray_third

    .el-date-table .start-date .drs-selected-day span,
    .el-date-table .end-date .drs-selected-day span
        background-color $color_blue_second !important
</style>
