<template>
    <div class="header-root">
        <template v-if="this.$route.path == '/management/vehicle'">
            <span class="hoa-empty-space"></span>
            <el-button size="small" type="primary" @click="addFleet" class="add-button"><span
                    class="via-add"></span> {{$t('fleet.addFleet')}}
            </el-button>
            <el-button size="small" type="primary" @click="addVehicle" class="add-button"><span
                    class="via-add"></span> {{$t('fleet.addVehicle')}}
            </el-button>
            <el-input :placeholder="$t('vehicle.search')" v-model="vehicleSearchKey" size="small" class="search-button"
                      :title="$t('vehicle.searchHover')">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </template>
        <template v-if="this.$route.path == '/management/driver'">
            <span class="hoa-empty-space"></span>
            <el-button size="small" type="primary" @click="addDriver" class="add-button"><span
                    class="via-add"></span> {{$t('driver.addDriver')}}
            </el-button>
            <el-input :placeholder="$t('driver.search')" v-model="driverSearchKey" size="small" class="search-button"
                      :title="$t('driver.searchHover')">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
        </template>
        <template v-if="this.$route.name == 'viewVehicle'">
            <span class="hoa-empty-space"></span>
            <el-button size="small" type="primary" @click="editVehicle($route.query.id)" class="add-button"><span
                    class="via-edit"></span> {{$t('vehicle.editVehicle')}}
            </el-button>
        </template>
        <template v-if="this.$route.name == 'viewDriver'">
            <span class="hoa-empty-space"></span>
            <el-button size="small" type="primary" @click="editDriver($route.query.id)" class="add-button"><span
                    class="via-edit"></span> {{$t('driver.editDriver')}}
            </el-button>
        </template>
        <template v-if="this.$route.name == 'userList'">
            <span class="hoa-empty-space"></span>
            <el-button size="small" type="primary" class="add-button"><span
                    class="via-add"></span> {{$t('userAccounts.addUser')}}
            </el-button>
        </template>
        <el-dialog :visible.sync="centerDialogVisible" width="26%" center>
            <div slot="title" class="dialog-title">
                {{getTitleText('popUpMsg.vehicles.seriesPlatform')}}
            </div>
            <div class="dialog-content">
                <div class="content-title">
                    {{getDescriptionText('popUpMsg.vehicles.selectVIAPlatform')}}
                </div>
                <div>
                    <div style="height: 40px" v-for="(item,index) in vehiclePlatformList" :key="index">
                        <el-radio :label=item v-model="vehiclePlatform">{{getDevicePrefixText('VIA Mobile360')}} {{item}}</el-radio>
                    </div>
                </div>

            </div>
            <span slot="footer" class="footer-class">
                    <el-button type="primary" size="small"
                               @click="onClickConfirmBtn">{{$t('globalText.oK')}}</el-button>
                    <el-button @click="centerDialogVisible = false" size="small" class="button-cancel">{{$t('globalText.cancel')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import bus from "../../utils/bus"
    import {CustomerConfigAddVehiclePopWindow} from '@/services/customer/CustomerConfig'

    export default {
        name: "headerOperatorArea",

        data() {
            return {
                vehicleSearchKey: '',
                driverSearchKey: '',
                centerDialogVisible: false,
                vehiclePlatform: "D700",
                vehiclePlatformList: []
            }
        },
        watch: {
            driverSearchKey(newVal) {
                this.$store.commit('setDriverSearchKey', newVal)
            },
            vehicleSearchKey(newVal) {
                this.$store.commit('setVehicleSearchKey', newVal)
            }
        },
        methods: {
            getTitleText(defaultValue) {
                const value = this.$t(defaultValue)
                return CustomerConfigAddVehiclePopWindow.getTitleText(value, this.$i18n.locale)
            },
            getDescriptionText(defaultValue) {
                const value = this.$t(defaultValue)
                return CustomerConfigAddVehiclePopWindow.getDescriptionText(value, this.$i18n.locale)
            },
            getDevicePrefixText(defaultValue) {
                return CustomerConfigAddVehiclePopWindow.getDevicePrefixText(defaultValue, this.$i18n.locale)
            },
            addVehicle() {
                let companyInfo = this.$store.getters.companyInfo
                if (companyInfo) {
                    this.vehiclePlatformList = companyInfo.MainModelListForAddVehicle || [];
                }
                this.centerDialogVisible = true;
            },
            onClickConfirmBtn() {
                this.centerDialogVisible = false;
                this.$router.push({name: "addNewVehicle", query: {model: this.vehiclePlatform}}).catch();
                // this.$router.push({name: "addVehicle",query:{model:this.vehiclePlatform}}).catch();
            },
            editVehicle(id) {
                this.$router.push({name: "editVehicle", query: {id: id}}).catch();
            },
            addFleet() {
                this.$router.push({name: "addFleet"}).catch();
            },
            addDriver() {
                this.$router.push({name: "addDriver", params: {rememberCtx: true}}).catch();
            },
            editDriver(id) {
                this.$router.push({name: "editDriver", query: {id: id}, params: {from: 'viewDriver'}}).catch();
            },
            // Export() {
            //     const Headers = this.$store.getters.driverTableHead.map((item) => {
            //         return item.name;
            //     });
            //     const TitleList = this.$store.getters.driverTableHead.map((item) => {
            //         return item.prop;
            //     });
            //
            //
            //     let excelName = 'DriverInfo';
            //     let tableData = this.$store.getters.driverList;
            //     console.log(JSON.stringify(tableData));
            //     exportToExcel(Headers, TitleList, tableData, excelName);
            //
            // },
        },
        created() {
            bus.$on("clearDriverSearchKey", () => {
                this.driverSearchKey = "";
            });
            bus.$on("clearFleetSearchKey", () => {
                this.vehicleSearchKey = "";
            })
        },
    }
</script>

<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"
    .hoa-empty-space
        margin-left 30px

    .search-button {
        margin-left: 10px;
        width: 200px;
        font-size: 14px
    }

    .dialog-title
        background-color $color_blue_first
        height 45px
        line-height 45px

    .header-root
        >>> .el-dialog__header
            padding 0 !important
        >>> .el-dialog__headerbtn
            top 10px
            font-size 24px
        >>> .el-dialog__headerbtn .el-dialog__close
            color white
        >>> .footer-class .el-button
            width 30%
        >>> .footer-class .el-button + .el-button
            margin-left 10%
        >>> .el-dialog__footer
            padding 10px 20px 0
        >>> .el-dialog--center .el-dialog__body
            padding 25px 25px 20px

    .dialog-content .content-title
        font-size 16px
        line-height normal
        word-break keep-all
        word-wrap break-word

    .button-cancel
        background gray
        color white

</style>
