<template>
    <div class="header" :class="{'header-collapse':collapse}">
        <!-- 折叠按钮 -->
        <div class="header-left">
            <div class="collapse-btn" @click="collapseChage">
                <span v-if="!collapse" class="via-menu-left"></span>
                <span v-else class="via-menu-right"></span>
            </div>
        </div>
        <div class="header-middle">
            <div :class="titleIcon" class="via-icon"></div>
            <div class="title">{{title === '' ? '' : $t('common.headerTitle.'+title)}}</div>
            <com-header-date-selector/>
            <header-selector/>
            <vheader-operator-area/>
        </div>
        <div class="header-right">
            <div class="header-message-con">

                <!--                <div>-->
                <!--                    <lang-selector></lang-selector>-->
                <!--                </div>-->

                <div class="webrtc-dot">
                    <el-badge :is-dot="checkIsShowRtcDot" class="item" type="danger">
                        <div class=" alarm-button" @click="showWebRtcDialog()">
                            <span class="via-microphone"/>
                        </div>
                    </el-badge>
                </div>

                <div class="alert-dot">
                    <el-badge :is-dot="hasNewCollision" class="item" type="danger">
                        <div class=" alarm-button">
                            <i class="el-icon-message-solid" @click="showMessageDialog('alert')"></i>
                        </div>
                    </el-badge>
                </div>

                <div class="user-avator">
                    <el-avatar shape="circle" @error="errorHandler" fit="cover" :size="40">
                        <img src="../../assets/image/default-photo.svg"/>
                    </el-avatar>
                </div>
                <el-dropdown class="user-name" trigger="click">
                    <span class="el-dropdown-link">
                        <span v-if="companyInfo">{{companyInfo.Name}} </span>
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item @click.native="showMyAccount">
                            <span class="via-user via-icon-user"></span>{{$t('myAccount.myAccount')}}
                        </el-dropdown-item>
                        <el-dropdown-item @click.native="showSetting"><span class="via-settings via-icon-user "></span>{{$t('settings.settings')}}
                        </el-dropdown-item>
                        <el-dropdown-item
                            v-if="isShowPartnerProgram"
                            @click.native="showPartnerProgram">
                            <div style="display: inline-block" class="via-icon-user-partner">
                                <img src="../../assets/image/ico_partner.svg" width="14px"/>
                            </div>
                            {{$t('settings.partner_program')}}
                        </el-dropdown-item>
                        <!--<el-dropdown-item @click.native="showUserAccount"><span class="via-settings via-icon-user "></span>{{this.$t('common.userAccount')}}-->
                        <!--</el-dropdown-item>-->
                        <el-dropdown-item divided @click.native="logout">
                            <span style="margin-left: 30px"></span>{{$t('logout.logout')}}
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>

            </div>

            <audio src="../../assets/notification/Call_Notification_Web.wav" style="display: none" id="audio"></audio>
            <audio src="../../assets/notification/VIA_Fleet_2-way_Call_Connection_Ring.mp3" style="display: none"
                   id="phoneTone" loop="loop"></audio>
        </div>
        <v-alarm v-if='isShowMessageDialog' @onClose="hideMessageDialog"/>
        <v-account v-if='isShowAccountDialog' @onClose="HideAccountDialog"></v-account>
        <v-change-password v-if='isShowChangePWDDialog' @onClose="HideChangePWDDialog"></v-change-password>
        <v-system-setting v-if='isShowSettingDialog' @onClose="HideSettingDialog"></v-system-setting>
        <v-alert-rtc-message v-if="isShowRtcDialog" @onClose="hideRtcDialog"/>
        <partner-program v-if="isShowPartnerProgramDialog" @onClose="hidePartnerProgramDialog"/>
    </div>
</template>
<script>
    import vAlarm from '../header/AlarmMessage'
    import vheaderOperatorArea from "../header/HeaderOperatorArea"
    import ComHeaderDateSelector from "../dashboard/ComHeaderDateSelector";
    import HeaderSelector from '../../components/route/HeaderSelector';
    import vAccount from "../../components/system/Account"
    import vChangePassword from "../../components/system/ChangePassword"
    import vSystemSetting from "../../components/system/Setting"
    import vAlertRtcMessage from '../header/AlertRtcMessage'
    import PartnerProgram from '../header/PartnerProgram'
    import {randomWord} from "../../assets/js/Util"
    import {
        getCurrentMasterState,
        startMaster,
        stopMaster,
        createSignalingChannel,
        setRtcIsCloseByCallId
    } from "../../utils/audio";
    import bus from '../../utils/bus'

    export default {
        components: {
            vAlarm,
            vheaderOperatorArea,
            ComHeaderDateSelector,
            vAccount,
            vChangePassword,
            vSystemSetting,
            vAlertRtcMessage,
            HeaderSelector,
            PartnerProgram
        },
        data() {
            return {
                title: "",
                titleIcon: "",
                isShowMessageDialog: false,
                isShowSelectDialog: false,
                isShowTTSDialog: false,
                isShowAccountDialog: false,
                isShowChangePWDDialog: false,
                isShowSettingDialog: false,
                isShowRtcDialog: false,
                isShowPartnerProgramDialog: false,
                messageType: "",
                broadCast: {
                    fleet: "",
                    ttsMsg: ""
                },
                reCalledRtcTask: null,
                retryCount: 0,
                continueRetry: true,
                retryTimer: null,
            };
        },
        computed: {
            isShowPartnerProgram () {
                return !(process.env.VUE_APP_ENV === 'PROD')
            },
            companyInfo: function () {
                return this.$store.getters.companyInfo;
            },
            hasNewCollision() {
                return this.$store.getters['websocket/hasNewCollisionInfo'];
            },
            checkIsShowRtcDot() {
                return this.$store.getters['websocket/getHasRtcNotification'];
            },
            currentRtcTaskInfo() {
                return this.$store.getters['websocket/getRtcTaskInfo'];
            },
            currentRtcInfo() {
                return this.$store.getters['websocket/getRequestCallInfo'];
            },
            isDeviceEnterChannel() {
                return this.$store.getters['websocket/getIsDeviceEnterChannel'];
            },
            collapse: function () {
                console.log("computed" + this.$store.getters.getMenuCollapse);
                return this.$store.getters.getMenuCollapse;
            },
        },
        watch: {
            retryCount() {
                if (this.retryCount === 10) {
                    this.continueRetry = false;
                    this.retryCount = 0;
                    if (this.retryTimer) {
                        clearInterval(this.retryTimer);
                        this.retryTimer = null
                    }
                    let name = this.$store.getters['websocket/getRtcTaskInfo'].driverName;
                    let msg = this.$t("popUpMsg.liveTracking.cannotReach_DriverName_NowPleaseTryLater", {
                        driverName: name.toUpperCase() === 'UnKnown'.toUpperCase() ? this.$t('globalText.unknown') : name
                    });
                    this.$customAlert(msg, {
                        dangerouslyUseHTMLString: true
                    });
                    this.$store.commit('websocket/setRtcTask', null);
                    this.stopPhoneToneAudio();//关闭拨号音
                }
            },
            '$route'(route) {
                this.setHeaderTitle(route.meta.title, route.meta.icon);
            },

            currentRtcTaskInfo() {
                if (this.currentRtcTaskInfo.type) {
                    console.log(`${this.currentRtcTaskInfo.type} 发起推流消息`);
                    this.startHandleRtc(this.currentRtcTaskInfo.deviceId, this.currentRtcTaskInfo.driverName,
                        this.currentRtcTaskInfo.vehicleId);
                } else {
                    console.log(`Header挂断按钮，结束推流`);
                    this.startHandleRtc(null);
                    if (this.currentRtcTaskInfo.rtcRecalledTask) {
                        this.reCalledRtcTask = this.currentRtcTaskInfo.rtcRecalledTask;
                    }
                }
            },
            isDeviceEnterChannel() {
                if (this.isDeviceEnterChannel) {
                    //device已进入channel
                    this.onDeviceRtcConnected();
                    //rtc通话连接成功，取消retry设定
                    this.continueRetry = false;
                    this.retryCount = 0;
                    if (this.retryTimer) {
                        clearInterval(this.retryTimer);
                        this.retryTimer = null
                    }
                }
            }
        },
        mounted() {
            // if (document.body.clientWidth < 1500) {
            //     this.collapseChage();
            // }
            bus.$on('onNewCall', () => {
                console.log(`播放声音`);
                document.getElementById('audio').play();
            });
            bus.$on('onDeviceOffRoad', this.onDeviceOffRoad)
        },
        methods: {
            async startHandleRtc(deviceId, name, vehicleId) {
                console.log(`deviceId = ${deviceId},name = ${name},vehicleId = ${vehicleId}`);
                if (getCurrentMasterState() || !deviceId) {
                    console.log('结束推流');
                    this.continueRetry = false;
                    if (this.retryTimer) {
                        clearInterval(this.retryTimer);
                        this.retryTimer = null
                    }
                    stopMaster((data) => {
                        this.onCloseWebRtc(data, deviceId);
                    });
                    setRtcIsCloseByCallId(this.$store.getters['websocket/getRtcCallId']);
                    this.$store.commit('websocket/setRtcLocalStream', null);
                    this.$store.commit('websocket/setRtcRemoteStream', null);

                } else {
                    let stream;
                    try {
                        //检查webcam
                        stream = await navigator.mediaDevices.getUserMedia({video: false, audio: true});
                    } catch (e) {
                        let msg = this.$t('popUpMsg.liveTracking.audioInputDeviceIsNotReady'); // 'Audio input device is not ready.'
                        this.$customAlert(msg);
                        stream.getTracks().forEach(track => track.stop());
                        stream = null;
                        return;
                    }
                    //通话开始之前将continueRetry置为true
                    this.continueRetry = true;
                    this.retryCount = 0;
                    this.$store.commit('websocket/setRtcCallId', null);

                    //设置挂断按钮可用标记
                    this.$store.commit('websocket/setHangUpStatus', false);
                    //标记当前有正在进行的通话
                    this.$store.commit('websocket/setRunningRtcFlag', true);
                    console.log('开始推流');
                    //通知Home父组件显示通话中的组件
                    this.$emit('rtcShowState', [true, name]);

                    //记录当前正在通话的相关信息
                    this.$store.commit('websocket/setCurrentRtcFlag', {
                        DeviceId: deviceId,
                        VehicleId: vehicleId,
                        DriverName: name
                    });
                    //开始播放拨号音
                    document.getElementById('phoneTone').play();

                    this.createWebRtcChannel(deviceId, vehicleId, name)

                }
            },
            async createWebRtcChannel(devicesId, vehicleId, name) {
                /**
                 //固定channelName的代码
                 let channelName = devicesId;
                 if (channelName.length < 64) {
                    for (let i = 0; i < 64 - channelName.length; i++) {
                        channelName = channelName + "0";
                    }
                }else {
                    channelName = channelName.substr(0, 64);
                }
                 console.log(`master进入channel之前`);
                 getSingnalingChannel(channelName);
                 //console.log(`channelName = ${channelName}`);
                 * */
                const awsConfig = await new Promise((resolve, reject) => {
                    this.$serverApi.credentials.credentials({ServiceType: 'WEBRTC'}, data => {
                        resolve(data);
                    }, errorMsg => {
                        reject(errorMsg);
                    });
                }).catch(reason => {
                    console.error(`错误回调,error = ${JSON.stringify(reason)}`);
                    this.stopPhoneToneAudio();
                });

                if (!awsConfig || !awsConfig.Credentials) {
                    console.error(`获取aws config参数出错，终止rtc初始化`);
                    let msg = this.$t("popUpMsg.liveTracking.cannotReach_DriverName_NowPleaseTryLater", {
                        driverName: name
                    });
                    this.clearRTCState(msg);
                    return;
                }
                let awsRTCConfig = {
                    region: awsConfig.Region,
                    accessKeyId: awsConfig.Credentials.AccessKeyId,
                    secretAccessKey: awsConfig.Credentials.SecretAccessKey,
                    sessionToken: awsConfig.Credentials.SessionToken,
                    endpoint: undefined
                };
                let isRecord = false;

                //获取当前是否允许录音设置
                const settingParams = await new Promise(((resolve, reject) => {
                    this.$serverApi.company.getCompany(data => {
                        resolve(data)
                    }, error => {
                        reject(error)
                    });
                })).catch(reason => {
                    console.error(`获取录音参数错误回调,error = ${JSON.stringify(reason)}`);
                });
                if (!settingParams) {
                    isRecord = false;
                } else {
                    isRecord = settingParams.CallRecord === 1;
                }


                // 创建Channel部分
                let channelName = vehicleId + "_" + randomWord(64 - vehicleId.length - 1);
                //channelName = "CHARLES01_BRtwk05iPkgLuCrqzGSogRlmifjfViQSkefVaulKENvMlur8xv6tJP";
                createSignalingChannel(awsRTCConfig, channelName, (data) => {
                    let config = {
                        DeviceId: devicesId,
                        Type: "Dial",
                        Channel: data,
                        ConnectionId: this.currentRtcInfo.connectionId
                    };
                    this.$serverApi.webRtcApi.sendChannelNameToServer(config, (response) => {
                        if (response.Result === 'ok') {
                            //Channel成功创建并上传到云端
                            if (this.retryCount === 0) {
                                //只有第一次连接才会走真正startMaster的逻辑
                                startMaster(channelName, response.CallId, awsRTCConfig, isRecord, localStream => {
                                    this.$store.commit('websocket/setRtcLocalStream', localStream);
                                    this.startRetry(config);
                                }, remoteStream => {
                                    this.$store.commit('websocket/setRtcRemoteStream', remoteStream);
                                    this.stopPhoneToneAudio();
                                }, (errorData) => {
                                    console.error(`收到错误回调，data = ${JSON.stringify(errorData)}`);
                                    this.onCloseWebRtc(errorData, devicesId);
                                    let msg = this.$t("popUpMsg.liveTracking.cannotReach_DriverName_NowPleaseTryLater", {driverName: name});
                                    this.$customAlert(msg, {dangerouslyUseHTMLString: true});
                                })
                            }

                        }
                    }, (errorData) => {
                        console.error(`初始化channel错误：error = ${errorData}`);
                        let msg = this.$t("popUpMsg.liveTracking.cannotReach_DriverName_NowPleaseTryLater", {
                            driverName: name
                        });
                        this.clearRTCState(msg);
                        return false;
                    })
                })
            },
            startRetry(config) {
                if (this.continueRetry) {
                    this.retryCount++;
                    if (this.retryTimer === null) {
                        this.retryTimer = setInterval(() => {
                            this.$serverApi.webRtcApi.sendChannelNameToServer(config, () => {
                                this.startRetry(config);
                            })
                        }, 10 * 1000);
                    }
                }
            },
            stopPhoneToneAudio() {
                let audio = document.getElementById('phoneTone');
                audio.pause();
                audio.currentTime = 0;
            },

            onDeviceOffRoad(deviceInfo) {
                console.log(`正在通话的车辆离线，离线车辆信息：`, deviceInfo);
                this.$store.commit('websocket/setRtcTask', null);
            },
            clearRTCState(errorData) {
                //设置挂断按钮可用标记
                this.$store.commit('websocket/setHangUpStatus', true);
                //标记当前有正在进行的通话
                this.$store.commit('websocket/setRunningRtcFlag', false);
                //通知Home父组件显示通话中的组件
                this.$emit('rtcShowState', [false, null]);
                this.$store.commit('websocket/setRtcTask', null);
                console.log(`上传ChannelName发生错误 error = ：${JSON.stringify(errorData)}`);
                this.stopPhoneToneAudio();
                this.$customAlert(errorData, {dangerouslyUseHTMLString: true});
            },
            onCloseWebRtc(errorData, devicesId) {
                this.stopPhoneToneAudio();
                //设置当前正在进行通话标记为false
                this.$store.commit('websocket/setRunningRtcFlag', false);
                this.$store.commit('websocket/setDeviceEnterChannelStatus', false);
                //清除当前正在通话的记录信息
                this.$store.commit('websocket/setCurrentRtcFlag', null);
                let code = errorData.code;
                /**
                 * code
                 * 0 Could not find webcam
                 * 1 channel close
                 * 2 error
                 * 3 device disconnected RTCPeerConnection.connectionState=disconnected
                 */
                console.log(`code = ${code},0 Could not find webcam;1 channel close;2 error`);
                this.$emit('rtcShowState', [false, ""]);
                this.$store.commit('websocket/setRtcLocalStream', null);
                this.$store.commit('websocket/setRtcRemoteStream', null);
                this.notifyServerCloseChannel(devicesId, errorData.channelName);
                if (code !== 1) {
                    //非正常关闭，不能保证master被关闭
                    stopMaster();

                }
            },

            onDeviceRtcConnected() {
                console.log('Device Rtc connected！');
                this.$serverApi.webRtcApi.sendDeviceConnectedMsgToServer({
                    DeviceId: this.currentRtcInfo.rtcConnectedDeviceId,
                    VehicleId: this.currentRtcInfo.rtcConnectedVehicleId,
                }, data => {
                    if (data.Result === 'ok') {
                        console.log('通知服务器Device链接成功');
                    }
                }, errorData => {
                    console.log('失败，error = ' + errorData);
                })
            },
            notifyServerCloseChannel(deviceId, channelName) {
                if (!deviceId) {
                    deviceId = this.currentRtcTaskInfo.deviceId;
                }
                let isDeviceBusy = this.$store.getters['websocket/getDeviceBusyState'];
                if (isDeviceBusy) {
                    // websocket/setDeviceBusyState
                    this.$store.commit('websocket/setDeviceBusyState', false);
                    return;
                }
                console.log(`close channel ,channelName = ${channelName},deviceId = ${deviceId}`);
                this.$serverApi.webRtcApi.sendChannelNameToServer(
                    {
                        DeviceId: deviceId,
                        Type: "EndDial",
                        Channel: channelName,
                    },
                    (data) => {
                        if (data && data.Result == 'ok') {
                            console.log(`已通知服务器，通话正常结束`);
                            if (this.reCalledRtcTask) {
                                //存在回拨任务
                                console.log(`回拨任务详情：${JSON.stringify(this.reCalledRtcTask)}`);
                                this.$store.commit('websocket/setRtcTask', {
                                    type: '重拨任务',
                                    deviceId: this.reCalledRtcTask.deviceId,
                                    driverName: this.reCalledRtcTask.driverName,
                                    vehicleId: this.reCalledRtcTask.vehicleId,
                                    currentTime: new Date().getTime()
                                });
                                this.reCalledRtcTask = null;
                            }
                        }
                    }, () => {
                        console.log("通知服务器断开通话失败");
                    })
            },
            setHeaderTitle(title, icon) {
                this.handleTitle(title);
                // this.title = title;
                this.titleIcon = icon;
            },
            handleTitle(value) {
                if (!value) {
                    this.title = value;
                    return
                }
                let strArr = value.split(' ');
                for (let i = 0; i < strArr.length; i++) {
                    if (i === 0) {
                        strArr[i] = strArr[i].charAt(0).toLowerCase() + strArr[i].substring(1);
                    } else {
                        strArr[i] = strArr[i].charAt(0).toUpperCase() + strArr[i].substring(1);
                    }
                }
                this.title = strArr.join('');
            },
            errorHandler() {
                return true
            },
            // 侧边栏折叠
            collapseChage() {
                console.log(this.collapse);
                this.$store.commit('setMenuCollapse', !this.collapse);
                //  bus.$emit('collapse', this.collapse);
            },
            showMessageDialog(messageType) {
                this.$store.commit('websocket/closeNewCollisionStatus');
                this.$serverApi.collision.list();
                this.isShowMessageDialog = true;
                this.messageType = messageType
            },
            hideMessageDialog() {
                this.isShowMessageDialog = false;
            },
            SelectTTSDialog() {
                this.isShowTTSDialog = true;
            },
            HideTTSDialog() {
                this.isShowTTSDialog = false;
            },
            showWebRtcDialog() {
                this.isShowRtcDialog = true;
            },
            hideRtcDialog() {
                this.isShowRtcDialog = false;
            },
            logout() {
                let isRunningRtc = this.$store.getters['websocket/getIsRunningRtcState'];
                if (isRunningRtc) {
                    let currentRtcInfo = this.$store.getters['websocket/getRequestCallInfo'];
                    let name = currentRtcInfo.rtcConnectedDriverName ? currentRtcInfo.rtcConnectedDriverName : 'UnKnown';
                    this.$customConfirm('Terminate the call to <strong>' + name +
                        '</strong> and logout?', {
                        dangerouslyUseHTMLString: true,
                        confirmButtonText: 'Logout',
                        cancelButtonText: 'Cancel',
                    }).then(() => {
                        //挂断rtc通话
                        this.$store.commit('websocket/setRtcTask', null);
                        this.$serverApi.auth.logout();
                        this.$store.dispatch('websocket/closeSocket')
                    }).catch(() => {
                        console.log('取消');
                    });
                } else {
                    this.$serverApi.auth.logout();
                    this.$store.dispatch('websocket/closeSocket')
                }
            },
            showMyAccount() {
                this.isShowAccountDialog = true;
            },
            HideAccountDialog() {
                this.isShowAccountDialog = false;
            },
            showChangePWD() {
                this.isShowChangePWDDialog = true;
            },
            HideChangePWDDialog() {
                this.isShowChangePWDDialog = false;
            },
            showSetting() {
                this.$router.push({name: 'settings'})
                // this.isShowSettingDialog = true;
            },
            showPartnerProgram() {
                this.isShowPartnerProgramDialog = true;
            },
            hidePartnerProgramDialog() {
                this.isShowPartnerProgramDialog = false;
            },
            HideSettingDialog() {
                this.isShowSettingDialog = false;
            },
            showUserAccount() {
                this.$router.push({name: 'userList'}).catch();
            }

        },
        created() {
            if (this.$route.meta) {
                this.setHeaderTitle(this.$route.meta.title, this.$route.meta.icon);
            }
        }
    };
</script>
<style lang="stylus" scoped>
    @import "~@/assets/stylus/color.styl"

    .header {
        position: relative;
        top: 0;
        left: 245px;
        box-sizing: border-box;
        height: 75px;
        font-size: 22px;
        color: $color_white;
        background: $color_blue_first;
        width: calc(100% - 245px);
    }

    .header-left {
        float: left;
        cursor: pointer;
        height: 75px
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .collapse-btn, .alarm-button {
        height 36px;
        width 32px
        border-radius 5px
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .collapse-btn:hover, .alarm-button:hover {
        background-color #394ab7;
    }

    .via-icon-user {
        margin-right: 10px;
        width 14px;
        display inline-block
    }
    .via-icon-user-partner {
        width 20px;
        display inline-block
    }

    .header-collapse {
        left: 64px;
        width: calc(100% - 65px);
    }

    .header-middle {
        float: left;
        margin-left: 26px;
        height: 75px;
        line-height: 75px;
        display: inline-block;
        font-size: 16px;
    }

    .header-middle div {
        vertical-align: middle;
        display: inline-block;
    }

    .via-icon {
        font-size: 24px;
        padding-right: 10px;
    }

    .header-middle .title {
        font-size: 20px;
        padding-right: 10px;
    }

    .header-middle div {
        display: inline-block;
    }

    .header-middle img {
        vertical-align: middle;
        margin-right: 5px;
    }

    .header-message-con {
        display: flex;
        height: 75px;
        align-items: center;
    }

    .header-message-con .item {
        margin-left: 30px;
        cursor: pointer;
        font-size: 24px;
    }

    .alert-dot >>> .el-badge__content.is-fixed {
        top 7px
        right 13px
    }

    .webrtc-dot >>> .el-badge__content.is-fixed {
        top 7px
        right 13px
    }

    .header-right {
        float: right;
        padding-right: 40px;
    }

    .user-avator {
        display flex
        top 50% - 20px
        margin-left: 30px;
    }

    .user-name {
        margin-left: 10px;
    }

    .el-dropdown-link {
        color: $color_white;
        font-weight: bold;
        cursor: pointer;
    }

    .el-dropdown-link:hover {
        color $color_blue_second
    }

    .select-icon {
        cursor: pointer;
        font-size: 16px;
        color: white;
    }

    .send-button {
        cursor: pointer;
        margin-left: 40px;
        vertical-align: middle;
        display: inline-block;
        border-radius: 20px;
        width: 132px;
        height: 40px;

    }

    .user-name .text-overflow-ellipsis {
        max-width 100px
        display inline-block
    }

    .alarm-button:hover {
        color: $color_gray_third;
    }


</style>
